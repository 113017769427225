var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row zero-gravity-container"},[_vm._m(0),_vm._m(1),_c('div',{staticClass:"col-sm-12 col-md-6 text-content"},[_c('div',{staticClass:"inner-cell"},[_c('h4',[_vm._v("Recline. Relax. Repeat.")]),_c('p',[_vm._v("Along with the daily stresses of life, gravity takes a toll on your body. Flexsteel's Zero Gravity recline technology allows you to achieve the optimal neutral body position to reduce strain and tension and help you reach the perfect level of comfort.")]),_c('a',{staticClass:"btn-inline benefits has-icon",attrs:{"href":"#"},on:{"click":function($event){_vm.showingBenefits=!_vm.showingBenefits}}},[_c('span',[_c('i',{staticClass:"icon",class:{'icon-add-small': !_vm.showingBenefits, 'icon-remove-small': _vm.showingBenefits}}),_vm._v(" Benefits ")])]),(_vm.showingBenefits)?_c('ul',[_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5)]):_vm._e()])]),_vm._m(6)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-sm-12 woman-container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-12 woman-row"},[_c('div',{staticClass:"inner-cell"},[_c('h1',[_vm._v("You"),_c('br'),_vm._v("Deserve It")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-sm-12 col-md-6 logo"},[_c('div',{staticClass:"inner-cell"},[_c('img',{attrs:{"src":require("@/assets/zero/ZeroGravity_Logo-horizontal@2x.png"),"alt":"Zero Gravity"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('strong',[_vm._v("Reduce Pressure")]),_vm._v(" Minimizes pressure and strain on the spine")])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('strong',[_vm._v("Reduce Pain")]),_vm._v(" Relieves back pain and eases sore muscles")])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('strong',[_vm._v("Reduce Swelling")]),_vm._v(" Improves circulation and soothes swelling")])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('strong',[_vm._v("Amplify Breathing")]),_vm._v(" Assists with heart function and increases lung capacity")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-sm-12 enjoy-content"},[_c('div',{staticClass:"inner-cell"},[_c('h3',[_vm._v("Enjoy Zero Gravity On These Flexsteel Models")])])])
}]

export { render, staticRenderFns }