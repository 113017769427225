<template>
	<div class="mobile-nav">
		<div class="hamburger">
			<div id="burger" :class="{ 'active' : isBurgerActive }" @click.prevent="toggleMobileNav">
					<button type="button" class="burger-button" title="Menu">
						<span class="hidden">Toggle menu</span>
						<span class="burger-bar burger-bar--1"></span>
						<span class="burger-bar burger-bar--2"></span>
						<span class="burger-bar burger-bar--3"></span>
					</button>
				</slot>
			</div>
		</div>
		<div class="sidebar">
			<div class="sidebar-backdrop" @click="closeSidebarPanel" v-if="isPanelOpen"></div>
			<transition name="slide">
				<div v-if="isPanelOpen"	class="sidebar-panel">
					<ul>
						<li @click="clearBack();trackNav('RSA');" class="lockup"><router-link :to="'/rsa'"><img src="~@/assets/logo-lockup.svg" alt="Flexsteel Logo"/></router-link></li>
						<li @click="clearBack();trackNav('Home');" class="home"><router-link :to="'/landing'">Home</router-link></li>
						<li @click="clearBack();trackNav('Get Inspired');" class="get-inspired"><router-link :to="'/get-inspired/'">Get Inspired</router-link></li>
						<li @click="clearBack();trackNav('Create Your Vision');"><router-link :to="'/your-vision/'">Create Your Vision</router-link></li>
						<li @click="clearBack();trackNav('Browse All Products');" class="all-products"><router-link :to="'/all-products/'">Browse All Products</router-link></li>
						<li @click="clearBack();trackNav('No Saved Items');" v-if="itemsInCart == 0" class="none-saved"><router-link :to="'/saved/'"><span></span> No Saved Items</router-link></li>
						<li @click="clearBack();trackNav('View Saved Items');" v-if="itemsInCart >= 1" class="has-saved"><router-link :to="'/saved/'">View {{itemsInCart}} Saved Item</router-link></li>
					</ul>
				</div>
			</transition>
		</div>
	</div>
</template>

<script>
import Vue from 'vue';
import {navbar} from 'vue-strap';
import _ from 'lodash';
import { mapGetters, mapActions } from 'vuex';

export default {
	name: 'BottomNav',
	data(){
		return{
		}
	},
	props: {
	},
	computed: {
		itemsInCart(){
			let cart = this.$store.getters.cartProducts;
			return cart.reduce((accum, item) => accum + item.quantity, 0);
		},
		isPanelOpen(){
			return this.$store.getters.getMobileNavStatus;
		},
		isBurgerActive() {
        	return this.$store.getters.getMobileNavStatus;
        }
	},
	methods: {
		toggleMobileNav(){
			this.setMobileNav(!this.isBurgerActive);
		},
		closeSidebarPanel(){
            // this.isPanelOpen = false;
			this.setMobileNav(false);
        },
		trackNav(section){
			this.$ua.trackEvent("Footer", "Click", section);
		},
		clearBack(){
			this.setMobileNav(false);
			// Vue.nextTick(() =>{
				// console.log(document.title, this.$router.history.current.path);
				// this.setLastRoute({title: document.title, path: this.$router.history.current.path});
			// });
			this.$goingBack = false;
		},
		...mapActions([
			'setLastRoute',
			'setMobileNav'
		])

	},
	mounted(){
	},
	components: {
		'navbar': navbar
	}
};
</script>

