import Vue from 'vue';
import axios from 'axios';
import Ionic from '@ionic/vue';

// import 'core-js';
// import "@babel/polyfill";
// import '@babel/plugin-proposal-object-rest-spread';

import App from './App';
import router from './router';
import store from './store';
import _ from 'lodash';

require('../node_modules/iframe-resizer/js/iframeResizer.contentWindow.min.js');

import LazyLoadDirective from "./directives/LazyLoad";
Vue.directive("lazyload", LazyLoadDirective);

import VueVirtualScroller from 'vue-virtual-scroller';
Vue.use(VueVirtualScroller);

import VueWindowSize from 'vue-window-size';
Vue.use(VueWindowSize);

// Vue.prototype.api_endpoint = "https://192.168.86.58:9002/api/";
Vue.prototype.api_endpoint = "https://flexsteel-kiosk.com/api/";
Vue.prototype.backroom_api_endpoint = "https://api.flexsteel.com";
Vue.prototype.backroom_api_key = "078AFE43-46CB-46CF-8164-F6153B71EE28";
Vue.prototype.appId = "com.flexsteel.app";

const { Plugins } = require('@capacitor/core');
const { SplashScreen } = Plugins;
const { Device } = Plugins;
// const { Storage } = Plugins;
const { Keyboard } = Plugins;
import { StatusBar } from '@ionic-native/status-bar';
// import { Deploy } from 'cordova-plugin-ionic';

import AsyncRoutes from './router/async-routes';

SplashScreen.hide();
if(Capacitor.getPlatform() == "android"){
	StatusBar.overlaysWebView(false);
}else{
	StatusBar.overlaysWebView(true);
}

Keyboard.addListener('keyboardDidShow', (info) => {
	if(window.location.hash && window.location.hash.indexOf('your-vision')){
		//don't scroll on PDP
	}else{
		window.scroll({
			top: 0,
			left: 0,
			behavior: 'smooth'
		});
	}
});
// Keyboard.addListener('keyboardDidHide', (info) => {
// 	Vue.$forceUpdate();
// });
//ios adds padding to the bottom of the screen when the keyboard is shown
Keyboard.setResizeMode("none");

Capacitor.DEBUG = false;

// var remote = {}
// if(process.env.IS_WEB){
	var remote = {};
	remote.app = {};
	remote.app.getName = function(){return "WEB BETA URL"};
	remote.app.getVersion = function(){return "XX"};
// }else{
	// remote = window.require('electron').remote;
// }

Vue.$http_orig = Vue.$http;
Vue.http = Vue.prototype.$http = axios;
Vue.config.productionTip = false;
// Vue.config.performance = true;

var getUrlParameter = function(name){
	name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
	var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
	var results = regex.exec(location.search);
	return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
};

var trackingId = 'UA-21887257-5';
if(getUrlParameter('ga') !== null && getUrlParameter('ga') !== undefined && getUrlParameter('ga') !== ""){
	trackingId = getUrlParameter('ga');
}

// console.log(remote.app.getName(), remote.app.getVersion());
import VueAnalytics from 'vue-ua';
Vue.use(VueAnalytics, {
	appName: remote.app.getName(),
	appVersion: remote.app.getVersion(),
	trackingId: trackingId,
	debug: false,
	vueRouter: router,
	trackPage: false,
});
ga('set', 'checkProtocolTask', null);
ga('set', 'checkStorageTask', null);
ga('set', 'historyImportTask', null);

Vue.use(Ionic);

import VueCollapse from 'vue2-collapse';
Vue.use(VueCollapse);

import VModal from 'vue-js-modal';
Vue.use(VModal);

Vue.prototype.$navigate = function(params){
	this.$router.push(params);
};

Vue.prototype.$goToPDP = function(sku, customization){
	if(this.$route.name == "all-products"){
		this.$navigate({ name: 'all-products-pdp', params: {sku: sku, passedcustomization: customization}});
	}else{
		this.$navigate({ name: 'your-vision-pdp', params: {sku: sku, passedcustomization: customization}});
	}
};

Vue.prototype.$goBack = function(){
	this.$router.go(-1);
	if('parentIFrame' in window){
		var obj = {};
		obj.action = "changeSource";
		// obj.path = to.path;
		parentIFrame.sendMessage(obj);
	}
}

/* eslint-disable no-new */
const app = new Vue({
	components: { App },
	store,
	router,
	template: '<app/>',
	created(){
		this.init();
	},
	methods: {
		async init(){
			// let storeId = await Storage.get({key: 'appStoreID'});
			// if(_.isNil(storeId) || _.isNil(storeId.value)){
			// 	//Set default store
			// 	await Storage.set({
			// 		key: 'appStoreID',
			// 		value: 1
			// 	});
			// 	app.$store.dispatch('setStore', 1);
			// }else{
			// 	app.$store.dispatch('setStore', storeId.value);
			// 	app.$ua.injectGlobalDimension(1, storeId.value);
			// }
			if(navigator.userAgent.indexOf("NEO-U9-H") != -1){
				let info = await Device.getInfo();
				if(info.model == "NEO-U9-H"){
					//running on device
					this.$store.dispatch('setStore', info.uuid);
					this.$ua.injectGlobalDimension(1, info.uuid);
				}
			}else{
				//dev / web version
				// console.log(this.$store.state.storeID);
				this.$ua.injectGlobalDimension(1, this.$store.state.storeID);

				//if we're in an iframe, listen for messages from the embed code
				if(window.self !== window.top){
					var local = this;

					if(getUrlParameter('storeId') !== null && getUrlParameter('storeId') !== undefined && getUrlParameter('storeId') !== ""){
						local.$store.dispatch('setStore', getUrlParameter('storeId'));
						local.$store.dispatch('setUserLoginStatus', true);
					}
					window.iFrameResizer = {
						//messages to our iframe from embed code
						onMessage: function(obj){
							if(obj.action == "setStore"){
								local.$store.dispatch('setStore', obj.store);
								// local.$store.dispatch('getAppProducts');
							}
							if(obj.action == "scroll"){
							}
						}
					}
				}
			}
			this.$store.dispatch('getAppCopy');
			this.$store.dispatch('getAppProducts');
			this.$store.dispatch('getAppFabrics');
			//account for app updates and content in this section changing
			this.$store.dispatch('setInspiration');
			//refresh products once an hour
			setInterval(this.refreshProducts, 60*60*1000);

			SplashScreen.hide();

			AsyncRoutes.Landing();
			AsyncRoutes.PDP();

			// setTimeout(this.checkForUpdate, 10*1000);

		},
		// async checkForUpdate(){
		// 	await Deploy.configure({channel: 'Production'})
		// 	const versions = await Deploy.getAvailableVersions();
		// 	console.log(versions);

		// 	try {
		// 		const currentVersion = await Deploy.getCurrentVersion();
		// 		console.log(currentVersion);
		// 		const resp = await Deploy.sync({updateMethod: 'auto'}, percentDone => {
		// 		  console.log(`Update is ${percentDone}% done!`);
		// 		});
		// 		if (!currentVersion || currentVersion.versionId !== resp.versionId){
		// 		  // We found an update, and are in process of redirecting you since you put auto!
		// 		  console.log("1")
		// 		}else{
		// 		  // No update available
		// 		  console.log("2")
		// 		}
		// 	  } catch (err) {
		// 		// We encountered an error.
		// 		console.log("3")
		// 		console.log(err);

		// 	  }

		// 	const update = await Deploy.checkForUpdate();
 		// 	if(update.available){
		// 		console.log("do update");
		// 	}else{
		// 		console.log("no update");
		// 	}
		// 	console.log(update);

		// },
		refreshProducts(){
			this.$store.dispatch('getAppProducts');
			this.$store.dispatch('getAppFabrics');
		}
	}
}).$mount('#app');
