<template>
	<div class="dealer-login-modal-container">
		<modal name="dealerLoginModal" class="content-toggle" :width="modalWidth" :height="modalHeight" @before-open="openedModal" :adaptive="true" :pivotY="0.1">
			<a @click="$modal.hide('dealerLoginModal');" class="close-icon"><icon icon-type="icon-close"></icon></a>
			<div class="login inner" :class="{'isLoading': loading}" v-if="step1">
				<div class="row header-copy">
					<div class="col-xs-12">
						<h2>Dealer Login</h2>
						<p>Customize the experience on this device by using your Backroom username and password below.</p>
						 <!-- to activate your custom pricing rules, product assortment and more on this device. -->
					</div>
				</div>
				<div class="row">
					<label class="col-xs-12 col-sm-12">Backroom Username:</label>
					<input @keyup.enter="doLogin" type="text" v-model="email" class="col-xs-12 col-sm-12" placeholder="XY01sales">
				</div>
				<div class="row">
					<label class="col-xs-12 col-sm-12">Password:</label>
					<input @keyup.enter="doLogin" type="password" v-model="password" class="col-xs-12 col-sm-12">
				</div>
				<div class="actions">
					<button @click="doLogin()" :disabled="email.length == 0 || password.length == 0" :class="{'btn-default': true, 'btn-submit': true, 'is-disabled': (email.length == 0 || password.length == 0)}">Login</button>
				</div>
				<div v-if="showBanner" :class="{'row': true, 'banner': true, 'hasError': true}" v-html="bannerText">
				</div>
				<div class="row forget-box">
					<a class="admin" href="https://backroom.flexsteel.com/Account/PasswordRecovery.aspx" target="_blank">Forgot Password?</a>
				</div>
				<div class="row legal">
					<div class="col-xs-12">
						<p>By clicking login you agree to the <a href="http://backroom.flexsteel.com/TermsAndConditions.aspx" target="_blank">Terms and Conditions</a></p>
					</div>
				</div>
			</div>

			<div class="login inner" :class="{'isLoading': loading}" v-if="step2">
				<div class="row header-copy">
					<div class="col-xs-12">
						<h2>Please Note</h2>
						<p>You have not configured a Backroom user to customize this product. To do so follow these steps:</p>
						<ol>
							<li>Lorem ipsum dolor sit amet</li>
							<li>Lorem ipsum dolor sit amet. e.g. XY01Kiosk</li>
							<li>Lorem ipsum dolor sit amet</li>
						</ol>
					</div>
				</div>
				<div class="actions">
					<button @click="$modal.hide('dealerLoginModal');" :class="{'btn-default': true, 'btn-submit': true}">Continue</button>
				</div>
			</div>

			<div class="login inner" :class="{'isLoading': loading}" v-if="step3">
				<div class="row header-copy">
					<div class="col-xs-12">
						<h2>Select A Dealer:</h2>
					</div>
				</div>
				<div class="row">
					<div class="col-xs-12 dealer-list">
						<p v-for="store in storeList" @click="chooseStore(store)">{{store.AccountName}}</p>
					</div>
				</div>
			</div>

			<div v-if="loading" class="loader">
				<fulfilling-bouncing-circle-spinner
					class="loader-inner"
					:animation-duration="2000"
					:size="100"
					color="#15bdef"
				/>
			</div>
		</modal>
	</div>
</template>

<script>
	import _ from 'lodash';
	import { mapGetters, mapActions } from 'vuex';
	import Icon from '../components/Icon';
	import { FulfillingBouncingCircleSpinner } from 'epic-spinners';

	export default {
		name: 'dealerLogin',
		components: {
			icon: Icon,
			fulfillingBouncingCircleSpinner: FulfillingBouncingCircleSpinner,
		},
		data(){
			return{
				showBanner: false,
				step1: true,
				step2: false,
				step3: false,
				accountNo: '',
				storeList: [],
				bannerText: '',
				adminLogin: true,
				loading: false,
				email: '',
				password: ''
			}
		},
		props: [
		],
		computed: {
			modalWidth(){
				if(this.isKiosk){
					return 1400;
				}else{
					if(this.windowWidth >= 500){
						return this.windowWidth - 100;
					}else{
						return this.windowWidth - 20;
					}
				}
			},
			modalHeight(){
				if(this.isKiosk){
					return 800;
				}else{
					if(this.windowHeight >= 700){
						return this.windowHeight - 100;
					}else{
						return this.windowHeight - 20;
					}
				}
			},
		},
		mounted(){
		},
		methods: {
			doLogin(){
				this.loading = true;
				this.showBanner = false;

				let formData = new FormData()
				formData.append('uid', this.email);
				formData.append('pid', this.password);
				formData.append('AccountNo', this.accountNo);
				let data = [...formData.entries()];
				let params = data.map(x => `${encodeURIComponent(x[0])}=${encodeURIComponent(x[1])}`).join('&');

				this.setUserId(this.email);

				this.$http.post(this.backroom_api_endpoint+"/Kiosk/api/2020-08/productdata", params, {
					headers: {
						'Content-Type': 'application/x-www-form-urlencoded',
						'apikey': this.backroom_api_key,
						'customername': 'Kiosk'
					}
				}).then(response => {
					if(response.data.Valid == "Valid User" || response.data.Valid == "Valid User/No Account"){
						this.showBanner = false;
						// this.setStore(response.data.data.store_id);
						//need to choose a store
						if(response.data.Valid == "Valid User/No Account"){
							this.storeList = response.data.Accounts;
							this.step1 = false;
							this.step2 = false;
							this.step3 = true;
							this.setToken(response.data.RefreshID);
						}
						if(response.data.Valid == "Valid User"){
							this.setToken(response.data.RefreshID);
							this.setStore(response.data.AccountNo);
							this.setProductAvailability(response.data.Products);
							this.setUserLoginStatus(true);
							this.$store.dispatch('getAppProducts');
							this.$store.dispatch('getAppCopy');
							this.$modal.hide('dealerLoginModal');
							this.$router.push("/landing");
						}
						// this.$store.dispatch('getStores');
						// this.$router.push("/landing");
					}else{
						this.showBanner = true;
						this.bannerText = "Invalid login. Please check your input and try again";
					}
					this.loading = false;
				}, err_response => {
					this.showBanner = true;
					this.bannerText = "Server error. Please wait a moment and try again.<br/><br/>If the issue persists contact: <a href='mailto:flexsteelalert@flexsteel.com'>flexsteelalert@flexsteel.com</a>";
					console.log("Error:", err_response);
					this.loading = false;
					this.step1 = true;
					this.step2 = false;
					this.step3 = false;

				});
			},
			chooseStore(store){
				this.accountNo = store.AccountNo;
				this.doLogin();
			},
			openedModal(event){
				this.step1 = true;
				this.step2 = false;
				this.step3 = false;
				this.email = this.$store.state.userId;
				this.password = "";
				this.loading = false;
				this.accountNo = "";
				//they are logged in already. Log them out and refresh products
				if(this.$store.state.userLoggedIn){
					this.setUserLoginStatus(false);
					this.setProductAvailability([]);
					this.$store.dispatch('getAppProducts');
					this.$store.dispatch('getAppCopy');
				}
			},
			...mapActions([
				'setToken',
				'setStore',
				'setUserId',
				'setUserLoginStatus',
				'setProductAvailability'
			])
		}
	}
</script>

