<template>
	<div>
		<div class="col tile-col tile-col-alt">
			<div class="btn-left" ref="swiperContainerBack" @click="scrollTileLeft('swiperContainer')" slot="button-prev"><div class="center"><i class="icon-down-open-big"></i></div></div>
			<div class="btn-right" @click="scrollTileRight('swiperContainer')" slot="button-next"><div class="center"><i class="icon-down-open-big"></i></div></div>
			<div class="tile-container" :style="containerStyles" ref="swiperContainer" v-on:scroll="showLeftTileScroll('swiperContainer')">
				<div class="tiles" :style="tilesStyles">
					<div class="item" v-for="fabric in filteredItems.data.items" :key="fabric.id" @click="setTile(fabric.id)" v-if="fabric.round !== 3">
						<customizable-tile :tile="fabric" :checked="customization[drapeType] == fabric.id" :type="drapeType" height="79" width="79"></customizable-tile>
					</div>
				</div>
			</div>
		</div>

		<div class="col tile-col tile-col-alt">
			<div class="row tag-holder">
				<div class="col-sm-12">
					<span v-for="(i, key) in itemJsFilters">
						<span v-for="type in i">
							<div class='tag has-icon' @click="setFilter(key, type)"><svg viewPort="0 0 9 9" version="1.1" xmlns="http://www.w3.org/2000/svg"><line x1="1" y1="9" x2="9" y2="1" stroke="#313d54" stroke-width="1"/><line x1="1" y1="1" x2="9" y2="9" stroke="#313d54" stroke-width="1"/></svg>{{type}}</div>
						</span>
					</span>
					<span v-if="keyword" >
						<div class='tag has-icon' @click="keyword=''"><svg viewPort="0 0 9 9" version="1.1" xmlns="http://www.w3.org/2000/svg"><line x1="1" y1="9" x2="9" y2="1" stroke="#313d54" stroke-width="1"/><line x1="1" y1="1" x2="9" y2="9" stroke="#313d54" stroke-width="1"/></svg>Search "{{keyword}}"</div>
					</span>

				</div>
			</div>
		</div>

		<div class="col tile-col tile-col-alt">
			<v-collapse-group :onlyOneActive="true">
				<v-collapse-wrapper :class="toggleOpenFilter(0)" :ref="'toggle-0'" v-on:onStatusChange="activateCollapseClass('toggle-0', 0)">
					<div class="filter-header" v-collapse-toggle :class="{active:collapse[0].classActive}">
						<span><icon :icon-type="{'icon-arrow-down-pdp': !collapse[0].classActive, 'icon-arrow-up-pdp': collapse[0].classActive}"></icon></span>
						FILTER / SEARCH
					</div>
					<div class="filter-options" v-collapse-content>
						<div class="col title-col title-col-alt">
							<div class="search-container-mobile">
								<form>
									<a class="search-box">
										<input id="searchInput" autocomplete="off" v-model="keyword" ref="keyword" type="text" placeholder="Enter Fabric SKU" :class="{'error': fabricsLength === 0}"/>
										<icon icon-type="icon-search-big"></icon>
									</a>
								</form>
							</div>
						</div>

						<div class="row filter-holder filter-tabs">
							<div class="col-xs-3 tab" :class="isActiveTab(facet.position)" @click="activateTab(facet)" v-for="(facet, index) in filteredItems.data.aggregations" :key="facet.name">
								{{facet.title}}
							</div>
						</div>
						<div class="row filter-holder">
							<div class="col-xs-12 filter-tab-content" :class="isActiveTab(facet.position)" v-for="(facet, index) in filteredItems.data.aggregations" :key="facet.name+'filter'">
								<div class="row">
									<div class="column " :class="{'col-xs-4': (facet.title == 'Material Type' || facet.title == 'Color Family'), 'col-xs-6': (facet.title == 'Fabric Grade' || facet.title == 'Wearability')}" v-for="bucket in facet.buckets" :key="bucket.key">
										<div class="option">
											<div @click="setFilter(facet.name, bucket.key)" :class="{'active': itemJsFilters[facet.name].includes(bucket.key)}">
												<span class="check"></span>
												<div class="filter-title"><span :class="machineName(facet, bucket.key)"></span> {{mappedLabel(facet, bucket.key)}}</div>
												<div class="clear"></div>
											</div>
										</div>
									</div>
								</div>
								<div class="row">
									<div class="col-xs-6">
										<a href="#clear-all" @click.prevent="clearAll()" class="clear-all">CLEAR ALL</a>
									</div>
									<div class="col-xs-6">
										<a href="#apply-filter" @click.prevent=""  v-collapse-toggle="'toggle-0'" class="apply">APPLY</a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</v-collapse-wrapper>
			</v-collapse-group>
		</div>

	</div>
</template>

<script>
import _ from 'lodash';
import { mapGetters, mapActions } from 'vuex';
import CustomizableTile from '../components/CustomizableTile';
import 'swiper/dist/css/swiper.css';
import { swiper, swiperSlide } from 'vue-awesome-swiper';
import dropdown from '../components/Dropdown';
import Icon from '../components/Icon';
import Itemsjs from 'itemsjs';

var configuration = {
	searchableFields: ['id', 'idAlt', 'ColorDescr', 'PatternDescr', 'Grade', 'WearCd'],
	sortings: {
		name_asc: {
			field: 'name',
			order: 'asc'
		}
	},
	aggregations: {
		PatternDescr: {
			title: 'Material Type',
			sort: 'term',
			conjunction: false,//OR
			size: 100
		},
		ColorDescr: {
			title: 'Color Family',
			sort: 'term',
			conjunction: false,//OR
			size: 100
		},
		Grade: {
			title: 'Fabric Grade',
			sort: 'term',
			conjunction: false,//OR
			size: 100
		},
		WearCd: {
			title: 'Wearability',
			sort: 'term',
			conjunction: false,//OR
			size: 100
		}

		//,
		// types: {
		// 	title: 'Frame',
		// 	sort: 'term',
		// 	conjunction: false,//OR
		// 	size: 100
		// },
		// materials: {
		// 	sort: 'term',
		// 	title: 'Material',
		// 	conjunction: true,//AND
		// 	size: 100
		// },
		// options: {
		// 	sort: 'term',
		// 	title: 'Options',
		// 	conjunction: true,//AND
		// 	size: 100
		// }
	}
}


export default {
	name: 'CoverChooserMobile',
	data(){
		var itemJsFilters = {};
		Object.keys(configuration.aggregations).map(function(v){
			itemJsFilters[v] = [];
		});

		return{
			'keyword': '',
			'itemJsFilters': itemJsFilters,
			tileWidth: 84,
			tileHeight: 84,
			activeFilters: [],
			activeTab: 1,
			collapse: [
				{ classActive: false }
			],

			swiperOption: {
				slidesPerView: 'auto',
				simulateTouch: true,
				slidesPerGroup: 2,
				slidesPerColumn: 2,
				spaceBetween: 5,
				slidesOffsetBefore: 58,
				slidesOffsetAfter: 58,
				slideToClickedSlide: false,
				navigation: {
					nextEl: '.btn-right',
					prevEl: '.btn-left'
				},
			},

		}
	},
	components: {
		swiper,
		swiperSlide,
		dropdown,
		icon: Icon,
		customizableTile: CustomizableTile,
	},
	props: [
		'drapeType',
		'rows',
		'product',
		'customization',
		'fabrics'
	],
	computed: {
		filteredItems(){
			var result = this.items.search({
				page: 1,
				per_page: 100000,
				query: this.keyword,
				filters: this.itemJsFilters
			});
			return result;
		},
		items(){
			return Itemsjs(this.fabrics, configuration);
		},
		containerStyles(){
			return "";
			return "height: "+(this.rows*this.tileHeight)+"px";
		},
		tilesStyles(){
			return "width: "+((this.fabricsLength*this.tileWidth)/this.rows+100)+"px";
		},
		fabricsLength(){
			return this.filteredItems.pagination.total;
			let local = this;
			let count = 0;
			_.forEach(this.fabrics, function(fabric, key){
				count++;
			});
			return count;
		},
		...mapGetters({
			lastRoute: 'getLastRoute',
			allRoutes: 'getAllRoutes'
		})
	},
	methods: {
		setTile(id){
			this.$emit('setTile', {"type": this.drapeType, id});
		},
		activateTab(facet){
			console.log(facet);
			this.activeTab = facet.position;
		},
		clearAll(){
			var filters = {};
			Object.keys(configuration.aggregations).map(function(v){
				filters[v] = [];
			})

			this.itemJsFilters = filters;

		},
		isActiveTab(tab){
			if(this.activeTab == tab){
				return "active-tab";
			}
			return "";
		},
		toggleOpenFilter(index){
			var styles = "";
			if(this.collapse[index].classActive){
				styles += " active ";
			}
			if(!_.isNil(this.$refs['toggle-'+index]) && !_.isNil(this.$refs['toggle-'+index].status)){
				styles += ' open ';
			}
			return styles;
		},
		activateCollapseClass(refName, index){
			this.collapse[index].classActive = this.$refs[refName].status;
		},
		setFilter(type, val){
			if(!_.includes(this.itemJsFilters[type], val)){
				this.itemJsFilters[type].push(val);
			}else{
				_.pull(this.itemJsFilters[type], val);
			}
			//hack to force reactivity
			this.itemJsFilters[type].splice(10000);
			// this.$forceUpdate();
			return true;
		},
		//change what we display to consumers vs what comes in the API
		mappedLabel(facet, item){
			if(facet.name == "PatternDescr"){
				if(item == "Floral/Leaf Pattern"){return "Pattern";}
			}
			return item;
		},
		//something that we can reference via CSS
		machineName(facet, name){
			var facetName = facet.name.replace(/[!\"#$%&'\(\)\*\+,\.\/:;<=>\?\@\[\\\]\^`\{\|\}~]/g, '').toLowerCase().split(" ").join("-");
			name = name.replace(/[!\"#$%&'\(\)\*\+,\.\/:;<=>\?\@\[\\\]\^`\{\|\}~]/g, '').toLowerCase().split(" ").join("-");
			return "filter-icon "+facetName+" "+name;
		},
		scrollTileLeft(ref){
			let container = this.$refs[ref];
			container.scroll({
				top: 0,
				left: container.scrollLeft-450,
				behavior: 'smooth'
			});
		},
		scrollTileRight(ref){
			let container = this.$refs[ref];
			container.scroll({
				top: 0,
				left: container.scrollLeft+450,
				behavior: 'smooth'
			});
		},
		showLeftTileScroll(ref){
			let container = this.$refs[ref];
			let backButton = this.$refs[ref+"Back"];
			if(container.scrollLeft == 0){
				backButton.style.display = "none";
			}else{
				backButton.style.display = "block";
			}
		},
		...mapActions([
			'popRoute'
		])
	}
};
</script>

