<template>
	<div class="PDP max-full-page" :class="{'standard': (product && !product.isFeatured), 'not-kiosk': !isKiosk, 'is-really-kiosk': isKiosk}" ref="pdp" :style="heightStyle">
		<vue-headful v-if="product" :title="product.t + ' '+product.s_t"/>
		<!-- PDP with draping  -->
		<div v-if="!product">
			<h1>Product not found</h1>
			<p>{{sku}} is not currently available. Checking for an update...</p>
			<back-button></back-button>
		</div>
		<div v-if="product && product.isFeatured">
			<div class="tab-content row" id="top" ref="top" :style="heightStyle">
				<div class="left-side draped col-sm-7" :style="heightStyle">
					<back-button v-if="!included || product.sku !== '1356-11P'" style="background-color: #F7F9FA;"></back-button>
					<div v-if="included && showback && product.sku === '1356-11P'" class="back" @click="goBackToFLX()"><div class="inner-back">&lt; Back</div></div>
					<div class="logo-container" v-if="$store.state.appCopy.storeLogo"><img :src="$store.state.appCopy.storeLogo" :alt="$store.state.appCopy.storeName"/></div>
					<div v-if="product.soon === true" class="coming-soon"></div>
					<h1 @dblclick="inventoryToggle()">{{product.t}}<br/><span>{{product.s_t}}</span></h1>
					<div class="spin-ico-mobile"><icon icon-type="icon-spin-empty"></icon></div>
					<div class="image-details-mobile" v-if="imageDetails">{{imageDetailsMobile}}</div>
					<h4 @dblclick="inventoryToggle()" v-if="product.price">{{product.store_name}} {{$store.state.appCopy.pricingLabel}} {{product.price}} <span class="sale" v-if="product.sale_price">{{product.sale_price}}</span><br/><span>{{product.available}}</span></h4>
					<div class="marxentContainer">
						<div id="marxentViewer" v-if="!configuratorError" :class="{'go-large': shouldGoLarge, 'content': true}">

						</div>
						<div id="marxentError" v-if="configuratorError" >
							<div class="error">
								<h3>Configuration Error</h3>
								<p>There was an error loading the configuration tool. Please try again later.</p>
							</div>
						</div>
					</div>
					<div class="image-details" v-if="imageDetails">{{imageDetails}}</div>
					<div class="actions">
						<!-- <div @click="getConfiguration();">Get Config</div> -->
						<saved-button @click="$ua.trackEvent('360-SKU', 'Click', 'Add to saved Items', product.sku);" shadow="true" :sku="product.sku" :customization="customization"></saved-button>
						<a @click="scrollBottom();$ua.trackEvent('360-SKU', 'Click', 'View Product Details');" class="btn-secondary has-icon add-shadow"><span><icon icon-type="icon-arrow-down-pdp"></icon><b class="desktop">VIEW PRODUCT</b> DETAIL<b class="desktop">S</b></span></a>
					</div>
				</div>
				<div class="right-side draped col-sm-5" :class="{'is-flx': product.sku === '1356-11P'}" :style="minHeightStyle">
					<div class="flx-promo" v-if="product.sku === '1356-11P'" @click="setVideo('https://s3.amazonaws.com/flexsteel-retail-pilot/uploads/flx-video-30s.mp4')">
						<div class="content"></div>
					</div>
					<div class="title-area" :class="{'mobiletoggle': isAToggleOpen()}" v-if="product.round !== '3'">
						<h3>Tap swatches below<br/>to see how they look.</h3>
						<p>Hundreds more available at the fabric wall, just ask a store associate.</p>
						<div class="detail" v-if="loaded">
							<div class="mobile-only-pdp" v-if="!isKiosk">
								<v-collapse-group :onlyOneActive="true">
									<v-collapse-wrapper :class="toggleOpenFilter(0)" :ref="'toggle-0'" v-on:onStatusChange="activateCollapseClass('toggle-0', 0)">
										<div class="header" v-collapse-toggle :class="{active:collapse[0].classActive}">
											{{product.s_t}} Fabrics
											<span><icon :icon-type="{'icon-arrow-down-pdp': !collapse[0].classActive, 'icon-arrow-up-pdp': collapse[0].classActive}"></icon></span>
										</div>
										<div class="options" v-collapse-content>
											<cover-chooser-mobile :product="product" :fabrics="displayedCovers" :customization="customization" drape-type="FABRIC" :rows="2" @setTile="setTile">{{product.s_t}} Fabrics</cover-chooser-mobile>
										</div>
									</v-collapse-wrapper>

									<v-collapse-wrapper v-if="hasTwoTone" :class="toggleOpenFilter(0)" :ref="'toggle-3'" v-on:onStatusChange="activateCollapseClass('toggle-3', 0)">
										<div class="header" v-collapse-toggle :class="{active:collapse[0].classActive}">
											{{product.s_t}} Fabric Two-Tone Inside Fabrics
											<span><icon :icon-type="{'icon-arrow-down-pdp': !collapse[0].classActive, 'icon-arrow-up-pdp': collapse[0].classActive}"></icon></span>
										</div>
										<div class="options" v-collapse-content>
											<cover-chooser-mobile :product="product" :fabrics="displayedCovers" :customization="customization" drape-type="BODY 2" :rows="2" @setTile="setTile">{{product.s_t}} Fabric Two-Tone Inside Fabrics</cover-chooser-mobile>
										</div>
									</v-collapse-wrapper>
									<v-collapse-wrapper v-if="hasCustomPillows" :class="toggleOpenFilter(1)" :ref="'toggle-1'" v-on:onStatusChange="activateCollapseClass('toggle-1', 0)">
										<div class="header" v-collapse-toggle :class="{active:collapse[1].classActive}">
											Accent Pillow Fabrics
											<span><icon :icon-type="{'icon-arrow-down-pdp': !collapse[1].classActive, 'icon-arrow-up-pdp': collapse[1].classActive}"></icon></span>
										</div>
										<div class="options" v-collapse-content>
											<cover-chooser-mobile :product="product" :fabrics="displayedCovers" :customization="customization" drape-type="PILLOW_1" :rows="2" @setTile="setTile">Accent Pillow Fabrics</cover-chooser-mobile>
										</div>
									</v-collapse-wrapper>
								<v-collapse-wrapper v-if="hasCustomPillows2"  :class="toggleOpenFilter(2)" :ref="'toggle-2'" v-on:onStatusChange="activateCollapseClass('toggle-2', 0)">
									<div class="header" v-collapse-toggle :class="{active:collapse[2].classActive}">
										Additional Accent Pillow Fabrics
										<span><icon :icon-type="{'icon-arrow-down-pdp': !collapse[2].classActive, 'icon-arrow-up-pdp': collapse[2].classActive}"></icon></span>
									</div>
									<div class="options" v-collapse-content>
										<cover-chooser-mobile :product="product" :fabrics="displayedCovers" :customization="customization" drape-type="PILLOW_2" :rows="2" @setTile="setTile">Additional Accent Pillow Fabrics</cover-chooser-mobile>
									</div>
								</v-collapse-wrapper>

							</v-collapse-group>
						</div>

						<div class="desktop-only-pdp">
							<cover-chooser-archive  key="desktop-chooser-flx" v-if="product.round == 3" :product="product" :fabrics="flxCovers" :customization="customization" drape-type="FABRIC" :rows="1" @setTile="setTile">{{product.s_t}} Fabrics</cover-chooser-archive>
							<div v-else>
								<cover-chooser key="desktop-chooser-1" :product="product" :fabrics="displayedCovers" :customization="customization" drape-type="FABRIC" :rows="bodyFabricRows" @setTile="setTile">{{product.s_t}} Fabrics</cover-chooser>
								<cover-chooser key="desktop-chooser-2" v-if="hasTwoTone" :product="product" :fabrics="displayedCovers" :customization="customization" drape-type="BODY 2" :rows="bodyFabricRows" @setTile="setTile">{{product.s_t}} Fabric Two-Tone Inside Fabrics</cover-chooser>
								<cover-chooser key="desktop-chooser-3" v-if="hasCustomPillows" :product="product" :fabrics="displayedCovers" :customization="customization" drape-type="PILLOW_1" :rows="pillowFabricRows" @setTile="setTile">Accent Pillow Fabrics</cover-chooser>
								<cover-chooser key="desktop-chooser-4" v-if="hasCustomPillows2" :product="product" :fabrics="displayedCovers" :customization="customization" drape-type="PILLOW_2" :rows="pillowFabricRows" @setTile="setTile">Additional Accent Pillow Fabrics</cover-chooser>
							</div>
						</div>

						<div class="col related" v-if="relatedProducts.length >= 1">
							<strong class="title">Related Products</strong>
							<div class="related-items">
								<product-grid-item class="related-item col-xs-6 col-sm-4" type="related" v-for="p in relatedProducts" :product="p" ></product-grid-item>
								<!-- :customization="customization" -->
							</div>
						</div>
					</div>
				</div>
			</div>
			</div>
			<div class="tab-content row" id="bottom" ref="bottom" :style="heightStyle">
				<div class="left-side draped col-sm-7" :style="heightStyle">
					<div class="row inner">
						<h1 @dblclick="inventoryToggle()">{{product.t}}<br/><span>{{product.s_t}}</span></h1>
						<h4 @dblclick="inventoryToggle()" v-if="product.price">{{product.store_name}} {{$store.state.appCopy.pricingLabel}} {{product.price}} <span class="sale" v-if="product.sale_price">{{product.sale_price}}</span><span>{{product.available}}</span></h4>
						<div class="col-sm-12 dim-image">
							<diagram :type="product.types[0]" view="horizontal" :diagram_width="product.width" :diagram_height="product.height" :diagram_depth="product.depth"></diagram>
						</div>
						<div class="col-sm-8 description">
							<div class="sku">SKU: <strong>{{product.sku}}</strong></div>
							<div v-html="description" :class="{'long-text': description.length >= 400}"></div>
							<div v-if="product.catalog" class="digital-catalog-launcher" @click="launchCatalogPage(product.sku)">
								<div v-if="!loadingCatalog">View {{product.catalog}} Details</div>
								<div v-if="loadingCatalog">Loading...</div>
							</div>
						</div>
						<div class="col-sm-4 dims">
							<dimension title="General Dimensions" :height="product.height" :width="product.width" :depth="product.depth"></dimension>

							<div class="seating-dims" v-if="product.s_dim">
								<strong>Seating Area Dimensions</strong><br/>
								<span v-html="product.s_dim"></span>
							</div>
						</div>
					</div>
					<div class="actions">
						<saved-button @click="$ua.trackEvent('360-SKU', 'Click', 'Add to saved Items', product.sku);" shadow="true" :customization="customization" :sku="product.sku"></saved-button>
						<a @click="scrollTop();$ua.trackEvent('360-SKU', 'Click', 'Back to Overview');" class="btn-secondary has-icon add-shadow"><span><icon icon-type="icon-arrow-up-pdp"></icon>BACK TO OVERVIEW</span></a>
					</div>
				</div>
				<div class="right-side draped col-sm-5">
					<img v-if="product.bss == 1" class="blue-steel-spring" src="~@/assets/pdp/bss/1.jpg">
					<img v-if="product.bss == 2" class="blue-steel-spring" src="~@/assets/pdp/bss/2.jpg">
					<img v-if="product.bss == 3" class="blue-steel-spring" src="~@/assets/pdp/bss/3.jpg">
					<div class="inner">
						<div v-if="featureText" class="additional-details">
							<h4>Additional Details:</h4>
							<ul v-html="featureText"></ul>
						</div>
					</div>
				</div>

			</div>
		</div>

		<!-- Standard PDP -->

		<div class="tab-content row" v-if="product && !product.isFeatured">
			<div class="left-side standard col-sm-8">
				<back-button></back-button>
				<div class="logo-container" v-if="$store.state.appCopy.storeLogo"><img :src="$store.state.appCopy.storeLogo" :alt="$store.state.appCopy.storeName"/></div>
				<div v-if="product.soon === true" class="coming-soon"></div>
				<h1 @dblclick="inventoryToggle()" :class="{'multi-line': (product.t.length + product.s_t.length >= 30)}">{{product.t}} {{product.s_t}}</h1>
				<h4 @dblclick="inventoryToggle()" v-if="product.price">{{product.store_name}} {{$store.state.appCopy.pricingLabel}} {{product.price}} <span class="sale" v-if="product.sale_price">{{product.sale_price}}</span></h4>
				<h5 @dblclick="inventoryToggle()">{{product.available}}</h5>
				<div id="imageViewer">
					<div class="content hero">
						<div class="">
							<cached-image :src="heroImage"/>
						</div>
					</div>
					<div class="image-details" v-if="imageDetails">{{imageDetails}}</div>
					<div class="swatches">
						<div v-for="(image, index) in product.img" class="swatch" @click="changeImage(index)" :class="{active: index == selectedSwatch}">
							<div class="content">
								<cached-image :src="image"/>
							</div>
						</div>
					</div>
				</div>
				<div class="actions">
					<saved-button :sku="product.sku" ></saved-button>
				</div>
			</div>
			<div class="right-side standard col-sm-4">
				<div class="dims">
					<dimension title="General Dimensions" :height="product.height" :width="product.width" :depth="product.depth"></dimension>

					<div class="seating-dims" v-if="product.s_dim">
						<strong>Seating Area Dimensions</strong><br/>
						<span v-html="product.s_dim"></span>
					</div>
				</div>
				<div class="description">
					<div class="sku">SKU: <strong>{{product.sku}}</strong></div>
					<div v-html="description"></div>
					<div v-if="product.catalog" class="digital-catalog-launcher" @click="launchCatalogPage(product.sku)">
						<div v-if="!loadingCatalog">View {{product.catalog}} Details</div>
						<div v-if="loadingCatalog">Loading...</div>
					</div>
				</div>

				<div class="dim-image">
					<diagram :type="product.types[0]" view="stacked" :diagram_width="product.width" :diagram_height="product.height" :diagram_depth="product.depth"></diagram>
					<!-- <img :src="product.diag"/> -->
				</div>
				<div class="copy">
					<h4>We stand behind what we make.</h4>
					<p>Our products are built on beliefs and values that evolved through over a century of manufacturing. We construct our furniture with care and precision, and we hold ourselves accountable for every detail.</p>
					<p>We believe the end result makes the extra effort worth the many years it lasts.</p>
				</div>
				<div class="col related" v-if="relatedProducts.length >= 1">
					<strong class="title">Related Products</strong>
					<div class="related-items">
						<product-grid-item class="related-item col-xs-6 col-sm-4" type="related" v-for="p in relatedProducts" :product="p" ></product-grid-item>
					</div>
				</div>
			</div>
		</div>
		<bottom-nav></bottom-nav>
		<modal name="videoPlayer" class="video-toggle" width="100%" height="100%" :adaptive="true">
			<a @click="hideVideo();" class="close-icon"><icon icon-type="icon-close"></icon></a>
			<div class="player" v-if="videoPlayerURL != ''">
				<video autoplay="true"
					poster="iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mP89etbPQAJSwNrvKpw1AAAAABJRU5ErkJggg=="
					preload="false"
					:src="videoPlayerURL"
					width="960"
					height="538"
					controls="true"
					ref="videoPlayer">
				</video>
			</div>
		</modal>
		<inventory-viewer v-if="product" :product="product"></inventory-viewer>
	</div>
</template>

<script>
	import Vue from 'vue';
	import _ from 'lodash';
	import BottomNav from '../components/BottomNav';
	import BackButton from '../components/BackButton';
	import SavedButton from '../components/SavedButton';
	import CachedImage from '../components/CachedImage';
	import Diagram from '../components/Diagram';
	import Dimension from '../components/Dimension';
	import InventoryViewer from '../components/InventoryViewer';
	import ProductGridItem from '../components/ProductGridItem';
	import CustomizableTile from '../components/CustomizableTile';
	import CoverChooser from '../components/CoverChooser';
	import CoverChooserArchive from '../components/CoverChooserArchive';
	import CoverChooserMobile from '../components/CoverChooserMobile';
	import Icon from '../components/Icon';

	import { Decoder } from '@nuintun/qrcode';

	import { Plugins, FilesystemDirectory, Capacitor } from '@capacitor/core';
	import { DocumentViewer } from '@ionic-native/document-viewer/ngx';
	const { Filesystem } = Plugins;
	import { HTTP } from '@ionic-native/http/ngx';
	const http = new HTTP();

	import vueHeadful from 'vue-headful';
	import VueScrollTo from 'vue-scrollto';

	import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
	const iab = new InAppBrowser();

	import { mapGetters } from 'vuex';
	var viewerInstance = null;
	var initalLoad = true;

	export default {
		name: 'PDP',
		data(){
			return{
				configurator: null,
				configuratorError: false,
				iframeURL: '',
				videoPlayerURL: '',
				lastVideoScroll: 0,
				loaded: false,
				loadingCatalog: false,
				'customization': {
					'FABRIC': '959-80',
					'BODY': '959-80',
					'BODY 2': '959-80',
					'PILLOW_1': '011-22',
					'PILLOW_2': '011-22',
					'LEGS': 'F'
				},
				collapse: [
					{ classActive: false },
        			{ classActive: false },
					{ classActive: false }
				],
				swiperOptionAlt: {
					slidesPerView: 'auto',
					simulateTouch: true,
					slidesPerGroup: 2,
					spaceBetween: 5,
					slidesOffsetBefore: 58,
					slidesOffsetAfter: 58,
					slideToClickedSlide: false,
					navigation: {
						nextEl: '.btn-right',
						prevEl: '.btn-left'
					},
				},
				description: "",
				featureText: "",
				displayedCovers: [],
				qrObserver: null,
				flxCovers: [
					{"type":"FABRIC","id":"L771_00","img":"static/img/base/row3/L771_00.jpg","round":3},
					{"type":"FABRIC","id":"L389_02","img":"static/img/base/row3/L389_02.jpg","round":3},
					{"type":"FABRIC","id":"L764_01","img":"static/img/base/row3/L764_01.jpg","round":3},
				],
				'selectedSwatch': 0
			}
		},
		props: [
			'sku',
			'included',
			'showback',
			'passedcustomization'
		],
		directives: {
			scrollTo: VueScrollTo
		},
		components: {
			bottomNav: BottomNav,
			backButton: BackButton,
			coverChooser: CoverChooser,
			coverChooserArchive: CoverChooserArchive,
			coverChooserMobile: CoverChooserMobile,
			cachedImage: CachedImage,
			diagram: Diagram,
			dimension: Dimension,
			inventoryViewer: InventoryViewer,
			savedButton: SavedButton,
			vueHeadful: vueHeadful,
			productGridItem: ProductGridItem,
			icon: Icon,
			customizableTile: CustomizableTile,
		},
		computed: {
			flxCoversTmp(){
				var covers = [];
				for(var x = 0; x <= 1000; x++){
					var tmpCover = _.cloneDeep(this.flxCovers[0]);
					tmpCover.id += x;
					covers.push(tmpCover);
				}
				return covers;
			},
			isKiosk(){
				if(this.appId == "com.flexsteel.kiosk"){
					return true;
				}
				return false;
			},
			heightStyle(){
				if(this.isKiosk){
					return {};
				}
				var styles = {
					'height': (this.windowHeight+'px')
				};
				if(this.windowWidth <= 767){
					styles = {};
				}
				return styles;
			},
			minHeightStyle(){
				var styles = {
					'min-height': (this.windowHeight+'px')
				};
				if(this.windowWidth <= 767){
					styles = {};
				}
				return styles;
			},
			shouldGoLarge(){
				if(_.includes(this.product.types, 'Sectionals') || _.includes(this.product.types, 'Sofas') || _.includes(this.product.types, 'Loveseats')){
					return true;
				}else{
					return false;
				}
			},
			product(){
				return this.$store.getters.getProductById(this.sku);
			},
			imageDetails(){
				if(!this.product.isFeatured){
					//standard PDP
					return this.product.img_t[this.selectedSwatch];
				}else{
					let res = this.product.t +" "+this.product.s_t+" "+this.product.sku+" in "+this.customization.FABRIC;
					return res;
				}
			},
			imageDetailsMobile(){
				if(!this.product.isFeatured){
					//standard PDP
					return this.product.img_t[this.selectedSwatch];
				}else{
					let res = this.product.sku+" in "+this.customization.FABRIC;
					return res;
				}
			},
			heroImage(){
				let selected = this.product.img[this.selectedSwatch];
				selected = selected.replace("styles/large/", "styles/zoom/");
				return selected;
			},
			swiperFabric() {
				if(!_.isNil(this.$refs.swiperFabric)){
					return this.$refs.swiperFabric.swiper;
				}
			},
			selectedBodyFabric(){
				return this.customization.FABRIC;
			},
			selectedAccentPillow(){
				return this.customization.PILLOW;
			},
			hasBlueSteel(){
				if(this.featureText.indexOf("Blue Steel Spring") !== -1){
					return true;
				}else{
					return false;
				}
			},
			relatedProducts(){
				return this.$store.getters.getRelatedProductsByType(this.product.t, this.product.sku, this.product.types);
			},
			bodyFabricRows(){
				if(this.hasTwoTone){
					return 2;
				}
				return 3;
			},
			pillowFabricRows(){
				if(this.hasTwoTone){
					return 1;
				}
				return 2;
			},
			hasTwoTone(){
				if(this.product.two_tone == 1){
					return true;
				}
				return false;
			},
			hasCustomPillows(){
				if(_.includes(this.product.customOptions, "PILLOW")){
					return true;
				}
				return false;
			},
			hasCustomPillows1(){
				if(_.includes(this.product.customOptions, "PILLOW+1")){
					return true;
				}
				return false;
			},
			hasCustomPillows2(){
				if(_.includes(this.product.customOptions, "PILLOW+2")){
					return true;
				}
				return false;
			},
			pillowsLength(){
				return this.pillows.length;
				let local = this;
				let count = 0;
				_.forEach(this.pillows, function(pillow, key){
					if(pillow.round == local.product.round){
						count++;
					}
				});
				return count;
			},
			...mapGetters({
				defaultFabrics: 'customizableDefaultFabrics',
				fabrics: 'customizableFabrics',
				// pillows: 'customizablePillows',
				pillows: 'customizableFabrics',
				lastRoute: 'getLastRoute'
			})
		},
		methods: {
			setVideo(url){
				this.videoPlayerURL = url;
				this.lastVideoScroll = window.scrollY;
				window.scroll({
					top: 0,
					left: 0,
					behavior: 'smooth'
				});
				this.$modal.show('videoPlayer');
			},
			hideVideo(){
				this.videoPlayerURL = '';
				window.scroll({
					top: this.lastVideoScroll,
					left: 0,
					behavior: 'smooth'
				});
				this.$modal.hide('videoPlayer');
			},
			isAToggleOpen(){
				var res = false;
				_.forEach(this.collapse, function(collapse, key){
					if(collapse.classActive){
						res = true;
					}
				});
				return res;
			},
			toggleOpenFilter(index){
				var styles = "";
				if(this.collapse[index].classActive){
					styles += " active ";
				}
				if(!_.isNil(this.$refs['toggle-'+index]) && !_.isNil(this.$refs['toggle-'+index].status)){
					styles += ' open ';
				}
				return styles;
			},
			activateCollapseClass(refName, index){
				this.collapse[index].classActive = this.$refs[refName].status;
			},
			launchCatalogPage(sku){
				if(this.isKiosk){
					this.$modal.show('contentPlayer', {sku: sku});
					// this.iframeURL = "https://docs.google.com/gview?embedded=true&url="+url;
					// this.$modal.show('contentPlayer');
				}else{
					if(Capacitor.getPlatform() == "web"){
						let browser = iab.create("https://catalog.flexsteel.com/generate/?sku="+sku, "_system");
						// const browser = iab.create("https://catalog.flexsteel.com/generate/?sku="+sku, "_self", "location=yes,zoom=no,toolbarcolor=#15bdef,closebuttoncolor=#FFFFFF");
						// browser.on('loadstop').subscribe(event => {
						// 	// browser.insertCSS({ code: "html{zoom: 0.5;}" });
						// 	// browser.insertCSS({ code: "#ctl00_LeftMenuLoginView_ucLeftNav_divLeftMenu{display:none;}" });
						// });
					}else{
						try{
							this.loadingCatalog = true;
							var local = this;
							var fileName = sku+".pdf";
							var url = "https://catalog.flexsteel.com/generate/?sku="+sku;
							Filesystem.getUri({
								directory: FilesystemDirectory.Data,
								path: ''
							}).then((result) => {
								let targetPath = result.uri + '/' + fileName;
								http.downloadFile(url, null, null, targetPath).then((res) => {
									local.loadingCatalog = false;
									var options = {
										title: sku+" Catalog Page"
									}
									var document = new DocumentViewer();
									document.viewDocument(targetPath, 'application/pdf', options);
								}, (res) => {
									console.log('error downloading', res);
									local.loadingCatalog = false;
								});
							}).catch(e => {
								console.log(e);
								this.$modal.show('contentPlayer', {sku: sku});
								window.scroll({
									top: 0,
									left: 0,
									behavior: 'smooth'
								});
								local.loadingCatalog = false;
							});
						}catch(e){
							console.log(e);
							this.$modal.show('contentPlayer', {sku: sku});
							window.scroll({
								top: 0,
								left: 0,
								behavior: 'smooth'
							});
							local.loadingCatalog = false;
						}

					}
				}
				ga("send", "event", "Catalog Page Clicked", "Click", sku);
			},
			inventoryToggle(){
				this.$modal.show('inventoryToggle');
				// if(_.includes(this.product.collections, 'Latitudes') || _.includes(this.product.collections, 'Wynwood') || _.includes(this.product.collections, 'Casegoods')){
				//
				// }else{
				// 	console.log("no inventory for non-latitudes/wynwood");
				// }
			},
			goBackToFLX(){
				this.$emit('back');
			},
			scrollTop(){
				let container = this.$refs.pdp;
				let target = this.$refs.top;
				let scroll = target.offsetTop;
				if(this.included){
					scroll = 0;
				}
				if(this.windowWidth <= 767){
					window.scroll({
						top: scroll,
						left: 0,
						behavior: 'smooth'
					});
				}else{
					container.scroll({
						top: scroll,
						left: 0,
						behavior: 'smooth'
					});
				}
			},
			scrollBottom(){
				let container = this.$refs.pdp;
				let target = this.$refs.bottom;
				if(this.windowWidth <= 767){
					window.scroll({
						top: target.offsetTop,
						left: 0,
						behavior: 'smooth'
					});
				}else{
					container.scroll({
						top: target.offsetTop,
						left: 0,
						behavior: 'smooth'
					});
				}
			},
			setTile({type, id}){
				this.customization[type] = id;
				var features = this.getMarxentFeatures();
			},
			changeImage(index){
				this.selectedSwatch = index;
			},
			getMarxentFeatures(){
				var features = [];
				if(this.configurator !== null){
					//FLX chair
					if(this.product.round === '3'){
						features.push("UPHOLSTERY", this.customization.FABRIC);
						return features;
					}

					features.push("BODY", this.customization.FABRIC);
					this.configurator.updateOption(this.customization.FABRIC,"Body Fabric");

					if(this.hasTwoTone){
						features.push("BODY 2", this.customization["BODY 2"]);
						this.configurator.updateOption(this.customization["BODY 2"],"Body Fabric 2");
					}
					if(this.hasCustomPillows){
						this.configurator.updateOption(this.customization.PILLOW_1,"Pillow Group 1");
						features.push("PILLOW GROUP 1", this.customization.PILLOW_1);
					}
					if(this.hasCustomPillows2){
						this.configurator.updateOption(this.customization.PILLOW_2,"Pillow Group 2");
						features.push("PILLOW GROUP 2", this.customization.PILLOW_2);
					}
				}
				return features;
			},
			async initMarxent(){
				let local = this;
				//add splitLayout=true to show the list of configurations
				this.configurator = new MxtProductConfigurator.MxtKongfigurator({
					spinCommon: {
						apiKey: 'AIzaSyDCLwjmciL795EruchVhX6V6GKjngkMgHU',
						element: document.getElementById('marxentViewer'),
						expandButton: {},
						webArMode: "OnDemand",
						expandButton: {
							onExpand: () => {
								let mainElement = document.getElementById('marxentViewer');
								mainElement.style.position = 'absolute';
								mainElement.style.top = '0px';
								mainElement.style.left = '0px';
								mainElement.style.width = '100vw';
								mainElement.style.height = '100vh';
								mainElement.style.setProperty('height', '100vh', 'important');
							},
							onCollapse: () => {
								let mainElement = document.getElementById('marxentViewer');
								mainElement.style.position = null;
								mainElement.style.width = null;
								mainElement.style.height = null;
								mainElement.style.top = null;
								mainElement.style.left = null;
							}
						}
					},
					spin3d:{
						camera: {
							enabled: false
						},
						screenshot: {
							enabled: false,
						},
					},
					disableAllPickerComponents: true,
					preview: false,
					disabledComponents: ['share'],
					assetEnvironment: "Latest",
					configurationId: this.sku,
					backgroundColor: '#F7F9FA',
					backgroundColorOnScreenshots: '#FFFFFF',
					// initialSkus: [this.customization.FABRIC],
					// initialSkus: ["8648-31181-30458-70553-40FD"],
					callbacks: {
						onError: function(error){
							console.log("Error", error, error.reason.errorCode);
							if(error && error.reason && error.reason.errorCode === 1){
								//no config found
								local.configuratorError = true;
								local.configurator.destroy();
								local.configurator = null;
							}
						},
					}
				});
				await this.configurator.init();
				console.log(this.configurator);
				this.getMarxentFeatures();
			},
			getConfiguration(){
				const config = this.configurator.configurationController.model;
				console.log(config.steps[0].name);
				let body_fabric_step = config.steps.find(step => step.name === 'Boby Fabric');
				const data = config.steps.map(step => this.configurator.configurationController.getSelectedOption(step));
				console.log(body_fabric_step, data)
				console.log(this.configurator.configurationController.setSelectedOption(0, "108-40"));

			},
			getProductDetails(){
				let formData = new FormData()
				formData.append('action', "getProduct");
				formData.append('sku', this.sku);

				Vue.prototype.$http.post(Vue.prototype.api_endpoint, formData, {
					headers: {
						'Content-Type': 'multipart/form-data'
					}
				}).then(response => {
					if(response.data.status == true){
						let productData = response.data.data;
						this.description = productData.d;
						this.featureText = productData.f_t;
					}
				}, err_response => {
					console.log("Error getting product details:", err_response);
				});

			},
			createCovers(_fabric){
				this.displayedCovers = [];
				this.displayedCovers = _.clone(_fabric, true);
				//motion products have a subset of fabrics
				if(this.product.motion == 1){
					this.displayedCovers = this.displayedCovers.filter(fabric => (fabric.MotionDraped == "True"));
				}
					// this.fabrics.forEach((c, i) => setTimeout(() => this.displayedCovers.push(c), i * 20))

					// let local = this;
					// setTimeout(function(){
					// 	local.loaded = true;
					// }, 1000);


				// if(!_.isNil(this.swiperFabric)){
				// 	this.swiperFabric.slideTo(0, 1000, false);
				// }

				_.forEach(this.displayedCovers, function(cover, key){
					cover.idAlt = cover.id.replace("-", "");
				});
			}
		},
		watch: {
			// customization: {
			// 	handler(val, oldval){
			// 		// this.$router.replace({query: {customization: JSON.stringify(val)}});
			// 		// this.$route.query.customization = JSON.stringify(val);
			// 	},
			// 	deep: true
			// }
			fabrics: {
				handler(val, oldval){
					this.createCovers(val);
				}

			}
		},
		updated(){
			initalLoad = true;
			// && !this.isKiosk
			if(this.product.isFeatured){
				// this.initCylindo();
			}
		},
		beforeDestroy(){
			if (this.configurator) {
				this.configurator.destroy();
				this.configurator = null;
				console.log("destroyed");
			}
		},
		mounted(){
			this.$nextTick(function(){
				this.getProductDetails();
				if(!_.isNil(this.passedcustomization)){
					// this.customization = this.passedcustomization;
					this.customization = Object.assign({}, this.passedcustomization);
				}else{
					if(!_.isNil(this.$route.query.customization)){
						this.customization = Object.assign({}, JSON.parse(this.$route.query.customization));
					}else{
						this.customization.BODY = this.defaultFabrics[0] || '011-22';
						this.customization["BODY 2"] = this.defaultFabrics[0] || '011-22';
						this.customization.FABRIC = this.defaultFabrics[0] || '011-22';
						this.customization.PILLOW_1 = this.defaultFabrics[1] || '108-40';
						this.customization.PILLOW_2 = this.defaultFabrics[2] || '108-70';
					}
				}
				if(this.product.isFeatured){
					// if(this.product.round == 2){
					// 	this.customization.FABRIC = "424_02";
					// 	this.customization.PILLOW = "041_00";
					// 	this.customization.PILLOW_1 = "041_00";
					// 	this.customization.PILLOW_2 = "041_00";
					//
					// }

					this.initMarxent();
					if(this.product.round === '3'){
						this.customization.FABRIC = "L771_00";
						this.setTile("FABRIC", "L771_00");
					}

				}

				this.createCovers(this.fabrics);

				this.loaded = true;
			});
		}
	}
</script>

