<template functional>
	<div class="icon"><div :class="['inner-icon', props.iconType]"></div></div>
</template>

<script>
import _ from 'lodash';
import { mapGetters, mapActions } from 'vuex';

export default {
	name: 'Icon',
	data(){
		return{
		}
	},
	props: [
		'iconType',
	],
	computed: {
	},
	methods: {
	}
};
</script>
