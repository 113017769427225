<template>
	<div class="dimension-component" v-if="height > 0">
		<span v-if="title"><strong>{{title}}</strong><br/></span>
		H: {{height}}&rdquo;&nbsp;&nbsp;W: {{width}}&rdquo;&nbsp;&nbsp;D: {{depth}}&rdquo;
	</div>
</template>

<script>
import _ from 'lodash';
import { mapGetters, mapActions } from 'vuex';

export default {
	name: 'Dimension',
	data(){
		return{
		}
	},
	props: [
		'title',
		'width',
		'depth',
		'height'
	],
	computed: {
	},
	methods: {
	}
};
</script>

