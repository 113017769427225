<template>
	<vue100vh :class="{'your-vision': true, 'max-full-page': true}">
		<vue-headful :title="pageTitle"/>
		<back-button></back-button>
		<div class="tab-content">
			<div id="tab1">
				<article class="article row">
					<h2>Make Our Favorites Yours</h2>
					<div class="products-col col-sm-12">
						<div :class="'products-list'">
							<div class="content section col-sm-12 col-md-4" v-for="section in sections" v-if="hasProducts(section.type)" @click="$navigate('/your-vision/grid/'+section.type);$ua.trackEvent('Your Vision', 'Click', section.sku);">
								<div class="image " v-if="loaded" :class="section.type">
									<customizable-product-image skip-click="true" :sku="section.sku" view="vision-grid" :type="section.type" :customization="customization"></customizable-product-image>
								</div>
								<div class="product-name"><span>View</span> {{section.label}}</div>
							</div>
						</div>
					</div>
					<div class="arrows" v-if="isKiosk && (type == 'all' || type == 'chairs')">
						<a class="btn-up smooth" @click="pageUp"><i class="icon-up-open-big"></i></a>
						<a class="btn-down smooth" @click="pageDown"><i class="icon-down-open-big"></i></a>
					</div>

				</article>
			</div>
		</div>
		<bottom-nav></bottom-nav>
	</vue100vh>
</template>

<script>
	import BottomNav from '../components/BottomNav';
	import SavedButton from '../components/SavedButton';
	import BackButton from '../components/BackButton';
	import CustomizableTile from '../components/CustomizableTile';
	import CustomizableProductImage from '../components/CustomizableProductImage';
	import VueScrollTo from 'vue-scrollto';
	import Icon from '../components/Icon';
	import vue100vh from 'vue-100vh';
	import _ from 'lodash';

	import vueHeadful from 'vue-headful';
	import { mapGetters, mapActions } from 'vuex';

	export default {
		name: 'landing-page',
		data(){
			return{
				loaded: false,
				'pageTitle': 'Your Vision',
				'customization': {
					"BODY": '959-80',
					"FABRIC": '959-80',
					"UPHOLSTERY": '959-80',
					// "LEGS", this.customization.LEGS,
					// "LEG FINISH", this.customization.LEGS,
					// "PILLOW", this.customization.PILLOW_1,
					// "PILLOWS", this.customization.PILLOW_1,
					"PILLOW GROUP 1": '011-22',
					"PILLOW GROUP 2": '011-22',
					'PILLOW': '011-22',
					'PILLOW_1': '011-22',
					'PILLOW_2': '011-22'
				},
				sections: [
					{"label": 'Sofas', "sku":"5010-31", "type": "sofa"},
					{"label": 'Loveseats', "sku":"5118-20", "type": "loveseat"},
					{"label": 'Chairs', "sku":"5966-10", "type": "chair"},
					{"label": 'Recliners', "sku":"2804-50", "type": "recliner"},
					{"label": 'Sectionals', "sku":"7564-SECT", "type": "sectional"},
					{"label": 'Ottomans', "sku":"5010-08", "type": "ottoman_hidden"}
				]
			}
		},
		directives: {
			scrollTo: VueScrollTo
		},
		props: [
			'type'
		],
		components: {
			bottomNav: BottomNav,
			backButton: BackButton,
			icon: Icon,
			customizableTile: CustomizableTile,
			customizableProductImage: CustomizableProductImage,
			vueHeadful: vueHeadful,
			savedButton: SavedButton,
			vue100vh
		},
		computed: {
			isKiosk(){
				if(this.appId == "com.flexsteel.kiosk"){
					return true;
				}
				return false;
			},
			...mapGetters({
				defaultFabrics: 'customizableDefaultFabrics',
				fabrics: 'customizableFabrics',
				pillows: 'customizablePillows'
			})
		},
		methods: {
			hasProducts(type){
				let items = [];
				if(type == "chair"){
					items = this.$store.getters.getFeaturedByType("Chair");
				}
				if(type == "ottoman" || type == "ottoman_hidden"){
					items = this.$store.getters.getFeaturedByType("Ottoman");
				}
				if(type == "recliner"){
					items = this.$store.getters.getFeaturedByType("Recliner");
				}
				if(type == "sectional"){
					items = this.$store.getters.getFeaturedByType("Sectional");
				}
				if(type == "sofa"){
					items = this.$store.getters.getFeaturedByType("Sofa");
				}
				if(type == "loveseat"){
					items = this.$store.getters.getFeaturedByType("Loveseat");
				}
				if(items.length >= 1){
					return true;
				}else{
					return false;
				}
			},
			pageDown(){
				window.scroll({
					top: window.scrollY+window.innerHeight*.9,
					left: 0,
					behavior: 'smooth'
				});
			},
			pageUp(){
				window.scroll({
					top: window.scrollY-window.innerHeight*.9,
					left: 0,
					behavior: 'smooth'
				});
			},
		},
		mounted(){
			//set the default customization
			this.customization.BODY = this.defaultFabrics[0] || '011-22';
			this.customization.FABRIC = this.defaultFabrics[0] || '011-22';
			this.customization.PILLOW_1 = this.defaultFabrics[1] || '108-40';
			this.customization.PILLOW_2 = this.defaultFabrics[2] || '108-70';
			this.loaded = true;
		}
	}
</script>

