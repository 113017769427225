<template functional>
	<label class="custom-tile" :class="$options.theClasses(props.checked, props.width)">
		 <!-- @click="setTile" :class="theClasses" -->
		<input type="radio" :name="'radio-'+props.type" :checked="props.checked">
		<span class="img">
			<!-- :data-url="thumbnail" -->
			<img :src="$options.thumbnail(props.tile.img)" :height="props.height" :width="props.width" :alt="props.tile.id" src2="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVQYV2NgYAAAAAMAAWgmWQ0AAAAASUVORK5CYII=">
			 <!-- v-on:load="scaleTile" -->
		</span>
		<div class="inner-tile">
			<div class="inner-tile-inner">
				<div class="icon"><div class="inner-icon icon-check-alt"></div></div>
				<div class="tile-id">{{props.tile.id}}</div>
			</div>
		</div>
	</label>
</template>

<script>
import _ from 'lodash';
import { mapGetters, mapActions } from 'vuex';

export default {
	name: 'CustomizableTile',
	data(){
		return{
			loaded: false
		}
	},
	props: [
		'checked',
		'tile',
		'width',
		'chosen',
		'height',
		'type'
	],
	theClasses(checked, width){
		let isChecked = "";
		if(checked){
			isChecked = " isChecked ";
		}
		return "custom-tile "+isChecked+" width-"+width;
	},
	thumbnail(url){
		return url.replace("w_1000,", "w_100,").replace("h_1000,", "h_100,");
	},
	computed: {
		theClasses(){
			let isChecked = "";
			if(this.checked){
				isChecked = " isChecked ";
			}
			return "custom-tile "+isChecked+" width-"+this.width;
		},

		// isChecked(){
		// 	if(this.tile.id == this.chosen){
		// 		return true;
		// 	}else{
		// 		return false;
		// 	}
		// }
	},
	methods: {
	}
};
</script>
