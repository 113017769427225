<template>
	<vue100vh class="getInspired max-full-page">
		<vue-headful title="Get Inspired"/>
		<div class="large-device">
			<div class="tab-content" :style="swiperStyles">
				<div class="content">
					<swiper :options="swiperOption" ref="mySwiper">
						<div class="btn-left" slot="button-prev"><i class="icon-down-open-big"></i></div>
						<div class="btn-right" slot="button-next"><i class="icon-down-open-big"></i></div>
						<!-- slides -->
						<swiper-slide v-for="item in inspiration">
							<img @click="sectionDetails(item.id);$ua.trackEvent('Get-Inspired-Main', 'Click', item.name)" :src="'static/img/inspiration/'+item.img" :alt="item.name">
							<a @click.stop="sectionDetails(item.id);$ua.trackEvent('Get-Inspired-Main', 'Click', item.name)"  class="btn-default"><span>View {{item.name}}</span></a>
						</swiper-slide>

						<!-- Optional controls -->
						<!-- <div class="swiper-pagination"  slot="pagination"></div>
						<div class="swiper-scrollbar"   slot="scrollbar"></div> -->
					</swiper>
				</div>
			</div>
		</div>
		<div class="small-device">
			<div class="section" v-for="item in inspiration">
				<div class="content">
					<div class="inner">
						<img @click="sectionDetails(item.id);$ua.trackEvent('Get-Inspired-Main', 'Click', item.name)" :src="'static/img/inspiration/'+item.img" :alt="item.name">
						<div class="button-holder">
							<a @click="sectionDetails(item.id);$ua.trackEvent('Get-Inspired-Main', 'Click', item.name)"  class="btn-default"><span>View {{item.name}}</span></a>
						</div>
					</div>
				</div>
			</div>
		
		</div>
		<bottom-nav></bottom-nav>
	</vue100vh>
</template>

<script>
	import BottomNav from '../components/BottomNav';
	import { mapGetters, mapActions } from 'vuex';
	import vueHeadful from 'vue-headful';
	import vue100vh from 'vue-100vh';

	import 'swiper/dist/css/swiper.css';
	import { swiper, swiperSlide } from 'vue-awesome-swiper';

	export default {
		name: 'GetInspiration',
		components: {
			bottomNav: BottomNav,
			vueHeadful: vueHeadful,
			swiper,
			swiperSlide,
			vue100vh
		},
		data() {
			return {
				swiperOption: {
					slidesPerView: 1,
					loopedSlides: 3,
					width: 1200,
					height: 820,
					autoHeight: true,
					// loop: true,
					centeredSlides: true,
					simulateTouch: true,
					spaceBetween: 44,
					slideToClickedSlide: false,
					effect: 'coverflow',
					breakpoints: {
						900: {
							width: 800,
							height: 600
						},
						1000: {
							width: 900,
							height: 650
						},
						1200: {
							width: 1000,
							height: 800
						}
					},
					coverflowEffect: {
						slideShadows: false,
						rotate: 0,
						stretch: -44,
						// depth: 44
					},
					navigation: {
						nextEl: '.btn-right',
						prevEl: '.btn-left'
					},
				}
			}
		},
		computed: {
			slideWidth(){
				return this.windowWidth - 80 - 80;	
			},
			swiper() {
				return this.$refs.mySwiper.swiper
			},
			swiperStyles(){
				var verticalScale = this.windowHeight / 980;
				var horizontalScale = this.windowWidth / 1470;
				if(horizontalScale >= 1 && verticalScale >= 1){
					return {};
				}else{
					if(horizontalScale < verticalScale){
						return {  transform: 'scale('+horizontalScale+')',transformOrigin: 'center top' }		
					}
					if(verticalScale <= horizontalScale){
						return {  transform: 'scale('+verticalScale+')',transformOrigin: 'center top' }
					}
				}
				console.log(horizontalScale, verticalScale);
				return {  transform: 'scale('+verticalScale+')' }
				//ipad
				if(this.windowWidth <= 1100){
					return {  transform: 'scale('+verticalScale+')' }
					return {  transform: 'scale(0.7) translate(-9%, -22%)' }
				}
				//ipad pro 11
				if(this.windowWidth <= 1200){
					return {  transform: 'scale('+verticalScale+') translateZ(0)' }
					return {  transform: 'scale(0.8) translate(0%, -14%)' }
				}
				//ipad pro 12.9
				if(this.windowWidth <= 1400){
					return {  transform: 'scale('+verticalScale+') translateZ(0)' }
					return {  transform: 'scale(0.9) translate(0%, -2%)' }
				}

				
				return {};
			},
			...mapGetters({
				inspiration: 'inspiration'
			})
		},
		methods: {
			sectionDetails(id){
				this.$navigate('/get-inspired/section/'+id);
			},
			scaleContent(){
				// this.$refs.mySwiper.
			}
		},
		mounted() {
			// console.log('this is current swiper instance object', this.swiper)
			this.swiper.slideTo(0, 1000, false);
			this.scaleContent();
		}
	}
</script>

