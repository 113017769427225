<template>
	<div class="wifi-modal-container">
		<modal name="wifi-modal" width="100%" height="100%" @opened="openedModal" :adaptive="true">
			<div class="modal-top wifi-modal">
				<a @click="$modal.hide('wifi-modal');$store.dispatch('getAppProducts');" class="close-icon"><icon icon-type="icon-close"></icon></a>
				<h2>Wifi Setup</h2>
				<p>Click the button below (when it becomes available) to access this devices Wifi settings screen. Once you've setup the network connection swipe from the bottom left edge of the screen to return to the Flexsteel kiosk.</p>
				<button @click="launchWifi()" :class="{'btn-default': true, 'is-disabled': disabled}">LAUNCH WIFI SETTINGS</button>
				<div class="swipe-helper">
					<p>Swipe to the right from here<br/>to return to the kiosk after Wifi setup</p>
					<div class="icon-holder">
						<i class="icon icon-saved-arrow"></i>
					</div>
				</div>
			</div>
		</modal>
	</div>
</template>

<script>
	import _ from 'lodash';
	import { mapGetters } from 'vuex';
	import Icon from '../components/Icon';

	export default {
		name: 'WifiSetup',
		components: {
			icon: Icon,
		},
		data(){
			return{
				'loading': false,
				'disabled': true
			}
		},
		computed: {
		},
		mounted(){

		},
		methods: {
			openedModal(){
				setTimeout(this.enableSettings, 3*1000);
			},
			enableSettings(){
				this.disabled = false;
			},
			launchWifi(){
				if (window.cordova && window.cordova.plugins.settings) {
					console.log('openNativeSettingsTest is active');
					window.cordova.plugins.settings.open(['wifi', true], function() {
							console.log('opened settings');
						},
						function () {
							console.log('failed to open settings');
						}
					);
				}else{
					alert("WIFI settings screen only works on kiosk device");
					console.log('openNativeSettingsTest is not active!');
				}
			},
		}
	}
</script>

