export const ADD_TO_CART = 'ADD_TO_CART';
export const REMOVE_FROM_CART = 'REMOVE_FROM_CART';
export const UPDATE_CART = 'UPDATE_CART';
export const CLEAR_CART = 'CLEAR_CART';
export const SET_LAST_ROUTE = 'SET_LAST_ROUTE';
export const POP_LAST_ROUTE = 'POP_LAST_ROUTE';
export const SET_APP_COPY = 'SET_APP_COPY';
export const SET_STORE = 'SET_STORE';
export const SET_ALL = 'SET_ALL';
export const SET_FEATURED = 'SET_FEATURED';
export const SET_LOADING = 'SET_LOADING';
export const SET_LOADING_PRODUCTS = 'SET_LOADING_PRODUCTS';
export const SET_SCREENSAVER = 'SET_SCREENSAVER';
export const SET_TOGGLE_MOBILE_NAV = 'SET_TOGGLE_MOBILE_NAV';
export const SET_USER = 'SET_USER';
export const SET_SEARCH = 'SET_SEARCH';
export const SET_COVERS = 'SET_COVERS';
export const SET_DEFAULT_COVERS = 'SET_DEFAULT_COVERS';
export const SET_PRODUCT_AVAILABILITY = 'SET_PRODUCT_AVAILABILITY';
export const SET_TOKEN = 'SET_TOKEN';
export const SET_USER_ID = 'SET_USER_ID';
export const SET_USER_LOGIN_STATUS = 'SET_USER_LOGIN_STATUS';
export const SET_USER_REFRESH_TIME = 'SET_USER_REFRESH_TIME';
export const SET_SERVICES_REFRESH_TIME = 'SET_SERVICES_REFRESH_TIME';
export const CLEAR_TOKEN = 'CLEAR_TOKEN';
export const SET_INSPIRATION = 'SET_INSPIRATION';
