<template>
	<div :class="{'highlight': true, 'active': active, 'opened': isOpened, 'created': isCreated}" :style="{ top: item.y+'px', left: item.x+'px' }">
		<v-popover placement="auto" boundariesElementX=".left-side .highlights" @show="opened" @hide="closed" ref="popover">
			<div class="inner tooltip-target"></div>
			<template slot="popover">
				<a class="close-icon" v-close-popover><icon icon-type="icon-close"></icon></a>
				<h3>{{item.product.t}} {{item.product.s_t}}</h3>
				<div class="dim" v-if="item.product.height > 0">{{item.product.height}}"H x {{item.product.width}}"W x {{item.product.depth}}"D</div>
				<saved-button shadow="true" :sku="item.product.sku"></saved-button>
				<a @click="productDetails(item.product.sku)" class="btn-secondary add-shadow"><span>VIEW PRODUCT DETAILS</span></a>
			</template>
		</v-popover>
	</div>
</template>

<script>
import _ from 'lodash';
import { mapGetters, mapActions } from 'vuex';
import SavedButton from '../components/SavedButton';
import Icon from '../components/Icon';
import { VTooltip, VPopover, VClosePopover } from 'v-tooltip';
VTooltip.options.autoHide = true;

export default {
	name: 'Highlight',
	components:{
		vPopover: VPopover,
		icon: Icon,
		savedButton: SavedButton
	},
	directives: {
		tooltip: VTooltip,
		closePopover: VClosePopover
	},
	data(){
		return{
			'isCreated': false,
			'isOpened': false
		}
	},
	props: [
		'item',
		'active'
	],
	computed: {

	},
	methods: {
		opened(){
			this.isOpened = true;
			this.$emit('opened', true);
		},
		closed(){
			this.isOpened = false;
			this.$emit('closed', true);
		},
		productDetails(sku){
			console.log(this.$refs);
			this.$refs.popover.hide();
			this.$goToPDP(sku);
			// this.$navigate('/thirty-in-thirty/sku/'+sku);
		},
	},
	mounted(){
		this.isCreated = true;
	}
};
</script>

