<template>
	<div v-if="backRouteTitle != ''" class="back" @click="goBack()"><div class="inner-back">&lt; Back</div></div>
	 <!-- to {{backRouteTitle()}} -->
</template>

<script>
import _ from 'lodash';
import { mapGetters, mapActions } from 'vuex';

export default {
	name: 'BackButton',
	data(){
		return{
		}
	},
	props: [
	],
	computed: {
		...mapGetters({
			lastRoute: 'getLastRoute',
			allRoutes: 'getAllRoutes'
		})
	},
	methods: {
		backRouteTitle(){
			let index = _.findLastIndex(this.allRoutes, ['path', this.$router.history.current.path]);
			if(!_.isNil(index) && !_.isNil(this.allRoutes[index-1])){
				return this.allRoutes[index-1].title;
			}
		},
		goBack(){
			this.$goBack();
			this.popRoute();
		},
		...mapActions([
			'popRoute'
		])
	}
};
</script>

