<template>
	<div class="max-full-page" :class="{'flxPage': true}" ref="flx" :style="{'height': windowHeight+'px', 'max-width': windowWidth+'px'}">
		<vue-headful title="FLX"/>
		<div class="flx-video full" v-if="showingVideo && !showingIntro"  :style="{'height': windowHeight+'px'}">
			<div class="video-overlay" @click="stopFLXVideo()"></div>
			<video @ended="stopFLXVideo()"
				preload="true"
				poster="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
				width="100%"
				src="~@/assets/flx/flx-video.mp4"
				height="100%"
				ref="videoPlayer">
				<!-- <source src="~@/assets/flx/flx-video.webm" type="video/webm">
				<source src="~@/assets/flx/flx-video.mp4" type="video/mp4"> -->
			</video>
		</div>
		<div class="flx-video intro" v-show="showingIntro && !showingVideo">
			<div class="video-overlay" @click="stopIntroVideo()"></div>
			<video @ended="stopIntroVideo()"
				muted
				autoplay="true"
				playsinline
				poster="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
				preload="false"
				width="100%"
				height="100%"
				ref="introVideoPlayer">
				<source src="~@/assets/flx/flx-intro.webm" type="video/webm">
				<source src="~@/assets/flx/flx-intro.mp4" type="video/mp4">
			</video>
		</div>
		<div class="inner-page" v-if="!showingVideo && !showingIntro"  :style="{'height': windowHeight+'px'}">
			<div class="page-top" @click="showMenu"  :style="{'min-height': windowHeight+'px'}">
				<div class="mobile-only-flx">
					<div class="flx-logo-mobile"><div class="content"></div></div>
					<div v-for="item in flx">
						<div class="inner">
							<h1>{{item.description1}} {{item.description2}}</h1>
							<div class="content-holder">
								<div class="content">
									<img v-if="item.type == 'image'" :src="assetUrl(item.id, 'img')" :alt="item.description1">
									<video v-if="item.type == 'video'"
										poster="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
										autoplay
										playsinline
										loop
										muted
										width="100%"
										height="300"
										:ref="'videoSlideMobile'+item.id">
										<source :src="assetUrl(item.id, 'video-webm')" type="video/webm">
										<source :src="assetUrl(item.id, 'video')" type="video/mp4">
									</video>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="non-mobile-only-flx">
					<div class="flx-logo"><div class="content"></div></div>
					<div v-if="!showingMenu" class="menu-bg off-bottom">Tap Screen For Menu</div>
					<div v-if="showingMenu" class="menu-item off-bottom video-play">
						<a @click.stop='playFLXVideo()'>
							<div class="icon-holder">
								<i class="icon icon-flx-play"></i>
								<span class="cta">Play Video</span>
							</div>
						</a>
					</div>
					<div v-if="showingMenu" class="menu-item off-bottom view-product-details">
						<a @click='showPDP()'>
							<div class="icon-holder">
								<i class="icon icon-flx-arrow-down"></i>
								<span class="cta">View Product Details</span>
							</div>
						</a>
					</div>
					<div v-if="showingMenu" class="menu-item off-bottom add-to-saved">
						<div class="icon-holder">
							<saved-button class="saved-button" view="4" sku="1356-11P"></saved-button>
						</div>
					</div>
					<swiper :options="swiperOption" ref="mySwiper" @ready="sliderReady" @slideChange="slideChangeStart">
						<div class="flx-btn-left off-bottom" slot="button-prev" @click.stop="stopPropagation()">
							<div class="icon-holder">
								<i class="icon icon-flx-arrow-back"></i>
							</div>
						</div>
						<div class="flx-btn-right off-bottom" slot="button-next" @click.stop="stopPropagation()">
							<div class="icon-holder">
								<i class="icon icon-flx-arrow-forward"></i>
							</div>
						</div>
						<swiper-slide v-for="item in flx" >
							<div class="inner" :style="{'height': windowHeight+'px'}">
								<h1>{{item.description1}}</h1>
								<h2>{{item.description2}}</h2>
								<img v-if="item.type == 'image'" :src="assetUrl(item.id, 'img')" :alt="item.description1">
								<video v-if="item.type == 'video'"
									poster="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
									width="62%"
									playsinline
									muted
									:height="windowHeight"
									muted
									:ref="'videoSlide'+item.id">
									<source :src="assetUrl(item.id, 'video-webm')" type="video/webm">
									<source :src="assetUrl(item.id, 'video')" type="video/mp4">
								</video>
							</div>
						</swiper-slide>
					</swiper>
				</div>
			</div>
			<div class="page-bottom" ref="pdpContainer" >
				<pdp sku="1356-11P" @back="showFLX()" :showback="showingPDP" included="true"></pdp>
			</div>
		</div>
		<bottom-nav></bottom-nav>
	</div>
</template>

<script>
	import BottomNav from '../components/BottomNav';
	import BackButton from '../components/BackButton';
	import SavedButton from '../components/SavedButton';
	import StoreChooser from '../screens/StoreChooser';
	import PDP from '../screens/PDP';
	import Icon from '../components/Icon';
	import 'swiper/dist/css/swiper.css';
	import { swiper, swiperSlide } from 'vue-awesome-swiper';

	import flxAsset1 from '../assets/flx/FLX_Kiosk-Carousel-01.jpg';
	import flxAsset1Video from '../assets/flx/FLX_Kiosk-Carousel-01.mp4';
	import flxAsset1VideoWebM from '../assets/flx/FLX_Kiosk-Carousel-01.webm';
	import flxAsset2 from '../assets/flx/FLX_Kiosk-Carousel-02.jpg';
	import flxAsset3 from '../assets/flx/FLX_Kiosk-Carousel-03.jpg';
	import flxAsset4 from '../assets/flx/FLX_Kiosk-Carousel-04.jpg';
	import flxAsset4Video from '../assets/flx/FLX_Kiosk-Carousel-04.mp4';
	import flxAsset4VideoWebM from '../assets/flx/FLX_Kiosk-Carousel-04.webm';
	import flxAsset5 from '../assets/flx/FLX_Kiosk-Carousel-05.jpg';
	import flxAsset6 from '../assets/flx/FLX_Kiosk-Carousel-06.jpg';
	import flxAsset7 from '../assets/flx/FLX_Kiosk-Carousel-07.jpg';
	import flxAsset7Video from '../assets/flx/FLX_Kiosk-Carousel-07.mp4';
	import flxAsset7VideoWebM from '../assets/flx/FLX_Kiosk-Carousel-07.webm';
	import flxAsset8 from '../assets/flx/FLX_Kiosk-Carousel-08.jpg';
	import flxAsset9 from '../assets/flx/FLX_Kiosk-Carousel-09.jpg';

	import _ from 'lodash';

	import vueHeadful from 'vue-headful';
	import { mapGetters, mapActions } from 'vuex';

	export default {
		name: 'flx-page',
		data(){
			return{
				showingMenu: true,
				showingVideo: false,
				showingIntro: true,
				showingPDP: false,
				activeSlide: 1,
				swiperOption: {
					slidesPerView: 1,
					loopedSlides: 3,
					// width: '100%',
					height: this.windowHeight,
					autoHeight: false,
					// loop: true,
					centeredSlides: true,
					simulateTouch: true,
					spaceBetween: 0,
					preventClicks: true,
					slideToClickedSlide: false,
					// on: {
					// 	slideChange(){
					// 		console.log(this.activeIndex);
					// 		// this.$refs['videoSlide'+this.activeIndex].play();
					// 	}
					// },
					navigation: {
						nextEl: '.flx-btn-right',
						prevEl: '.flx-btn-left'
					},
				}

			}
		},
		components: {
			bottomNav: BottomNav,
			icon: Icon,
			pdp: PDP,
			savedButton: SavedButton,
			backButton: BackButton,
			storeChooser: StoreChooser,
			vueHeadful: vueHeadful,
			swiper,
			swiperSlide
		},
		computed: {
			swiper() {
				return this.$refs.mySwiper.swiper
			},
			...mapGetters({
				flx: 'flx'
			})
		},
		methods: {
			//workaround for android
			assetUrl(id, type){
				if(id === "1"){
					if(type == 'video'){
						return flxAsset1Video;
					}else if(type == 'video-webm'){
						return flxAsset1VideoWebM;
					}else{
						return flxAsset1;
					}
				}
				if(id === "2"){return flxAsset2;}
				if(id === "3"){return flxAsset3;}
				if(id === "4"){
					if(type == 'video'){
						return flxAsset4Video;
					}else if(type == 'video-webm'){
						return flxAsset4VideoWebM;
					}else{
						return flxAsset4;
					}
				}
				if(id === "5"){return flxAsset5;}
				if(id === "6"){return flxAsset6;}
				if(id === "7"){
					if(type == 'video'){
						return flxAsset7Video;
					}else if(type == 'video-webm'){
						return flxAsset7VideoWebM;
					}else{
						return flxAsset7;
					}
				}
				if(id === "8"){return flxAsset8;}
				if(id === "9"){return flxAsset9;}
			},
			showPDP(){
				this.showingPDP = true;
				let container = this.$refs.flx;
				let target = this.$refs.pdpContainer;
				container.scroll({
					top: target.offsetTop,
					left: 0,
					behavior: 'smooth'
				});
			},
			showFLX(){
				this.showingPDP = false;
				let container = this.$refs.flx;
				container.scroll({
					top: 0,
					left: 0,
					behavior: 'smooth'
				});
			},
			slideChangeStart(play){
				if(this.swiper.activeIndex == 0){
					this.showingMenu = true;
				}else{
					this.showingMenu = false;
				}
				this.$nextTick(function(){
					let video = this.$refs["videoSlide"+(this.swiper.activeIndex+1)];
					this.activeSlide = this.swiper.activeIndex+1;
					if(!_.isNil(video)){
						video[0].currentTime = 0;
						video[0].play();
					}
				});
			},
			stopPropagation(){
			},
			showMenu(){
				this.showingMenu = true;
			},
			playFLXVideo(){
				this.showingVideo = true;
				this.$nextTick(function(){
					let video = this.$refs["videoPlayer"];
					console.log(video);
					if(!_.isNil(video)){
						video.currentTime = 0;
						video.play();
					}
				});

			},
			stopFLXVideo(){
				this.showingVideo = false;
			},
			stopIntroVideo(){
				this.showingIntro = false;
				this.$nextTick(function(){
					let video = this.$refs["videoSlide1"];
					if(!_.isNil(video)){
						video[0].currentTime = 0;
						video[0].play();
					}
				});
			},
			sliderReady(){
				let video = this.$refs["videoSlide1"];
				if(!_.isNil(video)){
					video[0].currentTime = 0;
					video[0].play();
				}
			}
		},
		mounted() {
			//hidden with v-if
			// this.swiper.slideTo(0, 1000, false);
		}
	}
</script>
