<template>
	<span>
		<a v-if="styleType == 1" @click='toggleCart()' class="btn-default has-icon" :class="{'add-shadow': shadow}"><span><icon :icon-type="{'icon-add-white': !cartStatus, 'icon-remove-white': cartStatus}"></icon><b v-html="slotText"></b></span></a>
		<a v-if="styleType == 2" @click='toggleCart()' class="btn-inline has-icon" :class="{ isInCart: cartStatus}"><span><icon :icon-type="{'icon-add-small': !cartStatus, 'icon-remove-small': cartStatus}"></icon>{{slotShortText}}</span></a>
		<a v-if="styleType == 3" @click='toggleCart()' class="btn-inline style-3 has-icon" :class="{ isInCart: cartStatus}"><span><icon :icon-type="{'icon-add-blue-fill': !cartStatus, 'icon-remove-blue': cartStatus}"></icon>{{slotText}}</span></a>
		<a v-if="styleType == 4" @click='toggleCart()' class="style-4" :class="{ isInCart: cartStatus}">
			<div class="icon-holder">
				<i class="icon " :class="{'icon-flx-add': !cartStatus, 'icon-flx-remove': cartStatus}"></i>
				<span class="cta">{{slotStyle4Text}}</span>
			</div>
		</a>
	</span>
</template>

<script>
import _ from 'lodash';
import { mapGetters, mapActions } from 'vuex';
import Icon from '../components/Icon';

export default {
	name: 'SavedButton',
	components:{
		icon: Icon
	},
	data(){
		return{
			slotTextDefault: '<b class="desktop">ADD TO </b>SAVE<b class="desktop">D ITEMS</b>',
			slotTextRemove: 'REMOVE<b class="desktop"> FROM SAVED</b>',
			slotShortTextDefault: 'Add',
			slotShortTextRemove: 'Remove',
			slotStyle4TextDefault: 'Add to Saved',
			slotStyle4TextRemove: 'Remove'
		}
	},
	props: [
		'sku',
		'shadow',
		'customization',
		'view'
	],
	computed: {
		styleType(){
			if(!_.isNil(this.view)){
				return this.view;
			}else{
				return "1";
			}
		},
		slotStyle4Text(){
			if(!this.isInCart(this.sku)){
				return this.slotStyle4TextDefault;
			}else{
				return this.slotStyle4TextRemove;
			}
		},

		slotShortText(){
			if(!this.isInCart(this.sku)){
				return this.slotShortTextDefault;
			}else{
				return this.slotShortTextRemove;
			}
		},
		slotText(){
			if(!this.isInCart(this.sku)){
				return this.slotTextDefault;
			}else{
				return this.slotTextRemove;
			}
		},
		itemsInCart(){
			let cart = this.$store.getters.cartProducts;
			return cart.reduce((accum, item) => accum + item.quantity, 0);
		},
		cartStatus(){
			return this.isInCart();
		}
	},
	methods: {
		toggleCart(){
			if(!this.isInCart()){
				this.addToCartById({sku: this.sku, customization: this.customization});
			}else{
				this.removeFromCartById(this.sku);
			}
		},
		isInCart(){
			let lookup = this.$store.getters.getInCartById(this.sku);
			if(!_.isNil(lookup) && lookup.quantity >= 1){
				return true;
			}else{
				return false;
			}
		},
		...mapActions([
			'addToCartById',
			'removeFromCartById'
		])
	}
};
</script>
