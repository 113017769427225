<template>
	<div :class="{'store-modal': true}">
		<modal name="storeToggle" class="store-toggle" width="100%" height="100%" :adaptive="true">
			<a @click="$modal.hide('storeToggle')" class="close-icon"><icon icon-type="icon-close"></icon></a>
			<div class="stores">
				<h4>Tap to change location:</h4>
				<a @click="chooseStore(store)" :class="{'active': store.id == $store.state.storeID}" v-for="(store, key) in stores" v-if="store">{{store.name}}</a>
			</div>
		</modal>
		<div class="store-modal-trigger">
			<a @click='getStores()' class="btn-inline"><span>Change Store</span></a>
		</div>
	</div>
</template>

<script>
import Vue from 'vue';
import _ from 'lodash';
import Icon from '../components/Icon';
import { mapGetters, mapActions } from 'vuex';

const { Plugins } = require('@capacitor/core');
const { Storage } = Plugins;

export default {
	name: 'StoreModal',
	data(){
		return{
			'stores': []
		}
	},
	props: {
	},
	computed: {
		storeName(){
			return this.$store.state.stores[this.$store.state.storeID];
		}
	},
	methods: {
		getStores(){
			// this.$modal.show('storeToggle');
			// return true;
			let formData = new FormData()
			formData.append('action', "getStores");

			Vue.prototype.$http.post(Vue.prototype.api_endpoint, formData, {
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			}).then(response => {
				if(response.data.status == true){
					this.stores = response.data.data;
					this.$modal.show('storeToggle');
				}
			}, err_response => {
				console.log("Error getting stores list:", err_response);
			});

		},
		async chooseStore(store){
			this.setStore(store.id);
			this.$store.dispatch('getAppProducts');
			this.$modal.hide('storeToggle');
			//update the local json value
			await Storage.set({
				key: 'appStoreID',
				value: this.$store.state.storeID
			});
			window.location.reload();
			this.$ua.injectGlobalDimension(1, store.id);
		},
		...mapActions([
			'clearToken',
			'setStore',
			'setLastRoute'
		])

	},
	mounted(){
		console.log("here");
	},
	components: {
		icon: Icon
	}
};

</script>

