<template>
	<div class="allProductsGrid">
		<vue-headful :title="area"/>
		<div class="sidebar-backdrop" @click="closeSidebarPanel" v-if="isPanelOpen"></div>
		<div class="tab-content">
			<div class="row row-eq-height">
				<transition name="slide">
					<div v-if="isPanelOpen || windowWidth >= 768" class="col-sm-3 col-md-3 col-lg-2 filters " :class="{'is-open': isPanelOpen}" :style="{'height': windowHeight+'px'}">
						<back-button class="non-mobile-only"></back-button>
						<div class='mobile-only icon-close' @click="closeSidebarPanel"></div>
						<a v-if="!searchKeyword" class="search-box" @click="closeSidebarPanel();$modal.show('search-modal');"><icon icon-type="icon-search-big"></icon> <span></span></a>
						<a v-if="searchKeyword" class="search-box" @click="closeSidebarPanel();$modal.show('search-modal');"><icon icon-type="icon-search-big"></icon> <span>{{searchKeyword}}</span></a>

						<v-collapse-group :onlyOneActive="true">
							<v-collapse-wrapper v-for="(facet, index) in filteredItems.data.aggregations" :class="toggleOpenFilter(index)" :ref="'toggle-'+index">
								<div class="header" v-collapse-toggle>
									{{ facet.title }} <icon icon-type="icon-add-navy-no-circle"></icon>
									<div v-if="itemJsFilters[facet.name].length >= 1" class="indicator"></div>
								</div>
								<div class="options" v-collapse-content>
									<div class="inner">
										<div class="option featured" v-for="bucket in facet.buckets" v-if="bucket.key == 'Wynwood' || bucket.key == 'Latitudes' || bucket.key == 'South Haven' || bucket.key == 'Casegoods'">
											<div @click="setFilter(facet.name, bucket.key)" :class="{'active': itemJsFilters[facet.name].includes(bucket.key)}"><span></span><div class="title">{{bucket.key}}</div><div class="clear"></div></div>
										</div>
										<div class="option" v-for="bucket in facet.buckets" v-if="bucket.key != 'Wynwood' && bucket.key != 'Latitudes' && bucket.key != 'South Haven' && bucket.key != 'Casegoods'">
											<div @click="setFilter(facet.name, bucket.key)" :class="{'active': itemJsFilters[facet.name].includes(bucket.key)}"><span></span><div class="title">{{bucket.key}}</div><div class="clear"></div></div>
										</div>
									</div>
								</div>
							</v-collapse-wrapper>
						</v-collapse-group>
						<a v-if="hasFilters()" class="btn-secondary add-shadow clear-filters" @click="clearFilters"><span>CLEAR FILTERS</span></a>
					</div>
				</transition>
				<div class="grid col-xs-12 col-sm-offset-3 col-md-offset-3 col-lg-offset-2 col-sm-9 col-md-9 col-lg-10 min-full-page max-full-page-no-width dragscroll" ref="grid" id="product-grid" :style="{'height': windowHeight+'px'}">
					<div class="inner">
						<div class="top">
							<back-button class="mobile-only"></back-button>
							<zero-gravity v-if="searchKeyword == 'Zero Gravity'"></zero-gravity>
							<div v-else>
								<h1>
									{{searchTxt()}}
								</h1>
							</div>
							<!-- <p class="text-muted">Search performed in {{ filteredItems.timings.search }} ms, facets in {{ filteredItems.timings.facets }} ms</p> -->
							<div class="showing">
								<a class="filter-icon mobile-only" @click.prevent="toggleSidebarPanel()"><icon icon-type="icon-filter"></icon> <span></span></a>
								{{showingText()}}
								<span v-for="(i, key) in itemJsFilters">
									<span v-for="type in i">
										<div class='tag has-icon' @click="setFilter(key, type)">{{type}}<div class='inner-icon icon-close'></div></div>
									</span>
								</span>
							</div>
						</div>
						<div class="row-eq-height">
							<product-grid-item class="col-xs-6 col-sm-4 col-lg-3 all-products" v-for="p in pagedProducts" :key="p.sku" :product="p" ></product-grid-item>
							<mugen-scroll scroll-container="grid" :handler="nextPage" :should-handle="!loading"></mugen-scroll>
						</div>
					</div>
				</div>
				<div class="arrows" v-if="isKiosk">
					<a class="btn-up smooth" @click="pageUp"><i class="icon-up-open-big"></i></a>
					<a class="btn-down smooth" @click="pageDown"><i class="icon-down-open-big"></i></a>
				</div>
			</div>
			<search-modal :searchKeyword="searchKeyword" :area="area" :filteredProducts="filteredProducts()"></search-modal>
			<bottom-nav ref="bottomNav"></bottom-nav>
		</div>
	</div>
</template>

<script>
	import _ from 'lodash';
	import BottomNav from '../components/BottomNav';
	import ProductGridItem from '../components/ProductGridItem';
	import { mapGetters } from 'vuex';
	import vueHeadful from 'vue-headful';
	import Icon from '../components/Icon';
	import BackButton from '../components/BackButton';
	import SearchModal from '../components/SearchModal';
	import MugenScroll from 'vue-mugen-scroll';
	import Itemsjs from 'itemsjs';
	import FabricDetailCard from '../components/FabricDetailCard.vue';
	import ZeroGravity from '../components/ZeroGravity';

	var configuration = {
		searchableFields: ['title', 'tags', 'actors'],
		sortings: {
			name_asc: {
				field: 'name',
				order: 'asc'
			}
		},
		aggregations: {
			collections: {
				title: 'Group',
				sort: 'term',
				conjunction: false,//OR
				size: 1000
			},
			types: {
				title: 'Frame',
				sort: 'term',
				conjunction: false,//OR
				size: 100
			},
			materials: {
				sort: 'term',
				title: 'Material',
				conjunction: true,//AND
				size: 100
			},
			options: {
				sort: 'term',
				title: 'Options',
				conjunction: true,//AND
				size: 100
			}
		}
	}

	export default {
		name: 'ProductGrid',
		components: {
			bottomNav: BottomNav,
			icon: Icon,
			mugenScroll: MugenScroll,
			backButton: BackButton,
			searchModal: SearchModal,
			vueHeadful: vueHeadful,
			productGridItem: ProductGridItem,
			zeroGravity: ZeroGravity,
			FabricDetailCard
		},
		data(){
			var itemJsFilters = {};
			Object.keys(configuration.aggregations).map(function(v){
				itemJsFilters[v] = [];
			});

			return{
				'itemJsFilters': itemJsFilters,
				'loading': false,
				'isUpdated': true,
				'isPanelOpen': false,
				'pageNum': 1,
				'numPerPage': 12,
				'firstClass': 'closed',
				'secondClass': 'closed',
				'thirdClass': 'closed',
				'fourthClass': 'closed',
				'filterTypes': [
					'type',
					'material',
					'option',
					'group',
				],
				'filters': {
					'area': [],
					'type': [],
					'material': [],
					'option': [],
					'group': [],
				}
			}
		},
		props: [
			'area',
			'passedSearchKeyword'
		],
		computed: {
			realWindowHeight(){
				// console.log(this.$refs);
				if(!_.isNil(document.getElementById('bottom-nav'))){
					var bottomNavHeight = document.getElementById('bottom-nav').clientHeight;
					return this.windowHeight - bottomNavHeight;
				}
			},
			isKiosk(){
				if(this.appId == "com.flexsteel.kiosk"){
					return true;
				}
				return false;
			},
			filteredItems(){
				var result = this.items.search({
					page: 1,
					per_page: 100000,
					// query: this.searchKeyword,
					filters: this.itemJsFilters
				});
				return result;
			},
			items(){
				return Itemsjs(this.products, configuration);
			},
			pagedProducts(){
				// let prods = this.filteredProducts();
				let prods = _.cloneDeep(this.filteredItems.data.items, true);
				return prods.splice(0, this.numPerPage*this.pageNum);
			},
			products(){
				let local = this;
				if(this.searchKeyword != ""){
					let keyword = this.searchKeyword.toLowerCase();
					if(keyword == "zero gravity"){
						let products = this.allProducts.filter(function(product){
							if(product.t.toLowerCase().indexOf("jarvis") > -1 || product.t.toLowerCase().indexOf("garrett") > -1 || product.t.toLowerCase().indexOf("brian") > -1 || product.t.toLowerCase().indexOf("willis") > -1 || product.t.toLowerCase().indexOf("henry") > -1 || product.t.toLowerCase().indexOf("yuma") > -1){
								return true;
							}
							return false;
						});
						return products;
					}else{
						let products = this.allProducts.filter(function(product){
							if(product.sku.toLowerCase().indexOf(keyword) > -1 || product.t.toLowerCase().indexOf(keyword) > -1 || product.s_t.toLowerCase().indexOf(keyword) > -1){
								return true;
							}
							return false;
						});
						return products;
					}
				}else{
					if(!_.isNil(local.area)){
						local.filters["area"].push(local.area);
					}
					let products = this.allProducts.filter(function(product){
						// return true;
						if(_.isNil(local.area)){
							return true;
						}
						return local.productHasAttribute(product, 'area', "areas", local.filters, "OR");
					});
					return products;
				}
			},
			...mapGetters({
				allProducts: 'allProducts',
				searchKeyword: 'searchKeyword',
				lastRoute: 'getLastRoute'
			})
		},
		// activated(){
		// 	if(!_.isNil(this.passedSearchKeyword) && this.passedSearchKeyword != ''){
		// 		console.log("searching...2");
		// 		console.log(this.passedSearchKeyword);
		// 		this.searchKeyword = this.passedSearchKeyword;
		// 	}
		// },
		mounted(){
			// if(!_.isNil(this.passedSearchKeyword) && this.passedSearchKeyword != ''){
			// 	console.log("searching...");
			// 	console.log(this.passedSearchKeyword);
			// 	this.searchKeyword = this.passedSearchKeyword;
			// }
		},
		methods: {
			toggleSidebarPanel(){
				this.isPanelOpen = !this.isPanelOpen;
			},
			closeSidebarPanel(){
            	this.isPanelOpen = false;
	        },

			toggleOpenFilter(index){
				if(!_.isNil(this.$refs['toggle-'+index]) && !_.isNil(this.$refs['toggle-'+index].status)){
					return 'open';
				}
			},
			nextPage(){
				this.pageNum++;
			},
			// updateSearch(newSearch){
			// 	this.searchKeyword = newSearch;
			// },
			searchTxt(){
				if(this.searchKeyword == ""){
					return this.area;
				}else{
					return "Search Results for: "+this.searchKeyword;
				}
			},
			pageDown(){
				let container = this.$refs.grid;
				container.scroll({
					top: container.scrollTop+window.innerHeight*.9,
					left: 0,
					behavior: 'smooth'
				});
			},
			pageUp(){
				let container = this.$refs.grid;
				container.scroll({
					top: container.scrollTop-window.innerHeight*.9,
					left: 0,
					behavior: 'smooth'
				});
			},
			openedSearch(){
				this.$refs.searchKeyword.focus();
			},
			search(){
				//should be searching on keydown
				this.$modal.hide('search-modal');
				this.pageNum = 1;
			},
			hasFilters(){
				let found = false;
				_.forEach(this.itemJsFilters, function(filter, key){
					if(filter.length > 0){
						found = true;
					}
				});
				return found;
			},
			filteredProducts(filterMatch, filterType, filter){
				let local = this;
				let products = false;
				let _filters = false;
				if(!_.isNil(filterMatch) && filterMatch == true){
					//tmp
					// return 1;
					//we are looking to get count of matched products if a filter were to be applied (indicate to the user that zero matches so dont click)
					products = _.cloneDeep(local.products, true);
					_filters = _.cloneDeep(local.filters, true);
					_filters[filterType].push(filter);
				}else{
					products = local.products;
					_filters = local.filters;
				}
				products = products.filter(function(product){
					if(_filters.type.length == 0){
						return true;
					}
					return local.productHasAttribute(product, 'type', 'types', _filters, "OR");
				});
				products = products.filter(function(product){
					if(_filters.material.length == 0){
						return true;
					}
					return local.productHasAttribute(product, 'material', 'materials', _filters, "AND");
				});
				products = products.filter(function(product){
					if(_filters.option.length == 0){
						return true;
					}
					return local.productHasAttribute(product, 'option', 'options', _filters, "AND");
				});
				products = products.filter(function(product){
					if(_filters.group.length == 0){
						return true;
					}
					return local.productHasAttribute(product, 'group', 'collections', _filters, "OR");
				});
				if(!_.isNil(filterMatch) && filterMatch == true){
					return products.length;
				}
				return products;
			},
			mapFilterTypes(type){
				if(type == 'type'){return 'types';}
				if(type == 'material'){return 'materials';}
				if(type == 'option'){return 'options';}
				if(type == 'group'){return 'collections';}
			},
			getFilters(filterType){
				let filter = [];
				_.forEach(this.products, function(product, key){
					_.forEach(product[filterType], function(type, f_key){
						if(!_.includes(filter, type) && !_.isNil(type)){
							filter.push(type);
						}
					});
				});
				filter = _.sortBy(filter);
				return filter;
			},
			productHasAttribute(product, filterType, productType, filters, matchType){
				//OR lookup
				if(matchType == "OR"){
					let match = false;
					if(!_.isNil(filters[filterType])){
						_.forEach(filters[filterType], function(type, f_key){
							if(_.includes(product[productType], type)){
								match = true;
							}
						});
					}
					return match;
				}
				//AND lookup
				if(matchType == "AND"){
					let matchCount = 0;
					if(!_.isNil(filters[filterType])){
						_.forEach(filters[filterType], function(type, f_key){
							if(_.includes(product[productType], type)){
								matchCount++;
							}
						});
						if(matchCount >= filters[filterType].length){
							return true;
						}
					}
					return false;
				}
			},
			setFilter(type, val){
				this.pageNum = 1;
				if(!_.includes(this.itemJsFilters[type], val)){
					this.itemJsFilters[type].push(val);
					// this.$set(this.itemJsFilters[type], type, val);
					// console.log(this.itemJsFilters);
				}else{
					_.pull(this.itemJsFilters[type], val);
				}
				//hack to force reactivity
				this.itemJsFilters[type].splice(10000);
				let container = this.$refs.grid;
				container.scroll({
					top: 0,
					left: 0,
					behavior: 'smooth'
				});
				// this.items.reindex(this.allProducts);
				this.$forceUpdate();
				this.$ua.trackEvent('Browse-All-Grid', 'Filter', type, val);
				return true;
			},
			showingText(){
				let count = 0;
				let base = "Showing "+this.filteredItems.pagination.total+" items";
				let local = this;
				if(this.hasFilters()){
					base += " for: ";
				}
				return base;
			},
			clearFilters(){
				var filters = {};
				Object.keys(configuration.aggregations).map(function(v){
					filters[v] = [];
				})

				this.itemJsFilters = filters;
			}
		}
	}
</script>

