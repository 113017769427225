<template>
	<div class="row fabric-detail-card">
		<div class="column col-xs-12 col-md-4">
			<img class="fabric-tile" :src="fabric.img"/>
		</div>
		<div class="column col-xs-12 col-md-8 fabric-details">
			<h5>{{title}}</h5>
			<h6>{{fabric.ColorDescr}} - {{fabric.id}}</h6>
			<p>{{fabric.PatternDescr}}, {{fabric.WearCd}}</p>
		</div>
	</div>
</template>

<script>
import _ from 'lodash';
import { mapGetters, mapActions } from 'vuex';

export default {
	name: 'FabricDetailCard',
	data(){
		return{
		}
	},
	props: [
		'sku',
		'title'
	],
	computed: {
		fabric(){
			return this.fabrics.find(fabric => fabric.id === this.sku);
		},
		...mapGetters({
			fabrics: 'customizableFabrics'
		})
	},
	methods: {
		...mapActions([
			'popRoute'
		])
	}
};
</script>

