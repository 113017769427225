<template>
	<div class="search-modal-container">
		<modal name="search-modal" :width="modalWidth" :height="modalHeight" @opened="openedSearch" :adaptive="true" :pivotY="0.1">
			<div class="modal-top search-modal">
				<a @click="$modal.hide('search-modal')" class="close-icon"><icon icon-type="icon-close"></icon></a>
				<h2>Search Products</h2>
				<p>Enter Group (e.g., Finley)<br/>or SKU (e.g., 5010-20) below</p>
				<div class="row">
					<form v-on:submit="search()">
						<div class='col-xs-12 col-md-9'>
							<input id="searchInput" autocomplete="off" v-model="keyword" ref="keyword" type="text" placeholder="Enter group or SKU #" v-on:change="updateSearch(keyword)"/>
						</div>
						<div class='col-xs-12 col-md-3'>
							<button @click="search()" :class="{'btn-default': true, 'btn-submit': true, 'is-disabled': (!keyword)}">SEARCH</button>
						</div>
					</form>
				</div>
				<!-- <label class="custom-check"><input v-model="limit" type="checkbox"><span class="checkmark"></span>Limit results to {{area}}</label> -->
				<div class="showing-preview" v-if="keyword.length >= 1">{{products.length}} items match your search</div>
			</div>
		</modal>
	</div>
</template>

<script>
	import _ from 'lodash';
	import { mapGetters, mapActions } from 'vuex';
	import Icon from '../components/Icon';

	export default {
		name: 'SearchModal',
		components: {
			icon: Icon,
		},
		data(){
			return{
				'loading': false,
				'limit': false,
				'keyword': ''
			}
		},
		props: [
			'filteredProducts',
			'area'
		],
		computed: {
			modalWidth(){
				if(this.isKiosk){
					return 1000;
				}else{
					if(this.windowWidth >= 950){
						return 900;
					}else{
						return this.windowWidth - 20;
					}
				}
			},
			modalHeight(){
				if(this.isKiosk){
					return 900;
				}else{
					if(this.windowHeight <= 450){
						return this.windowHeight - 20;
					}
					return 400;
				}
			},
			isKiosk(){
				if(this.appId == "com.flexsteel.kiosk"){
					return true;
				}
				return false;
			},
			products(){
				let local = this;
				if(this.keyword != ""){
					let keyword = this.keyword.toLowerCase();
					let _allProducts = this.allProducts;
					if(this.limit == true){
						_allProducts = this.filteredProducts;
					}
					let products = _allProducts.filter(function(product){
						if(product.sku.toLowerCase().indexOf(keyword) > -1 || product.t.toLowerCase().indexOf(keyword) > -1 || product.s_t.toLowerCase().indexOf(keyword) > -1){
							return true;
						}
						return false;
					});
					return products;
				}else{
					return this.allProducts;
				}
			},
			...mapGetters({
				allProducts: 'allProducts',
				searchKeyword: 'searchKeyword'
			})
		},
		mounted(){
		},
		methods: {
			searchTxt(){
				if(this.keyword == ""){
					return this.area;
				}else{
					return "Search Results for: "+this.keyword;
				}
			},
			openedSearch(){
				this.keyword = "";
				this.setSearchKeyword("");
				this.$refs.keyword.focus();
			},
			updateSearch(){
				this.setSearchKeyword(this.keyword);
			},
			search(){
				//they arent on a search grid yet
				if(_.isNil(this.area)){
					this.setSearchKeyword(this.keyword);
					this.$navigate({ name: 'all-products-grid', params: {area: 'Search', passedSearchKeyword: this.keyword}});
				}
				this.$modal.hide('search-modal');
				this.pageNum = 1;
			},
			...mapActions([
				'setSearchKeyword',
			])

		}
	}
</script>

