<template>
	<div class="row screensaver-row">
		<vue100vh class="screensaver col-sm-12" >
			<video @click="goHome()" autoplay="true"
				ref="video"
				poster="iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mP89etbPQAJSwNrvKpw1AAAAABJRU5ErkJggg=="
				muted="true"
				loop="true"
				playsinline
				preload="false"
				src="~@/assets/videos/ambient.mp4"
				width="100%"
				height="100%">
			</video>
			<div class="get-started">
				<a @click="goHome();$ua.trackEvent('Ambient', 'Click', '/landing');" class="btn-default add-shadow"><span>TOUCH SCREEN ANYWHERE TO BEGIN</span></a>
			</div>
			<a v-if="!isKiosk && !$store.state.userLoggedIn" @click="dealerLogin()" class="btn-secondary add-shadow dealer-login">DEALER LOGIN</a>
		</vue100vh>
		<dealer-login></dealer-login>
	</div>
</template>

<script>
import {navbar} from 'vue-strap';
import _ from 'lodash';
import { mapGetters, mapActions } from 'vuex';
import DealerLogin from '../components/DealerLogin';
import vue100vh from 'vue-100vh';

export default {
	name: 'ScreenSaver',
	data(){
		return{
		}
	},
	props: {
	},
	computed: {
		isKiosk(){
			if(this.appId == "com.flexsteel.kiosk"){
				return true;
			}
			return false;
		},
	},
	methods: {
		goHome(){
			console.log("going home");
			if(this.isKiosk){
				//clear the cart
				this.clearCart();
			}
			//go to landing page
			this.$navigate("landing");
		},
		dealerLogin(){
			this.$modal.show('dealerLoginModal');
		},
		...mapActions([
			'clearCart'
		])
	},
	updated() {
	},
	activated(){
		if(!this.isKiosk && this.windowWidth <= 550){
			this.$navigate("landing");
		}else{
			this.$refs.video.play();
		}
	},
	components: {
		dealerLogin: DealerLogin,
		vue100vh
	}
};
</script>

