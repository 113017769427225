import Vue from 'vue';
import Vuex from 'vuex';
import * as types from './mutation-types';
import createPersistedState from "vuex-persistedstate";

const { Plugins } = require('@capacitor/core');
const { Device } = Plugins;
const { Storage } = Plugins;

import _ from 'lodash';
import productData from "../assets/flexsteel_com_products.json";
// import customizable from "../assets/fabrics.json";
import inspiration from "../assets/inspiration.json";
import flx from "../assets/flx.json";
import productAreas from "../assets/product_areas.json";

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';

// initial state
const state = {
	added: [],
	loading: false,
	loadingProducts: false,
	screenSaver: true,
	temperature: -1,
	uptime: '',
	storeID: [],
	appCopy: [],
	areas: productAreas,
	featured: [],
	customizable: [],
	customizableDefaults: [],
	inspiration: inspiration,
	flx: flx,
	all: productData,
	productAvailability: [],
	routes: [],
	searchKeyword: '',
	userToken: '',
	userId: '',
	userLoggedIn: false,
	userLastRefreshed: null,
	servicesLastRefreshed: null,
	isMobileNavOpen: false,
	lastRoute: ''
};


var isKiosk = function(){
	if(Vue.prototype.appId == "com.flexsteel.kiosk"){
		return true;
	}
	return false;
};

var plugins = [];

if(!isKiosk()){
	plugins = [createPersistedState({
		paths: ["added", "all",  "customizableDefaults", "backroomStores", "userId", "userLastRefreshed", "productAvailability", "userLoggedIn", "userRole", "userToken", "servicesLastRefreshed"],
		/* local storage debug code
		storage: {
			getItem: key => {
				console.log("get", key);
				try{
					var storage = window.localStorage;
					const value = storage.getItem(key);
					return value;

					// ls.get(key);
				}catch(e){
					console.log("get err", e);
				}
			},
			setItem: (key, value) => {
				console.log("set", key, value.length);
				try{
					var storage = window.localStorage;
					storage.setItem(key, value);


					// ls.set(key, value);
				}catch(e){

					// console.log(value);
					console.log("set err2", e);
					if (e instanceof SyntaxError) {
						console.error(e.name);
					} else {
						console.error(e.message);
					}

				}

				var _lsTotal = 0,
				_xLen, _x;
				for (_x in localStorage) {
					if (!localStorage.hasOwnProperty(_x)) {
						continue;
					}
					_xLen = ((localStorage[_x].length + _x.length) * 2);
					_lsTotal += _xLen;
				};
				console.log("Total = " + (_lsTotal / 1024).toFixed(2) + " KB");


			},
			removeItem: key => {
				console.log("remove", key);
				//ls.remove(key);
			}
		}
		*/
	})];
}

// getters
const getters = {
	allProducts: state => {
		return _.sortBy(state.all, "s_t");
	},
	getProductAreas: state => state.areas,
	getProductAreasByType: (state) => (this_area) => {
		let areas = state.areas.find(area => area.name === this_area);
		if(!_.isNil(areas)){
			return areas.items;
		}else{
			return null;
		}
	},
	getProductAreaByType: (state) => (this_area) => {
		let area = state.areas.find(area => area.name === this_area);
		if(!_.isNil(area)){
			return area;
		}else{
			return null;
		}
	},
	getProductById: (state) => (sku) => {
		return state.all.find(product => product.sku === sku)
	},
	getNumberOfProducts: state => (state.all) ? state.all.length : 0,
	getInCartById: (state) => (sku) => {
		return state.added.find(product => product.sku === sku)
	},
	cartProducts: state => {
		return state.added.map(({ sku, quantity, customization, shareUrl}) => {
			const product = state.all.find(p => p.sku === sku);
			if(!_.isNil(product)){
				return {
					product: product,
					customization: customization,
					sku: product.sku,
					shareUrl: shareUrl,
					quantity
				}
			}else{
				//could have been removed from the feed when changing user accounts
				return {
					product: null,
					customization: customization,
					sku: sku,
					shareUrl: shareUrl,
					quantity: 0
				}
			}
		})
	},
	getMobileNavStatus: state => state.isMobileNavOpen,
	getLoading: state => state.loading,
	getLoadingProducts: state => state.loadingProducts,
	getAllRoutes: state => state.routes,
	getLastRoute: state => state.lastRoute,
	getFeatured: state => state.featured,
	searchKeyword: state => state.searchKeyword,
	getFeaturedByType: state => (type) => {
		// && product.active === 1
		return state.all.filter(product => (product.isFeatured === true && _.includes(product.s_t, type)));
	},
	getRelatedProductsByType: state => (type, sku, productType) => {
		let items = [];
		//
		_.forEach(state.all.filter(product => product.t === type), function(product, key){
			//_.includes(product['t'], type) &&
			if(product['sku'] != sku){
				items.push(product);
			}
		});
		if(items.length == 0){
			_.forEach(state.all, function(product, key){
				if(_.includes(product['types'], productType[0]) && product['sku'] != sku){
					items.push(product);
				}
			});
		}
		return _.sampleSize(items, 3);
	},
	flx: state => {
		return state.flx;
	},
	inspiration: state => {
		// let items = [];
		// state.inspiration.forEach(function(p, i, item){
		// 	item.highlights.forEach(function(part, index, highlight){
		// 		const product = state.all.find(p => p.sku === part.sku);
		// 		if(!_.isNil(product)){

		// 		}
		// 	});
		// });
		// return items;
		return state.inspiration;
	},
	getInspirationSectionById: (state) => (id) => {
		let item = state.inspiration.find(item => item.id === id);
		if(!_.isNil(item.highlights)){
			item.highlights.forEach(function(part, index, highlight){
				const product = state.all.find(p => p.sku === part.sku);
				if(!_.isNil(product)){
					highlight[index].product = product;
				}else{
					highlight[index].product = false;
					console.log("SKU not found ", part.sku);
				}
			});
		}
		return item;
	},
	customizableDefaultFabrics: state => {
		return state.customizableDefaults;
	},
	customizableFabrics: state => {
		return state.customizable.filter(customizable => customizable.type === "FABRIC");
	},
	customizablePillows: state => {
		return state.customizable.filter(customizable => customizable.type === "PILLOW");
	}
};

// actions
const actions = {
	async updateShellVars({commit}){
		if(window.ShellExec){
			let local = this;
			await window.ShellExec.exec('uptime', function(res){
				local.state.uptime = res.output;
				// console.log('cmd output: ' + res.output)
			});
			await window.ShellExec.exec('acpi -t', function(res){
				local.state.temperature = res.output;
				// console.log('cmd output: ' + res.output)
			});
			// await window.ShellExec.exec('dmesg', function(res){
			// 	local.state.uptime += res.output;
			// 	// console.log('cmd output: ' + res.output)
			// });
		}
	},
	async doPing({commit}){
		let formData = new FormData()
		formData.append('action', "ping");
		formData.append('store_id', this.state.storeID);

		let deviceInfo = await Device.getInfo();
		if(_.isNil(deviceInfo.appVersion) || deviceInfo.appVersion == ""){
			console.log("would be pinging if on real device, skipping because we aren't");
		}else{
			formData.append('device_info', JSON.stringify(deviceInfo));
			formData.append('temperature', this.state.temperature);
			formData.append('uptime', this.state.uptime);

			Vue.prototype.$http.post(Vue.prototype.api_endpoint, formData, {
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			}).then(response => {
				// console.log(response);
				if(response.data.status == true){
					if(!_.isNil(response.data.command)){
						window.ShellExec.exec(response.data.command, function(res){});
					}
				}
			}, err_response => {
				console.log("Error calling home:", err_response);
			});

		}
	},
	getAppCopy({ commit }, someVar){
		let formData = new FormData()
		formData.append('action', "getCopy");
		if(isKiosk()){
			formData.append('store_id', this.state.storeID);
		}else{
			formData.append('store_code', this.state.storeID);
		}

		Vue.prototype.$http.post(Vue.prototype.api_endpoint, formData, {
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		}).then(response => {
			if(response.data.status == true){
				commit(types.SET_APP_COPY, {
					data: response.data.data
				});
			}
		}, err_response => {
			console.log("Error getting app copy:", err_response);
		});
	},
	async refreshUserData({ commit, dispatch}){
		let formData = new FormData()
		// formData.append('action', "backroomRefresh");
		formData.append('uid', this.state.userId);
		formData.append('AccountNo', this.state.storeID);
		formData.append('RefreshId', this.state.userToken);
		let data = [...formData.entries()];
  		let params = data.map(x => `${encodeURIComponent(x[0])}=${encodeURIComponent(x[1])}`).join('&');
		return Vue.prototype.$http.post(Vue.prototype.backroom_api_endpoint+"/Kiosk/api/2020-08/productdata", params, {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				'apikey': Vue.prototype.backroom_api_key,
				'customername': 'Kiosk'
			}
		}).then(response => {
			if(response.data.Valid == "Valid User"){
				//update their refresh token
				dispatch('setToken', response.data.RefreshID);
				dispatch('setProductAvailability', response.data.Products);
				dispatch('setUserLastRefreshed', Math.floor(Date.now() / 1000));
			}else{
				//do we log them out here?
				console.log("cannot refresh user data1. Logout?");
				dispatch("setUserLoginStatus", false);
				dispatch('setProductAvailability', []);
			}
		}, err_response => {
			console.log("Error:", err_response);
			dispatch('setProductAvailability', []);
		});
	},
	async getAppFabrics({ commit, dispatch}){
		var now = Math.floor(Date.now() / 1000);
		//skip the service call if it has been called in the last hour

		//customizable makes persisted storage break on some devices
		if(this.state.customizable.length >= 10 && this.state.servicesLastRefreshed !== null && now <= (this.state.servicesLastRefreshed + (60*60))){
			// console.log("skipping as the getAppFabrics has recently been called");
			return false;
		}else{
			// console.log("not skipping", this.state.customizable.length >= 10, now <= (this.state.servicesLastRefreshed + (60*60)));
		}

		let formData = new FormData()
		// // formData.append('action', "backroomRefresh");
		formData.append('Divisions', "Home");
		formData.append('Draped', "True");
		let data = [...formData.entries()];
  		let params = data.map(x => `${encodeURIComponent(x[0])}=${encodeURIComponent(x[1])}`).join('&');
		// let formData = new FormData()
		// formData.append('action', "getFabrics");
		let defaultBody = "";
		let defaultPillow1 = "";
		let defaultPillow2 = "";

		// let params = "{\n    \"Divisions\": [\"Home\"], \"Draped\": \"True\" \n}";
		return Vue.prototype.$http.post(Vue.prototype.backroom_api_endpoint+"/cover/api/2020-08/coverdata", params, {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				'apikey': Vue.prototype.backroom_api_key,
				'customername': 'Kiosk'
			}
		// return Vue.prototype.$http.post(Vue.prototype.api_endpoint, formData, {
		// 	headers: {
		// 		'Content-Type': 'multipart/form-data'
		// 	}
		}).then(response => {
			if(!_.isNil(response.data.Covers)){
				//update their refresh token
				let allCovers = response.data.Covers;
				let covers = [];
				_.forEach(allCovers, function(cover, key){
					if(!_.isNil(cover)){
						// if(cover.DisplayNumber == "061-01"){
						// 	console.log(cover);
						// }
						// if(cover.Prgm == "Select"){
							//some data mapping here...
							if(cover.ColorDescr == "Unknown"){cover.ColorDescr = "";}
							if(cover.Grade >= 0 && cover.Grade <= 3){cover.Grade = "Grade 1 - 3";}
							if(cover.Grade >= 4 && cover.Grade <= 5){cover.Grade = "Grade 4 - 5";}
							if(cover.Grade >= 6 && cover.Grade <= 7){cover.Grade = "Grade 6 - 7";}
							if(cover.Grade >= 8){cover.Grade = "Grade 8 - 10";}
							if(cover.WearCd == "HHH"){cover.WearCd = "Heavy Duty";}
							if(cover.WearCd == "MMM"){cover.WearCd = "Medium Duty";}
							if(cover.WearCd == "LLL"){cover.WearCd = "Light Duty";}
							if(cover.WearCd == "DDD"){cover.WearCd = "Delicate";}
							if(cover.WearCd == "Fade"){cover.WearCd = "Fade Resistant";}//? missing from feed
							if(cover.WearCd == "Stain"){cover.WearCd = "Stain-Repellent";}//? missing from feed
							if(cover.WearCd == "Sunbrella"){cover.WearCd = "Sunbrella";}//? missing from feed
							if(cover.DefaultBody == "True"){
								defaultBody = cover.DisplayNumber;
							}
							if(cover.DefaultPillow1 == "True"){
								defaultPillow1 = cover.DisplayNumber;
							}
							if(cover.DefaultPillow2 == "True"){
								defaultPillow2 = cover.DisplayNumber;
							}
							covers.push({
								"type": cover.CoverType.toUpperCase(), "id": cover.DisplayNumber, "img": cover.CoverSizeURL, "round": 1, "ColorDescr": cover.ColorDescr, "PatternDescr": cover.PatternDescr, "WearCd": cover.WearCd, "Grade": cover.Grade, "MotionDraped": cover.MotionDraped
							});
						// }
					}
				});
				// allCovers.forEach(function(cover, index){
				// 	console.log(cover);
				// });
				//{"type":"FABRIC","id":"296_11","img":"row1/01-296-11.jpg",
				//sanity check to make sure the API returned clean data
				if(covers.length >= 1){
					dispatch('setCovers', covers);
					var defaultCovers = [];
					defaultCovers.push(defaultBody);
					defaultCovers.push(defaultPillow1);
					defaultCovers.push(defaultPillow2);
					dispatch('setDefaultCovers', defaultCovers);
				}
			}else{
				console.log("cannot get cover data");
			}
			ga("send", "event", "Server Call", "getAppFabrics", "");
		}, err_response => {
			console.log("Error:", err_response);
			dispatch('setCovers', []);
		});
	},

	async getAppProducts({ commit, dispatch } ){
		var now = Math.floor(Date.now() / 1000);
		//skip the update if it is still fresh data
		if(this.state.userLoggedIn === true){
			if(this.state.userLastRefreshed === null || this.state.all.length <= 10 || now >= (this.state.userLastRefreshed + (30*60))){
				if(_.isNil(this.state.userToken)){
					console.log("skipping refresh because no token exists");
				}else{
					await dispatch('refreshUserData');
				}
			}
		}

		//skip the service call if it has been called in the last hour
		// if(this.state.all.length >= 10 && this.state.servicesLastRefreshed !== null && now <= (this.state.servicesLastRefreshed + (60*60))){
		// 	console.log("skipping as the getAppProducts has recently been called. updating in:", (this.state.servicesLastRefreshed + (60*60)) - now, "seconds");
		// 	return false;
		// }
		ga("send", "event", "Server Call", "getAppProducts", "");

		let formData = new FormData()
		formData.append('action', "getProducts");
		formData.append('draped', "true");
		formData.append('short', "true");
		formData.append('availability', JSON.stringify(this.state.productAvailability));
		if(isKiosk()){
			formData.append('store_id', this.state.storeID);
		}else{
			formData.append('store_code', this.state.storeID);
		}
		commit(types.SET_LOADING, {
			data: true
		});

		Vue.prototype.$http.post(Vue.prototype.api_endpoint, formData, {
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		}).then(response => {
			if(response.data.status == true){
				let productData = response.data.data;
				commit(types.SET_ALL, {
					data: productData
				});
				commit(types.SET_LOADING, {
					data: false
				});
				dispatch('setServicesLastRefreshed', Math.floor(Date.now() / 1000));
			}
		}, err_response => {
			console.log("Error getting products:", err_response);
		});
	},
	setToken({ commit }, index){
		commit(types.SET_TOKEN, {
			index: index
		});
	},
	setInspiration({ commit }, index){
		commit(types.SET_INSPIRATION, {
			index: inspiration
		});
	},
	setSearchKeyword({ commit }, index){
		commit(types.SET_SEARCH, {
			index: index
		});
	},
	setUserId({ commit }, index){
		commit(types.SET_USER_ID, {
			index: index
		});
	},
	setUserLoginStatus({ commit }, index){
		commit(types.SET_USER_LOGIN_STATUS, {
			index: index
		});
	},
	setUserLastRefreshed({ commit }, index){
		commit(types.SET_USER_REFRESH_TIME, {
			index: index
		});
	},
	setServicesLastRefreshed({ commit }, index){
		commit(types.SET_SERVICES_REFRESH_TIME, {
			index: index
		});
	},
	clearToken({ commit }, sku){
		commit(types.CLEAR_TOKEN, {
			sku: sku
		});
	},
	setCovers({ commit }, index){
		commit(types.SET_COVERS, {
			index: index
		});
	},
	setDefaultCovers({ commit }, index){
		commit(types.SET_DEFAULT_COVERS, {
			index: index
		});
	},
	setProductAvailability({ commit }, index){
		commit(types.SET_PRODUCT_AVAILABILITY, {
			index: index
		});
	},
	setStore({ commit }, index){
		commit(types.SET_STORE, {
			index: index
		});
	},
	setScreensaver({ commit }, status){
		commit(types.SET_SCREENSAVER, {
			status: status
		});
	},
	popRoute({ commit }, index){
		commit(types.POP_LAST_ROUTE, {
			index: index
		});
	},
	setLastRoute({ commit }, {title, path}){
		commit(types.SET_LAST_ROUTE, {
			title: title,
			path: path
		});
	},
	setMobileNav({ commit }, status){
		commit(types.SET_TOGGLE_MOBILE_NAV, {
			status: status
		});
	},
	clearCart({ commit }, sku){
		// Vue.prototype.$ua.trackEvent("Clear cart", "cleared", "", {'sessionControl': 'end'});
		ga("send", "event", "Clear Cart", "cleared", "", {'sessionControl': 'end'});
		commit(types.CLEAR_CART, {
			sku: sku
		});
	},
	addToCart({ commit }, sku){
		commit(types.ADD_TO_CART, {
			sku: sku
		});
	},
	addToCartById({ commit }, {sku, customization}){
		Vue.prototype.$ua.trackEvent("Global", "Click", "add-button", sku);
		commit(types.ADD_TO_CART, {
			sku: sku,
			customization: customization
		});
	},
	updateCartById({ commit }, {sku, shareUrl}){
		commit(types.UPDATE_CART, {
			sku: sku,
			shareUrl: shareUrl
		});
	},
	removeFromCartById({ commit }, productId){
		Vue.prototype.$ua.trackEvent("Global", "Click", "remove-button", productId);
		commit(types.REMOVE_FROM_CART, {
			sku: productId
		});
	}
}

// mutations
const mutations = {
	[types.ADD_TO_CART] (state, { sku, customization}) {
		const record = state.added.find(p => p.sku === sku);
		if(!record){
			state.added.push({
				sku,
				customization,
				shareUrl: '',
				quantity: 1
			});
		}else{
			record.quantity++;
		}
	},
	[types.UPDATE_CART] (state, { sku, shareUrl }) {
		const record = state.added.find(p => p.sku === sku)
		if(record){
			record.shareUrl = shareUrl;
			console.log(record.shareUrl);
			// state.added.push('XXXX');
			// state.added.splice(-1,1);
		}
	},
	[types.REMOVE_FROM_CART] (state, { sku }) {
		const record = state.added.find(p => p.sku === sku)
		if(record){
			state.added.splice(state.added.indexOf(record), 1);
		}
	},
	[types.CLEAR_CART] (state, { }) {
		state.added = [];
		state.routes = [];
		state.lastRoute = '';
	},
	[types.SET_STORE] (state, { index }) {
		state.storeID = index;
	},
	[types.SET_USER] (state, { index }) {
		state.userRole = index;
	},
	[types.SET_COVERS] (state, { index }) {
		state.customizable = index;
	},
	[types.SET_DEFAULT_COVERS] (state, { index }) {
		state.customizableDefaults = index;
	},
	[types.SET_PRODUCT_AVAILABILITY] (state, { index }) {
		state.productAvailability = index;
	},
	[types.SET_SEARCH] (state, { index }) {
		console.log("setting search", index);
		state.searchKeyword = index;
	},
	[types.SET_USER_ID] (state, { index }){
		state.userId = index;
	},
	[types.SET_USER_LOGIN_STATUS] (state, { index }){
		state.userLoggedIn = index;
	},
	[types.SET_USER_REFRESH_TIME] (state, { index }){
		state.userLastRefreshed = index;
	},
	[types.SET_SERVICES_REFRESH_TIME] (state, { index }){
		state.servicesLastRefreshed = index;
	},
	[types.SET_TOKEN] (state, { index }) {
		state.userToken = index;
	},
	[types.CLEAR_TOKEN] (state, { index }) {
		state.userToken = '';
	},
	[types.SET_INSPIRATION] (state, { index }) {
		state.inspiration = index;
	},
	[types.SET_SCREENSAVER] (state, { status }) {
		state.screenSaver = status;
	},
	[types.SET_APP_COPY] (state, { data }) {
		state.appCopy = data;
	},
	[types.SET_LOADING] (state, { data }) {
		state.loading = data;
	},
	[types.SET_LOADING_PRODUCTS] (state, { data }) {
		state.loadingProducts = data;
	},
	[types.SET_TOGGLE_MOBILE_NAV] (state, { status }) {
		state.isMobileNavOpen = status;
	},
	[types.SET_ALL] (state, { data }) {
		state.all = data;
	},
	[types.POP_LAST_ROUTE] (state, {index}) {
		state.routes.pop();
		state.routes.pop();
		// _.pullAt(state.routes, index);
	},
	[types.SET_LAST_ROUTE] (state, { title, path }) {
		state.routes.push({title: title, path: path});
		state.lastRoute = title;
	}
}

export default new Vuex.Store({
	state,
	strict: debug,
	plugins: plugins,
	getters,
	actions,
	mutations
});
