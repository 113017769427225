<template>
	<div :class="'area-grid-item inner type-'+type" @click="goNext()">
		<div class="content">
			<img :src="'static/img/product_areas/'+area.photo"/>
			<div class="bottom">
				<div class="content">
					<span v-if='type == 1'>View&nbsp;</span>{{area.name}}
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import _ from 'lodash';
import { mapGetters, mapActions } from 'vuex';

export default {
	name: 'AreaGridItem',
	components:{
	},
	data(){
		return{
		}
	},
	props: [
		'area',
		'type'
	],
	computed: {
	},
	methods: {
		goNext(){
			if(this.type == 2){
				this.$navigate({ name: 'all-products-grid', params: {area: this.area.name}});
			}else{
				this.$navigate({ name: 'all-products-area', params: {basearea: this.area.name}});
			}
			this.setSearchKeyword("");
		},
		...mapActions([
			'setSearchKeyword',
		])
	}
};
</script>
