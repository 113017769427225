<template>
	<div id="flexsteel" class="dragscroll" :class="{'is-kiosk': isKiosk, 'is-safari': isSafari, 'embedded': isEmbedded}" @click="resetTimer" @scroll="resetTimer">
		<keep-alive include="ProductGrid">
			<router-view :key="$route.fullPath + ($route.params.passedSearchKeyword || null)"></router-view>
		</keep-alive>
		<setup-bar v-if="isKiosk" ref="setupBar"></setup-bar>

		<digital-catalog-modal></digital-catalog-modal>
		<div class="embedded-bottom" data-iframe-height></div>
	</div>
</template>

<script>
import Vue from 'vue';
import { mapGetters, mapActions, mapState } from 'vuex';
import SetupBar from './components/SetupBar';
import Icon from './components/Icon';
import DigitalCatalogModal from './components/DigitalCatalogViewer';

export default {
	name: 'flexsteel',
	components: {
		setupBar: SetupBar,
		digitalCatalogModal: DigitalCatalogModal
	},
	data: function(){
		return {
			doSaver: true,
			timer: '',
			pingtimer: ''
		}
	},
	// created: function(){
	// 	this.startTimer();
	// },
	computed: {
		isKiosk(){
			if(this.appId == "com.flexsteel.kiosk"){
				return true;
			}
			return false;
		},
		isSafari(){
			var ua = navigator.userAgent.toLowerCase();
			if (ua.indexOf('safari') != -1) {
				if (ua.indexOf('chrome') > -1) {
					return false;// Chrome
				} else {
					return true;// Safari
				}
			}
			return false;
		},
		//is the app embedded in an iframe?
		isEmbedded(){
			//'parentIFrame' in window - is not ready when this gets calculated
			if(!this.isKiosk){
				if(window.self !== window.top){
					return true;
				}else{
					return false;
				}
			}
		},
		...mapState(['screenSaver']),
	},
	methods:{
		startTimer: function(){
			this.timer = setInterval(this.activateScreenSaver, 180*1000);
		},
		startPingTimer: function(){
			this.pingtimer = setInterval(this.doPing, 300*1000);
		},
		resetTimer: function(){
			if(this.isKiosk && this.doSaver){
				clearInterval(this.timer);
				this.startTimer();
			}
		},
		stopTimer: function(){
			console.log("stopping timer...");
			clearInterval(this.timer);
		},
		activateScreenSaver: function(){
			//we do a hard reload to clear the keep-alive on the router
			window.location.replace('/');
			// this.$router.push("/screensaver");
		},
		doPing(){
			this.$store.dispatch('updateShellVars');
			//give our shell script time to run
			setTimeout(()=>{
				this.$store.dispatch('doPing');
			}, 10*1000);
		},
		cancelAutoUpdate: function() { clearInterval(this.timer) },
		...mapGetters({
			lastRoute: 'getLastRoute'
		}),
		...mapActions([
			'setLastRoute',
			'popRoute'
		])
	},
	created(){
		if(this.isKiosk){
			window.addEventListener('scroll', this.resetTimer);
			this.startTimer();
			this.startPingTimer();
			this.doPing();
		}
	},
	destroyed(){
		if(this.isKiosk){
			window.removeEventListener('scroll', this.resetTimer);
		}
	},
	watch: {
		'$route' (to, from){
			Vue.nextTick(() =>{
				this.setLastRoute({title: document.title, path: to.path});
				if('parentIFrame' in window){
					var obj = {};
					obj.action = "changeSource";
					obj.path = to.path;
					parentIFrame.sendMessage(obj);
				}
			});
		},
		'screenSaver' (to, from){
			if(to == "stop"){//they are stopping the screensaver
				this.stopTimer();
				this.doSaver = false;
			}else{
				this.doSaver = true;
			}
			console.log("toggling screensaver", to);
		}
	},
	beforeDestroy(){
		clearInterval(this.timer)
	}
}
</script>

<style lang="scss">
	@import 'scss/global.scss';
</style>
