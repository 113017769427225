<template>
	<div @click="goToPDP" ref="customProductImage" class="custom-product-image ">
		<span class="content" :class="view + ' img img-holder '+type">
			<transition name="fade">
				<div :key="image" class="new">
					<cached-image :src="image"></cached-image>
				</div>
			</transition>
			<div class="old">
				<cached-image :src="oldImage"></cached-image>
			</div>
		</span>
	</div>
</template>

<script>
import _ from 'lodash';
import { mapGetters, mapActions } from 'vuex';
import CachedImage from '../components/CachedImage';
// import { HTTP } from '@ionic-native/http/ngx';
// const http = new HTTP();

export default {
	name: 'CustomizableProductImage',
	components:{
		cachedImage: CachedImage,
	},
	data(){
		return{
			'productHeight': 50,
			'productWidth': 50,
			'modified': false,
			'image': 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=',
			'oldImage': 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII='
		}
	},
	props: [
		'sku',
		'round',
		'view',
		'skipClick',
		'customization',
		'type'
	],
	watch: {
		customization: {
			handler(newVal, oldVal){
				this.modified = true;
				this.initCylindo();
			},
			deep: true
		}
	},
	computed: {
		activeImage(){
			return this.image;
		},
		customization_set(){
			return this.customization;
		},
		...mapGetters({
			allProducts: 'allProducts',
			fabrics: 'customizableFabrics',
			pillows: 'customizablePillows'
		})
	},
	methods: {
	    onResize() {
			this.productWidth = this.$refs.customProductImage.clientWidth;
			this.productHeight = this.$refs.customProductImage.clientHeight;
    	},
		initCylindo(){
			let product = this.allProducts.find(prod => prod.sku === this.sku);
			if (!product) {
				return;
			}
			this.image = product.img[0];
			return true;

			var features = [
				"BODY", this.customization.FABRIC,
				"BODY 2", this.customization["BODY 2"],
				"PILLOW GROUP 1", this.customization.PILLOW_1,
				"PILLOW GROUP 2", this.customization.PILLOW_2
			];

			var options = {
				frame: 1,
				size: 400,
			}
			if(_.includes(this.view, 'large')){
				options.frame = 1;
				options.size = 1000;
				options.format = 'png';
				// options.bgcolor = '#F6F8F9';
			}

			if(_.includes(this.view, 'featured')){
				options.frame = 1;
				options.size = 1000;
				options.format = 'png';
				// options.bgcolor = '#F6F8F9';
			}

			if(_.includes(this.view, 'thirty-hero')){
				options.frame = 1;
				options.size = 1000;
				options.format = 'png';
				// options.bgcolor = '#F6F8F9';
			}
			if(_.includes(this.view, 'vision-grid')){
				options.frame = 1;
				options.size = 230;
				options.format = 'png';
				// options.bgcolor = '#F8F9FB';
			}
			if(_.includes(this.view, 'saved')){
				options.format = 'png';
				options.width = 400;
			}

			if(this.type == "recliner"){
				options.frame = 1;
			}
			if(this.type == "sectional"){
				options.frame = 5;
				if(_.includes(this.view, 'thirty-grid')){
					options.size = 1000;
				}
			}

			let qString = "?size="+options.size+"&";

			var local = this;
			this.$http.get("https://content.cylindo.com/api/v2/4768/products/"+this.sku+"/configuration").then(response => {

				var foundFeatures = response.data.features;
				for(var foundFeature in foundFeatures){
					if(foundFeatures[foundFeature].code === "BODY"){
						qString += "feature=BODY:"+local.customization.FABRIC+"&";
					}
					if(foundFeatures[foundFeature].code === "BODY 2"){
						qString += "feature=BODY 2:"+local.customization["BODY 2"]+"&";
					}
					if(foundFeatures[foundFeature].code === "PILLOW GROUP 1"){
						qString += "feature=PILLOW GROUP 1:"+local.customization.PILLOW_1+"&";
					}
					if(foundFeatures[foundFeature].code === "PILLOW GROUP 2"){
						qString += "feature=PILLOW GROUP 2:"+local.customization.PILLOW_2+"&";
					}
				}

				if(options.format == 'png'){
					qString += "encoding=png&";
				}

				this.oldImage = this.image;
				this.image = "https://content.cylindo.com/api/v2/4768/products/"+local.sku+"/frames/"+options.frame+"/"+qString;
				this.$emit("shareImageUpdated", this.image, this.sku);
			}, err_response => {
				console.log(err_response);
			});
		},
		goToPDP(){
			if(!this.skipClick){
				this.$goToPDP(this.sku, this.customization);
			}
			// this.$emit('select', this.tile);
		}
	},
	mounted(){
		this.initCylindo();
		this.$nextTick(() => {
			window.addEventListener('resize', this.onResize);
		})
	},
	beforeDestroy() {
    	window.removeEventListener('resize', this.onResize);
  	}
};
</script>

<style lang="scss" scoped>
	// @import '../scss/vars';
	.custom-product-image{
		background: #FFFFFF;

	}

	.fade-enter-active{
		transition: opacity 0.5s ease-in-out;
	}

	.fade-enter-to{
		opacity: 1;
	}

	.fade-enter{
		opacity: 0;
	}
	.img{

		position: relative;
		.old{
			display: none;
		}
		&.thirty-grid, &.related, &.landing, &.thirty-hero{
			width: 100%;
			height: 250px;
			display: block;
			.new{
				position: absolute;
				top: 0px;
				left: 0px;
				width: 100%;
				height: 250px;
				z-index: 2;
			}
			.old{
				display: block;
				position: absolute;
				width: 100%;
				height: 250px;
				top: 0px;
				left: 0px;
				z-index: 1;
			}
			img{
				object-fit: contain;
				max-height: 250px;
				max-width: 100%;
				width: 100%;
				height: 100%;
			}
			&.sectional{
				zoom: 1.65;
				// width: 165%;
				// margin-left: -35%;
				.new, .old{
					top: -35px;
				}
			}
		}
		&.vision-grid{
			// width: 380px;
			height: 250px;
			width: 100%;
			img{
				max-height: 200px;
				max-width: 100%;
			}
		}
		&.landing{
			img{
				max-height: 300px;
			}
		}
		&.related{
			width: 158px;
			height: 190px;
			.new{
				top: 10px;
				width: 158px;
				height: 158px;
			}
			.old{
				top: 10px;
				width: 158px;
				height: 158px;
			}
			img{
				max-height: 190px;
			}
		}
		img{
			max-width: 100%;
		}
		&.thirty-hero{
			overflow: hidden;
			width: auto;
			height: 400px;
			display: block;
			.new, .old{
				width: auto;
				height: 400px;
			}
			img{
				// margin-top: -80px;
				// margin-left: -80px;
				max-height: 400px;
			}

		}
		&.featured{
			overflow: hidden;
			width: 560px;
			height: 490px;
			display: block;
			img{
				// margin-top: -80px;
				// margin-left: -80px;
				height: 490px;
				// width: 550px;
				max-width: none;
			}
		}

		&.large{
			overflow: hidden;
			width: 400px;
			height: 400px;
			display: block;
			img{
				margin-top: -80px;
				margin-left: -80px;
				height: 550px;
				width: 550px;
				max-width: none;
			}
		}
		&.large.loveseat{
			overflow: hidden;
			width: 660px;
			height: 400px;
			display: block;
			img{
				margin-top: -190px;
				margin-right: 0px;
				height: 800px;
				width: 800px;
				max-width: none;
			}
		}
		&.large.sectional{
			overflow: hidden;
			width: 560px;
			height: 400px;
			display: block;
			img{
				margin-top: -290px;
				margin-left: -350px;
				height: 1000px;
				width: 1000px;
				max-width: none;
			}
		}
		&.grid{
			img{
				margin-top: 50px;
				height: 350px;
				// width: 800px;
			}
			&.iframe{
				height: 175px;
			}
		}
	}
	.custom-product-image{
		&.iframe{
			.grid{
				img{
					margin-top: 50px;
					height: 250px;
					// width: 800px;
				}
			}

		}
	}

</style>
