<template>
	<div class="savedPage">
		<vue-headful title="Saved"/>
		<div class="tab-content">
			<div class="" v-show="products.length">
				<back-button></back-button>

				<div class="row">
					<div class="top">
						<h1>
							Your <span>{{products.length}}</span> Saved Items
						</h1>
					</div>
					<div class="grid non-mobile-only">
						<swiper :options="swiperOption" ref="swiper">
							<div class="btn-left" slot="button-prev"><i class="icon-down-open-big"></i></div>
							<div class="btn-right" slot="button-next"><i class="icon-down-open-big"></i></div>
							<swiper-slide class="grid-items" v-for="p in products">
								<saved-cart-item @shareImageUpdated="shareImageUpdated" class="grid-item saved" :product="p.product" :customization="p.customization" type="saved"></saved-cart-item>
							</swiper-slide>
						</swiper>
					</div>
					<div class="grid mobile-only">
						<div class="grid-items" v-for="p in products">
							<saved-cart-item class="grid-item saved" :product="p.product" :customization="p.customization" type="saved"></saved-cart-item>
						</div>
					</div>

				</div>
				<div class="actions">
					<a class="btn-default add-shadow" @click="showEmail"><span>EMAIL YOUR ITEMS</span></a>
					<a class="btn-secondary add-shadow" @click="showText"><span>TEXT ITEMS TO YOUR PHONE</span></a>
				</div>
				<div class="row cart-actions">
					<a @click="clearCartLocal()">Clear all items and start over</a>
				</div>
			</div>
			<div class="row no-saved" v-show="!products.length">
				<back-button></back-button>
				<div class="grid">
					<div class="top">
						<h1>
							You haven't saved any items yet.
						</h1>
						<p class="first"><strong>Tap&nbsp;<i class="icon icon-add-blue"></i>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;to add products to your Saved Items</strong><br/>then email or text them to yourself</p>
						<div class="saved-arrow-block">
							<p class="second"><strong>Saved Items</strong> will appear here</p>
							<i class="icon icon-saved-arrow"></i>
						</div>
					</div>
				</div>
			</div>
		</div>
		<modal name="email-me" :width="modalWidth" :height="modalHeight" :class="{'confirmed': confirmEmail}" @opened="openedEmail" :adaptive="true" :pivotY="0.1">
			<div v-if="!confirmEmail">
				<div class="modal-top">
					<form autocomplete="off" v-on:submit.prevent="stopSubmit">
						<a @click="hideEmail()" class="close-icon"><icon icon-type="icon-close"></icon></a>
						<h2>We'll email your favorites</h2>
						<p>Enter your email address to receive your saved products and corresponding catalog pages for you to keep.</p>
						<div class="row">
							<div class='col-md-9'>
								<input autocomplete="off" id="email" :class="{'error': hasError}" v-model="email" ref="email" type="email" placeholder="email address"/>
							</div>
							<div class='col-md-3'>
								<button @click="submit()" :class="{'btn-default': true, 'btn-submit': true, 'is-disabled': (!optin_1 || !optin_2)}">Submit</button>
							</div>
						</div>
						<div class="opt-in-box">
							<label class="opt-in custom-check">
								<input v-model="optin_1" type="checkbox">
								<span class="checkmark"></span>
								By providing us with your email address, you agree to allow Flexsteel Industries, Inc. to send you your saved results.
							</label>
							<label class="opt-in custom-check"><input v-model="optin_2" type="checkbox"><span class="checkmark"></span>By providing us with your email address or phone number, you agree to receive communications from Flexsteel Industries, Inc. ("Flexsteel") regarding promotions and product campaigns. By clicking the "submit" button, you agree to Flexsteel's <a @click.stop.prevent="$modal.show('terms')">Terms and Conditions</a> and <a @click.stop.prevent="$modal.show('privacy')">Privacy Policy</a>.</label>
						</div>
					</form>
				</div>
			</div>
			<div v-if="confirmEmail">
				<div class="modal-top">
					<a @click="hideEmail()" class="close-icon"><icon icon-type="icon-close"></icon></a>
					<div class="logo-container" v-if="$store.state.appCopy.storeLogo"><img :src="$store.state.appCopy.storeLogo" :alt="$store.state.appCopy.storeName"/></div>
					<h2>Your saved items were emailed to <span>{{this.email}}</span></h2>
					<p>Didn't get it?<br/>Our representatives are here to answer any questions you have and help complete your order.</p>
					<a @click="hideEmail()" class="close-button"><icon icon-type="icon-close"></icon> Close</a>
				</div>
			</div>

		</modal>
		<modal name="text-me" :width="modalWidth" :height="modalHeight" :class="{'confirmed': confirmText}" :adaptive="true" @opened="openedText" :pivotY="0.1">
			<div v-if="!confirmText">
				<div class="modal-top">
					<form autocomplete="off" v-on:submit.prevent="stopSubmit">
						<a @click="hideText()" class="close-icon"><icon icon-type="icon-close"></icon></a>
						<h2>We'll text your favorites</h2>
						<p>Enter your phone number to receive a text with your list of saved products with corresponding catalog pages for you to keep.</p>
						<div class="row">
							<div class='col-md-9'>
								<input autocomplete="off" id="number" :class="{'error': hasError}" v-model="number" ref="number" type="text" placeholder="phone number"/>
							</div>
							<div class='col-md-3'>
								<button @click="submit_text()" :class="{'btn-default': true, 'btn-submit': true, 'is-disabled': (!optin_1)}">Submit</button>
							</div>
						</div>

						<div class="opt-in-box text-only">
							<label class="opt-in custom-check">
								<input v-model="optin_1" type="checkbox">
								<span class="checkmark"></span>
								By providing us with your phone number, you agree to allow Flexsteel Industries, Inc. to send you your saved results.
							</label>
						</div>
					</form>
				</div>
			</div>
			<div v-if="confirmText">
				<div class="modal-top">
					<a @click="hideText()" class="close-icon"><icon icon-type="icon-close"></icon></a>
					<h2>Your saved items were texted to <span>{{this.number | phone}}</span></h2>
					<p>Didn't get it?<br/>Our representatives are here to answer any questions you have and help complete your order.</p>
					<a @click="hideText()" class="close-button"><icon icon-type="icon-close"></icon> Close</a>
				</div>
			</div>
		</modal>

		<modal name="optinDialog" class="optin-dialog" :width="modalWidth" :height="modalHeight" :adaptive="true" :pivotY="0.1">
			<h2>Error</h2>
			<p>{{alertText}}</p>
			<a @click="$modal.hide('optinDialog')" class="close-button"><icon icon-type="icon-close"></icon> Close</a>
		</modal>

		<modal name="terms" class="terms-dialog" :width="modalWidth" :height="modalHeight" :adaptive="true" :pivotY="0.1">
			<a @click="$modal.hide('terms')" class="close-icon"><icon icon-type="icon-close"></icon></a>
			<terms-and-conditions></terms-and-conditions>
		</modal>
		<modal name="privacy" class="terms-dialog" :width="modalWidth" :height="modalHeight" :adaptive="true" :pivotY="0.1">
			<a @click="$modal.hide('privacy')" class="close-icon"><icon icon-type="icon-close"></icon></a>
			<privacy-policy></privacy-policy>
		</modal>

		<bottom-nav></bottom-nav>
	</div>
</template>

<script>
	import BottomNav from '../components/BottomNav';
	import BackButton from '../components/BackButton';
	import SavedCartItem from '../components/SavedCartItem';
	import TermsAndConditions from '../components/TermsAndConditions';
	import PrivacyPolicy from '../components/PrivacyPolicy';
	import { mapGetters, mapActions } from 'vuex';
	import vueHeadful from 'vue-headful';
	import Icon from '../components/Icon';

	import 'swiper/dist/css/swiper.css';
	import { swiper, swiperSlide } from 'vue-awesome-swiper';
	import VueKeyboard from "../components/Keyboard";

	export default {
		name: 'saved',
		components: {
			bottomNav: BottomNav,
			backButton: BackButton,
			vueHeadful: vueHeadful,
			keyboard: VueKeyboard,
			termsAndConditions: TermsAndConditions,
			privacyPolicy: PrivacyPolicy,
			icon: Icon,
			swiper,
			swiperSlide,
			savedCartItem: SavedCartItem
		},
		filters: {
			phone: function(val){
				if(!val){return '';}
				return val.replace(/[^0-9]/g, '').replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
			}
		},
		data(){
			return{
				email: '',
				number: '',
				isSubmitting: false,
				formDirty: false,
				hasError: false,
				optin_1: true,
				optin_2: true,
				alertText: "",
				confirmText: false,
				confirmEmail: false,
				keyboardInput: null,
				textKeyboardInput: null,
				swiperOption: {
					slidesPerView: '4',
					simulateTouch: true,
					initialSlide: 1,
					spaceBetween: 10,
					centeredSlides: true,
					roundLengths: true,
					// width: 400,
					// height: 500,
					// autoHeight: true,
					slidesOffsetBefore: 0,
					slidesOffsetAfter: 0,
					slideToClickedSlide: false,
					navigation: {
						nextEl: '.btn-right',
						prevEl: '.btn-left'
					},
				},
			}
		},
		computed: {
			modalWidth(){
				if(this.isKiosk){
					return 1400;
				}else{
					if(this.windowWidth >= 500){
						return this.windowWidth - 100;
					}else{
						return this.windowWidth - 20;
					}
				}
			},
			modalHeight(){
				if(this.isKiosk){
					return 800;
				}else{
					if(this.windowHeight >= 700){
						return this.windowHeight - 100;
					}else{
						return this.windowHeight - 20;
					}
				}
			},
			isKiosk(){
				if(this.appId == "com.flexsteel.kiosk"){
					return true;
				}
				return false;
			},
			...mapGetters({
				products: 'cartProducts',
				lastRoute: 'getLastRoute'
			})
		},
		methods: {
			shareImageUpdated(image, sku){
				//share image has been updated. Change the image in the cart.
				this.updateCartById({sku: sku, shareUrl: image});
			},
			prefill_gmail(){
				this.email += "@gmail.com";
			},
			prefill_yahoo(){
				this.email += "@yahoo.com";
			},
			prefill_outlook(){
				this.email += "@outlook.com";
			},
			stopSubmit(){
				console.log("no auto submit");
				return false;
			},
			submit(){
				if(this.validateEmail(this.email)){
					this.hasError = false;
					if(this.optin_1 || this.optin_2){
						this.alertText = "";
						this.isSubmitting = true;
						let formData = new FormData()
						formData.append('action', "submitEmail");
						formData.append('email', this.email);
						formData.append('saved', JSON.stringify(this.products));
						formData.append('optin_1', this.optin_1);
						formData.append('optin_2', this.optin_2);
						if(this.isKiosk){
							formData.append('store_id', this.$store.state.storeID);
						}else{
							formData.append('store_code', this.$store.state.storeID);
						}

						this.$http.post(this.api_endpoint, formData, {
							headers: {
								'Content-Type': 'multipart/form-data'
							}
						}).then(response => {
							console.log(response);
							if(response.data.status == true){
								this.confirmEmail = true;
							}else{
								this.alertText = "Error Sending. Please wait a moment and try again.";
								this.$modal.show('optinDialog');
							}
							this.isSubmitting = false;
						}, err_response => {
							console.log("Error:", err_response);
							this.alertText = "Communication Error. Please wait a moment and try again.";
							this.$modal.show('optinDialog');
							this.isSubmitting = false;
						});
						this.$ua.trackEvent("Saved", "Submit", "Email");
					}else{
						this.alertText = "Please agree to at least one method of communications.";
						this.$modal.show('optinDialog');
					}
				}else{
					//email error
					this.hasError = true;
				}
			},
			submit_text(){
				if(this.validatePhone(this.number)){
					this.hasError = false;
					if(this.optin_1){
						this.alertText = "";
						this.isSubmitting = true;
						let formData = new FormData()
						formData.append('action', "submitText");
						formData.append('number', this.number);
						formData.append('saved', JSON.stringify(this.products));
						formData.append('optin_1', this.optin_1);
						formData.append('optin_2', this.optin_2);
						if(this.isKiosk){
							formData.append('store_id', this.$store.state.storeID);
						}else{
							formData.append('store_code', this.$store.state.storeID);
						}

						this.$http.post(this.api_endpoint, formData, {
							headers: {
								'Content-Type': 'multipart/form-data'
							}
						}).then(response => {
							console.log(response);
							if(response.data.status == true){
								this.confirmText = true;
							}else{
								this.alertText = "Error Sending. Please wait a moment and try again.";
								this.$modal.show('optinDialog');
							}
							this.isSubmitting = false;
						}, err_response => {
							console.log("Error:", err_response);
							this.alertText = "Communication Error. Please wait a moment and try again.";
							this.$modal.show('optinDialog');
							this.isSubmitting = false;
						});
						this.$ua.trackEvent("Saved", "Submit", "Text");
					}else{
						this.alertText = "Please agree to at least one method of communications.";
						this.$modal.show('optinDialog');
					}
				}else{
					//validation error
					this.hasError = true;
				}
			},
			showEmail(){
				this.email = "";
				this.$modal.show('email-me');
				this.$ua.trackEvent("Saved", "Click", "Email Your Items");
			},
			hideEmail(){
				this.$modal.hide('email-me');
				this.confirmEmail = false;
			},
			showText(){
				this.number = "";
				this.$modal.show('text-me');
				this.$ua.trackEvent("Saved", "Click", "Text Items to Your Phone");
			},
			hideText(){
				this.$modal.hide('text-me');
				this.confirmText = false;
			},
			showKeyboard(e){
				this.keyboardInput = e.target;
			},
			showTextKeyboard(e){
				this.textKeyboardInput = e.target;
			},
			openedEmail(){
				this.$refs.email.focus();
			},
			openedText(){
				this.$refs.number.focus();
			},
			validatePhone(phone){
				var phoneNum = phone.replace(/[^\d]/g, '');
				if(phoneNum.length > 6 && phoneNum.length < 11){
					return true;
				}else{
					return false;
				}
			},
			validateEmail(email){
				var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
				return re.test(email);
			},
			clearCartLocal(){
				this.clearCart();
				// this.$navigate("/");
			},
			...mapActions([
				'clearCart',
				'updateCartById'
			])
		},
		mounted(){
			// this.swiper.slideTo(0, 1000, false);
		}
	}
</script>

