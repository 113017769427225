<template>
	<div :class="'grid-item '+type" v-if="product && product.img">
		<div class="content">
			<!-- v-lazyload  -->
			<div v-if="!customization || !product.isFeatured" class="img-holder" @click="productDetails(product.sku)">
				<div v-if="product.isFeatured" class="has-spin"><i class="icon icon-spin"></i></div>
				<div v-if="product.soon === true" class="coming-soon"></div>
				<cached-image :src="product.img[0]"></cached-image>
			</div>
			<div v-if="customization && product.isFeatured" class="img-holder" @click="productDetails(product.sku)">
				<div v-if="product.isFeatured" class="has-spin"><i class="icon icon-spin"></i></div>
				<customizable-product-image :class="{'iframe': iframe}" :view="'grid '+type" :sku="product.sku" :type="product.type" :customization="customization"></customizable-product-image>
			</div>
			<h4 @click="productDetails(product.sku)">{{product.t}} {{product.s_t}}<span v-if="product.price"> {{$store.state.appCopy.pricingLabel}} {{product.price}}</span></h4>
			<div class="customization-area" v-if="customization && product.isFeatured">
				<span v-if="customization.FABRIC">Body: {{customization.FABRIC}}</span>{{renderPillows()}}
			</div>
			<div v-if="type == 'saved' && product.catalog" class="digital-catalog-launcher" @click="viewCatalogPage(product.sku)">
				<div v-if="!loadingCatalog">View Catalog Page</div>
				<div v-if="loadingCatalog">Loading...</div>
			</div>
			<saved-button class="saved-button" view="2" :sku="product.sku" :customization="customization"></saved-button>
		</div>
	</div>
</template>

<script>
import _ from 'lodash';
import SavedButton from '../components/SavedButton';
import CustomizableProductImage from '../components/CustomizableProductImage';
import CachedImage from '../components/CachedImage';
import { mapGetters, mapActions } from 'vuex';

import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
const iab = new InAppBrowser();

import { Plugins, FilesystemDirectory, Capacitor } from '@capacitor/core';
import { DocumentViewer } from '@ionic-native/document-viewer/ngx';
const { Filesystem } = Plugins;
import { HTTP } from '@ionic-native/http/ngx';
const http = new HTTP();


export default {
	name: 'ProductGridItem',
	components:{
		savedButton: SavedButton,
		customizableProductImage: CustomizableProductImage,
		cachedImage: CachedImage,
	},
	data(){
		return{
			loadingCatalog: false
		}
	},
	props: [
		'iframe',
		'type',
		'product',
		'customization'
	],
	computed: {
		isKiosk(){
			if(this.appId == "com.flexsteel.kiosk"){
				return true;
			}
			return false;
		},
	},
	methods: {
		renderPillows(){
			if(_.includes(this.product.customOptions, "PILLOW+1")){
				if(_.includes(this.product.customOptions, "PILLOW+2")){
					return ", Pillow 1: "+this.customization.PILLOW_1+", Pillow 2: "+this.customization.PILLOW_2;
				}else{
					return ", Pillow: "+this.customization.PILLOW_1;
				}
			}else{
				if(_.includes(this.product.customOptions, "PILLOW")){
					return ", Pillow: "+this.customization.PILLOW;
				}
			}
		},
		viewCatalogPage(sku){
			if(this.isKiosk){
				this.$modal.show('contentPlayer', {sku: sku});
				// this.iframeURL = "https://docs.google.com/gview?embedded=true&url="+url;
				// this.$modal.show('contentPlayer');
			}else{
				if(Capacitor.getPlatform() == "web"){
					let browser = iab.create("https://catalog.flexsteel.com/generate/?sku="+sku, "_system");
					// const browser = iab.create("https://catalog.flexsteel.com/generate/?sku="+sku, "_self", "location=yes,zoom=no,toolbarcolor=#15bdef,closebuttoncolor=#FFFFFF");
					// browser.on('loadstop').subscribe(event => {
					// 	// browser.insertCSS({ code: "html{zoom: 0.5;}" });
					// 	// browser.insertCSS({ code: "#ctl00_LeftMenuLoginView_ucLeftNav_divLeftMenu{display:none;}" });
					// });
				}else{
					try{
						this.loadingCatalog = true;
						var local = this;
						var fileName = sku+".pdf";
						var url = "https://catalog.flexsteel.com/generate/?sku="+sku;
						Filesystem.getUri({
							directory: FilesystemDirectory.Data,
							path: ''
						}).then((result) => {
							let targetPath = result.uri + '/' + fileName;
							http.downloadFile(url, null, null, targetPath).then((res) => {
								local.loadingCatalog = false;
								var options = {
									title: sku+" Catalog Page"
								}
								var document = new DocumentViewer();
								document.viewDocument(targetPath, 'application/pdf', options);

							}, (res) => {
								local.loadingCatalog = false;
								console.log('error downloading', res);
							});
						}).catch(e => {
							local.loadingCatalog = false;
							console.log(e);
							this.$modal.show('contentPlayer', {sku: sku});
							window.scroll({
								top: 0,
								left: 0,
								behavior: 'smooth'
							});
						});
					}catch(e){
						local.loadingCatalog = false;
						console.log(e);
						this.$modal.show('contentPlayer', {sku: sku});
						window.scroll({
							top: 0,
							left: 0,
							behavior: 'smooth'
						});
					}

				}
			}
		},
		productDetails(sku){
			this.$goToPDP(sku, this.customization);
		},
	}
};
</script>
