<template>
	<div class="getInspiredSection  max-full-page" :style="heightStyle">
		<vue-headful :title="item.name"/>
		<div class="tab-content row">
			<div class="large-device">
				<div class="left-side col-sm-12 col-lg-9" ref="leftSide">
					<div class="inner">
						<div class="image">
							<div class="content" ref="imagecontainer">
								<div class="highlights" v-if="item.highlights"  :style="imageContainerStyles">
									<highlight v-for="highlight in item.highlights" v-if="highlight.product" :item="highlight" :active="isShowingHighlight" @opened="opened" @closed="closed"></highlight>
								</div>
								<div v-if="hasPrev" class="btn-left" @click="goPrev()"><i class="icon-down-open-big"></i></div>
								<div v-if="hasNext" class="btn-right" @click="goNext()"><i class="icon-down-open-big"></i></div>

								<a @click='toggleCart()' v-if="hasProducts" class="btn-default has-icon add add-all" :class="{ isInCart: isAllInCart}"><span v-if="!isAllInCart"><icon icon-type="icon-add-white"></icon>ADD ALL TO SAVED&nbsp;</span><span v-if="isAllInCart"><icon icon-type="icon-remove-white"></icon>REMOVE ALL FROM SAVED&nbsp;</span></a>
								<img class="hero" :src="'static/img/inspiration/'+item.img" :alt="item.name">
							</div>
						</div>
					</div>
				</div>
				<div class="right-side col-sm-12 col-lg-3">
					<div class="inner">
						<h3>{{item.name}}</h3>
						<div class="grid row-eq-height" v-if="item.highlights">
							<product-grid-item class="col-xs-6 col-sm-6 col-md-6 col-lg-6 inspired" v-for="highlight in item.highlights" v-if="highlight.product" :product="highlight.product"></product-grid-item>
						</div>
					</div>
				</div>
			</div>
			<bottom-nav></bottom-nav>
		</div>
	</div>
</template>

<script>
	import _ from 'lodash';
	import BottomNav from '../components/BottomNav';
	import ProductGridItem from '../components/ProductGridItem';
	import Highlight from '../components/Highlight';
	import Icon from '../components/Icon';
	import { mapGetters, mapActions } from 'vuex';
	import vueHeadful from 'vue-headful';

	export default {
		name: 'GetInspirationSection',
		components: {
			bottomNav: BottomNav,
			highlight: Highlight,
			icon: Icon,
			vueHeadful: vueHeadful,
			productGridItem: ProductGridItem
		},
		data(){
			return{
				'isOpened': 0,
				'isMounted': false
			}
		},
		props: [
			'section'
		],
		computed: {
			heightStyle(){
				var styles = {
					'height': (this.windowHeight+'px')
				};
				if(this.windowWidth <= 700){
					styles = {};
				}
				return styles;
			},
			//scale content based on the viewport
			imageContainerStyles(){
				if(this.isMounted && !_.isNil(this.$refs.imagecontainer)){
					var leftSide = this.$refs.imagecontainer.clientWidth;
					var scale = leftSide / 1440;
					var marginTop = (980 - (this.windowHeight * scale)) / 4;
					marginTop = 0;
					if(this.windowWidth >= 700){
						var isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
						if(scale < 1){
							if(isFirefox){
								return {  'transform': 'scale('+scale+')', 'transform-origin': 'top left', 'margin-top': marginTop+'px', 'position': 'absolute'}
							}else{
								return {  zoom: scale, 'margin-top': marginTop+'px'}
							}
						}
					}
				}
				return {};
			},
			hasProducts(){
				if(!_.isNil(this.item.highlights)){
					for(let highlight in this.item.highlights){
						if(this.item.highlights[highlight].product !== false){
							return true;
						}		
					}
				}
				return false;
			},
			isShowingHighlight(){
				if(this.isOpened >= 1){
					return true;
				}else{
					return false;
				}
			},
			item(){
				return this.$store.getters.getInspirationSectionById(this.section);
			},
			isAllInCart(){
				if(!_.isNil(this.item.highlights)){
					for(let highlight in this.item.highlights){
						if(!this.isInCart(this.item.highlights[highlight].sku)){
							return false;
						}
					}
					return true;
				}
				return false;
			},
			hasNext(){
				let section = parseInt(this.section);
				if(section <= (this.inspiration.length-1)){
					return true;
				}else{
					return false;
				}
			},
			hasPrev(){
				let section = parseInt(this.section);
				if(section > 1){
					return true;
				}else{
					return false;
				}
			},
			...mapGetters([
				'cartProducts',
				'inspiration'
			])
		},
		methods: {
			opened(){
				this.isOpened++;
			},
			closed(){
				this.isOpened--;
			},
			goNext(){
				let nextSection = parseInt(this.section) + 1;
				this.$navigate('/get-inspired/section/'+nextSection);
			},
			goPrev(){
				let nextSection = parseInt(this.section) - 1;
				this.$navigate('/get-inspired/section/'+nextSection);
			},
			toggleCart(){
				if(!this.isAllInCart){
					for(let highlight in this.item.highlights){
						if(!this.isInCart(this.item.highlights[highlight].sku)){
							this.addToCartById({sku: this.item.highlights[highlight].sku});
						}
					}
					this.$ua.trackEvent('Get-Inspired-Section', 'Click', 'add-button', 'Add All');
				}else{
					for(let highlight in this.item.highlights){
						if(this.isInCart(this.item.highlights[highlight].sku)){
							this.removeFromCartById(this.item.highlights[highlight].sku);
						}
					}
					this.$ua.trackEvent('Get-Inspired-Section', 'Click', 'add-button', 'Remove All');
				}
			},
			isInCart(sku){
				let lookup = this.$store.getters.getInCartById(sku);
				if(!_.isNil(lookup) && lookup.quantity >= 1){
					return true;
				}else{
					return false;
				}
			},
			...mapActions([
				'addToCartById',
				'removeFromCartById'
			])
		},
		mounted(){
    		this.isMounted = true;
    	}
	}
</script>

