<template>
	<div class="setup-bar" v-if="needsSetup">
		<div class="row">
			<div class="col-md-4 align-right">
				Kiosk Setup Status:
			</div>
			<!-- <div class="col-md-4 setup-item">
				<span v-if="storeSelected"><icon icon-type="icon-check-alt"></icon> Store Selected</span>
				<span v-if="!storeSelected"><icon icon-type="icon-form-clear"></icon> Store Selected <store-chooser class="store-chooser"></store-chooser></span>
			</div> -->
			<div class="col-md-4 setup-item">
				<span v-if="isConnected"><icon icon-type="icon-check-alt"></icon> Network Activated</span>
				<span v-if="!isConnected"><icon icon-type="icon-form-clear"></icon> Network Offline <div class="btn-default" @click="openSettings()">Setup Wifi</div></span>
			</div>
		</div>
		<wifi-setup></wifi-setup>
	</div>
</template>

<script>
	import Vue from 'vue';
	import _ from 'lodash';
	import { mapGetters } from 'vuex';
	import Icon from '../components/Icon';
	import WifiSetup from '../components/WifiSetup';
	import StoreChooser from '../screens/StoreChooser';

	const { Plugins } = require('@capacitor/core');
	const { Network } = Plugins;

	export default {
		name: 'WifiSetup',
		components: {
			icon: Icon,
			wifiSetup: WifiSetup,
			storeChooser: StoreChooser
		},
		data(){
			return{
				'timer': '',
				'kioskloading': true,
				'loading': true,
				'connected': false
			}
		},
		computed: {
			storeSelected(){
				if(this.$store.state.storeID >= 1){
					return true;
				}else{
					return false;
				}
			},
			isConnected(){
				if(this.loading){
					return false;
				}
				return this.connected;
			},
			needsSetup(){
				//we dont show until the kiosk has been online for a bit
				if(this.kioskloading){
					return false;
				}
				//no connection
				if(!this.connected){
					return true;
				}
				return false;
			}
		},
		created(){
			Network.addListener('networkStatusChange', this.networkChanged);
		},

		mounted(){
			this.checkNetwork();
			setTimeout(this.setKioskLoaded, 10*1000);
		},
		methods: {
			startTimer(){
				this.timer = setInterval(this.checkNetwork, 30*1000);
			},
			networkChanged(status){
				this.connected = false;
				this.checkNetwork();
				this.startTimer();
				// this.$store.dispatch('getAppProducts');
			},
			setKioskLoaded(){
				this.kioskloading = false;
			},
			checkNetwork(){
				this.loading = true;
				let formData = new FormData()
				formData.append('action', "checkConnection");
				formData.append('store_id', this.$store.state.storeID);

				Vue.prototype.$http.post(Vue.prototype.api_endpoint, formData, {
					headers: {
						'Content-Type': 'multipart/form-data'
					}
				}).then(response => {
					this.loading = false;
					if(response.data.status == true){
						this.connected = true;
						clearInterval(this.timer);
					}
				}, err_response => {
					console.log("Error connecting:", err_response);
					this.loading = false;
					this.connected = false;
					this.startTimer();
				});
			},
			openSettings(){
				this.$modal.show('wifi-modal');
			},
		},
		destroyed(){
			Network.removeEventListener('networkStatusChange', this.networkChanged);
		}
	}
</script>
