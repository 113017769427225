<template>
	<div>
		<div class="bottom-nav tabs-block">
			<div class="tabset" placement="bottom"  id="bottom-nav">
				<li @click="clearBack();trackNav('RSA');" class="lockup"><router-link :to="'/rsa'"><img src="~@/assets/logo-lockup.svg" alt="Flexsteel Logo"/></router-link></li>
				<li @click="clearBack();trackNav('Home');" class="home"><router-link :to="'/landing'">Home</router-link></li>
				<li @click="clearBack();trackNav('Get Inspired');" class="get-inspired"><router-link :to="'/get-inspired/'">Get Inspired</router-link></li>
				<li @click="clearBack();trackNav('Create Your Vision');"><router-link :to="'/your-vision/'">Create Your Vision</router-link></li>
				<li @click="clearBack();trackNav('Browse All Products');" class="all-products"><router-link :to="'/all-products/'">Browse All Products</router-link></li>
				<li @click="clearBack();trackNav('No Saved Items');" v-if="itemsInCart == 0" class="none-saved"><router-link :to="'/saved/'"><span class="no-items"></span> No Saved Items</router-link></li>
				<li @click="clearBack();trackNav('View Saved Items');" v-if="itemsInCart >= 1" class="has-saved"><router-link :to="'/saved/'">View {{itemsInCart}} Saved Item<span v-if="itemsInCart >= 2">s</span></router-link></li>
			</div>
		</div>
		<mobile-nav/>
	</div>
</template>

<script>
import Vue from 'vue';
import {navbar} from 'vue-strap';
import MobileNav from '../components/MobileNav';
import _ from 'lodash';
import { mapGetters, mapActions } from 'vuex';

export default {
	name: 'BottomNav',
	data(){
		return{
		}
	},
	props: {
	},
	computed: {
		itemsInCart(){
			let cart = this.$store.getters.cartProducts;
			return cart.reduce((accum, item) => accum + item.quantity, 0);
		}
	},
	methods: {
		trackNav(section){

			this.$ua.trackEvent("Footer", "Click", section);
		},
		clearBack(){
			// Vue.nextTick(() =>{
				// console.log(document.title, this.$router.history.current.path);
				// this.setLastRoute({title: document.title, path: this.$router.history.current.path});
			// });
			this.$goingBack = false;
		},
		...mapActions([
			'setLastRoute'
		])

	},
	mounted(){
	},
	components: {
		'navbar': navbar,
		'mobile-nav': MobileNav
	}
};
</script>

