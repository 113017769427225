<template>
	<div class="row zero-gravity-container">
		<div class="col-sm-12 woman-container">
			<div class="row">
				<div class="col-sm-12  woman-row">
					<div class="inner-cell">
						<h1>You<br/>Deserve It</h1>
					</div>
				</div>
			</div>
		</div>
		<div class="col-sm-12 col-md-6 logo">
			<div class="inner-cell">
				<img src="~@/assets/zero/ZeroGravity_Logo-horizontal@2x.png" alt="Zero Gravity"/>
			</div>
		</div>
		<div class="col-sm-12 col-md-6 text-content">
			<div class="inner-cell">
				<h4>Recline. Relax. Repeat.</h4>
				<p>Along with the daily stresses of life, gravity takes a toll on your body. Flexsteel's Zero Gravity recline technology allows you to achieve the optimal neutral body position to reduce strain and tension and help you reach the perfect level of comfort.</p>
				<a href="#" class="btn-inline benefits has-icon" @click="showingBenefits=!showingBenefits">
					<span>
						<i class="icon " :class="{'icon-add-small': !showingBenefits, 'icon-remove-small': showingBenefits}"></i> Benefits
					</span>
				</a>
				<ul v-if="showingBenefits">
					<li><strong>Reduce Pressure</strong> Minimizes pressure and strain on the spine</li>
					<li><strong>Reduce Pain</strong> Relieves back pain and eases sore muscles</li>
					<li><strong>Reduce Swelling</strong> Improves circulation and soothes swelling</li>
					<li><strong>Amplify Breathing</strong> Assists with heart function and increases lung capacity</li>
				</ul>
			</div>
		</div>
		<div class="col-sm-12 enjoy-content">
			<div class="inner-cell">
				<h3>Enjoy Zero Gravity On These Flexsteel Models</h3>
			</div>
		</div>
	</div>
</template>

<script>
	import _ from 'lodash';
	import { mapGetters } from 'vuex';
	import Icon from '../components/Icon';

	export default {
		name: 'ZeroGravity',
		components: {
			icon: Icon,
		},
		data(){
			return{
				showingBenefits: false,
				'loading': false
			}
		},
		computed: {
		},
		mounted(){

		},
		methods: {
		}
	}
</script>

