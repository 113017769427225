import Vue from 'vue';
import Router from 'vue-router';
import { mapGetters, mapActions } from 'vuex';
// import Landing from '../screens/Landing';
// import PDP from '../screens/PDP';
import AsyncRoutes from './async-routes';

Vue.use(Router);

const scrollBehavior = (to, from, savedPosition) => {
	return { x: 0, y: 0 }
};

const router = new Router({
	scrollBehavior,
	routes: [
		{
			path: '/landing',
			name: 'landing',
			meta: {analytics: 'Landing'},
			component: AsyncRoutes.Landing
		},{
			path: '/rsa',
			name: 'rsa',
			meta: {analytics: 'RSA'},
			component: require('@/screens/RSA').default
		},{
			path: '/your-vision',
			name: 'your-vision',
			meta: {analytics: 'Your Vision'},
			props: true,
			component: require('@/screens/Vision').default
		},{
			path: '/your-vision/grid/:type',
			name: 'your-vision-grid',
			meta: {analytics: 'Your Vision - Grid'},
			props: true,
			component: require('@/screens/VisionGrid').default
		},{
			path: '/your-vision/sku/:sku',
			name: 'your-vision-pdp',
			meta: {analytics: 'Your Vision - PDP'},
			props: true,
			component: AsyncRoutes.PDP
		},{
			path: '/saved',
			name: 'saved',
			meta: {analytics: 'Saved'},
			component: require('@/screens/Saved').default
		},{
			path: '/flx',
			name: 'flx',
			meta: {analytics: 'FLX'},
			component: require('@/screens/FLX').default
		},{
			path: '/all-products',
			name: 'all-products',
			meta: {analytics: 'Browse All Products'},
			component: require('@/screens/AllProducts').default
		},{
			path: '/all-products/area/:basearea',
			name: 'all-products-area',
			props: true,
			meta: {analytics: 'Browse All Products - Area'},
			component: require('@/screens/AllProducts').default
		},{
			path: '/all-products/grid/:area',
			name: 'all-products-grid',
			props: true,
			meta: {analytics: 'Browse All Products - Grid'},
			component: require('@/screens/AllProductsGrid').default
		},{
			path: '/all-products/sku/:sku',
			name: 'all-products-pdp',
			props: true,
			meta: {analytics: 'Browse All Products - PDP', usePathAnalytics: true},
			component: AsyncRoutes.PDP
		},{
			path: '/get-inspired',
			name: 'get-inspired',
			meta: {analytics: 'Get Inspired'},
			component: require('@/screens/GetInspired').default
		},{
			path: '/get-inspired/section/:section',
			name: 'get-inspired-section',
			props: true,
			meta: {analytics: 'Get Inspired - Section'},
			component: require('@/screens/GetInspiredSection').default
		},{
			path: '/screensaver',
			name: 'screensaver',
			meta: {analytics: 'Ambient Mode'},
			component: require('@/screens/ScreenSaver').default
		},{
			path: '*',
			redirect: '/screensaver'
		}
	]
});

export default router;
