<template>
	<div class="allProducts full-page max-full-page" :style="{'height': windowHeight+'px'}">
		<vue-headful :title="back_title"/>
		<div class="tab-content">
			<back-button v-if="type == 2"></back-button>
			<h1>{{title}}</h1>
			<div class="row">
				<div class="grid col-sm-12">
					<div class="inside">
						<div class="search-container">
							<a v-if="!searchKeyword" class="search-box" @click="$modal.show('search-modal')"><icon icon-type="icon-search-big"></icon> <span>Enter Group or SKU #</span></a>
							<a v-if="searchKeyword" class="search-box" @click="$modal.show('search-modal')"><icon icon-type="icon-search-big"></icon> <span>{{searchKeyword}}</span></a>
						</div>
						<div class="top row">
							<div :class="'area '+column" v-for="area in areas" @click="trackClick(area)" v-if="hasArea(area)">
								<area-grid-item  :area="area" :type="type"></area-grid-item>
							</div>
						</div>
					</div>
				</div>
			</div>

			<search-modal :searchKeyword="searchKeyword" v-on:update-search="updateSearch"></search-modal>
			<bottom-nav></bottom-nav>
		</div>
	</div>
</template>

<script>
	import _ from 'lodash';
	import BottomNav from '../components/BottomNav';
	import BackButton from '../components/BackButton';
	import AreaGridItem from '../components/AreaGridItem';
	import SearchModal from '../components/SearchModal';
	import Icon from '../components/Icon';
	import { mapActions, mapGetters } from 'vuex';
	import vueHeadful from 'vue-headful';

	export default {
		name: 'AllProducts',
		components: {
			bottomNav: BottomNav,
			searchModal: SearchModal,
			icon: Icon,
			backButton: BackButton,
			vueHeadful: vueHeadful,
			areaGridItem: AreaGridItem
		},
		data(){
			return{
				'searchKeyword': ''
			}
		},
		props: [
			'basearea'
		],
		computed: {
			title(){
				if(!_.isNil(this.basearea)){
					return this.basearea;
				}else{
					return "Browse by Area";
				}
			},
			back_title(){
				if(!_.isNil(this.basearea)){
					return this.basearea;
				}else{
					return "All Products";
				}
			},
			column(){
				if(this.type == 1){
					return "col-sm-6";
				}else{
					return "col-sm-3";
				}
			},
			type(){
				if(!_.isNil(this.basearea)){
					return 2;
				}else{
					return 1;
				}
			},
			areas(){
				if(this.type == 1){
					return this.allAreas;
				}else{
					return this.$store.getters.getProductAreasByType(this.basearea);
				}
			},
			...mapGetters({
				allProducts: 'allProducts',
				allAreas: 'getProductAreas',
				lastRoute: 'getLastRoute'
			})
		},
		activated(){
			this.setSearchKeyword("");
		},

		methods: {
			//ensure the product exists in the data feed
			hasArea(area){
				if(this.type == 1){
					return true;	
				}
				var res = false;
				this.allProducts.filter(function(product){
					if(_.includes(product["areas"], area.name)){
						res = true;
					}
				});
				return res;
			},
			updateSearch(newSearch){
				this.searchKeyword = newSearch;
			},
			trackClick(area){
				if(this.type == 1){
					this.$ua.trackEvent('Browse-All-Main', 'Click', area.name);
				}else{
					this.$ua.trackEvent('Browse-All-Area', 'Click', area.name);
				}
			},
			...mapActions([
				'setSearchKeyword',
			])
		}
	}
</script>

