<template>
	<div>
		<div class="col tile-col tile-col-alt">
			<strong class="title"><slot></slot><span>{{fabricsLength}} results</span></strong>
		</div>
		<div class="col tile-col tile-col-alt">
			<div class="btn-left" ref="swiperContainerBack" @click="scrollTileLeft('swiperContainer')" slot="button-prev"><div class="center"><i class="icon-down-open-big"></i></div></div>
			<div class="btn-right" @click="scrollTileRight('swiperContainer')" slot="button-next"><div class="center"><i class="icon-down-open-big"></i></div></div>
			<div class="tile-container" :style="containerStyles" ref="swiperContainer" v-on:scroll="showLeftTileScroll('swiperContainer')">
				<div class="tiles" :style="tilesStyles">
					<div class="item" v-for="fabric in fabrics" :key="fabric.id" @click="setTile(fabric.id)">
						<customizable-tile :tile="fabric" :checked="customization[drapeType] == fabric.id" :type="drapeType" height="79" width="79"></customizable-tile>
					</div>
				</div>
			</div>
		</div>

	</div>
</template>

<script>
import _ from 'lodash';
import { mapGetters, mapActions } from 'vuex';
import CustomizableTile from '../components/CustomizableTile';
import 'swiper/dist/css/swiper.css';
import { swiper, swiperSlide } from 'vue-awesome-swiper';
import dropdown from '../components/Dropdown';

//for products that are not ready for the new format
export default {
	name: 'CoverChooserArchive',
	data(){
		return{
			tileWidth: 84,
			tileHeight: 84,
			activeFilters: [],
			swiperOption: {
				slidesPerView: 'auto',
				simulateTouch: true,
				slidesPerGroup: 2,
				slidesPerColumn: 2,
				spaceBetween: 5,
				slidesOffsetBefore: 58,
				slidesOffsetAfter: 58,
				slideToClickedSlide: false,
				navigation: {
					nextEl: '.btn-right',
					prevEl: '.btn-left'
				},
			},

		}
	},
	components: {
		swiper,
		swiperSlide,
		dropdown,
		customizableTile: CustomizableTile,
	},
	props: [
		'drapeType',
		'rows',
		'product',
		'customization',
		'fabrics'
	],
	computed: {
		containerStyles(){
			return "";
			return "height: "+(this.rows*this.tileHeight)+"px";
		},
		tilesStyles(){
			return "width: "+((this.fabricsLength*this.tileWidth)/this.rows+100)+"px";
		},
		fabricsLength(){
			return this.fabrics.length;
		},
		...mapGetters({
			lastRoute: 'getLastRoute',
			allRoutes: 'getAllRoutes'
		})
	},
	methods: {
		setTile(id){
			this.$emit('setTile', {"type": this.drapeType, id});
		},
		toggleOpenFilter(index){
			this.$refs['toggle-'+index][0].toggle();
		},
		scrollTileLeft(ref){
			let container = this.$refs[ref];
			container.scroll({
				top: 0,
				left: container.scrollLeft-450,
				behavior: 'smooth'
			});
		},
		scrollTileRight(ref){
			let container = this.$refs[ref];
			container.scroll({
				top: 0,
				left: container.scrollLeft+450,
				behavior: 'smooth'
			});
		},
		showLeftTileScroll(ref){
			let container = this.$refs[ref];
			let backButton = this.$refs[ref+"Back"];
			if(container.scrollLeft == 0){
				backButton.style.display = "none";
			}else{
				backButton.style.display = "block";
			}
		},
		...mapActions([
			'popRoute'
		])
	}
};
</script>

