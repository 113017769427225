<template>
	<div>
		<div class="col tile-col tile-col-alt">
			<strong class="title">
				<slot></slot>
				<span>{{fabricsLength}} results</span>
			</strong>
		</div>
		<div class="col tile-col tile-col-alt">
			<div class="row filter-holder">
				<div class="search-container">
					<form>
						<a class="search-box" :data-state="searchState">
							<input @blur="searchState='closed'" id="searchInput" autocomplete="off" v-model="keyword" ref="keyword" type="text" placeholder="Enter Fabric SKU" :class="{'error': fabricsLength === 0}"/>
							<span @click="toggleSearch" class="search-icon-holder"><icon icon-type="icon-search-big"></icon><span> Search</span></span>
						</a>
					</form>
				</div>
				<div class="col-sm-3 filter-column" v-for="(facet, index) in filteredItems.data.aggregations" :key="facet.name">
					<dropdown :ref="'toggle-'+index">
						<div slot="button">
							<button class="btn dropdown-toggle" type="button" data-toggle="dropdown" v-on:click="toggleOpenFilter(index)" >
								{{facet.title}} <span class="caret"></span>
							</button>
						</div>
						<div slot="body" class="dropdown-menu" :class="{'open-left': (facet.title == 'Fabric Grade' || facet.title == 'Wearability')}">
							<div class="row">
								<div class="column " :class="{'col-sm-6 col-md-6 col-lg-4': (facet.title == 'Material Type' || facet.title == 'Color Family'), 'col-sm-6': (facet.title == 'Fabric Grade' || facet.title == 'Wearability')}" v-for="bucket in facet.buckets" :key="bucket.key">
									<div class="option">
										<div @click="setFilter(facet.name, bucket.key)" :class="{'active': itemJsFilters[facet.name].includes(bucket.key)}">
											<span class="check"></span>
											<div class="filter-title"><span :class="machineName(facet, bucket.key)"></span> {{mappedLabel(facet, bucket.key)}}</div>
											<div class="clear"></div>
										</div>
									</div>
								</div>
							</div>
							<div class="row">
								<div class="col-sm-12">
									<a href="#apply-filter" @click.prevent="toggleOpenFilter(index)" class="apply">APPLY</a>
								</div>
							</div>
						</div>
					</dropdown>
				</div>
			</div>
		</div>
		<div class="col tile-col tile-col-alt">
			<div class="row tag-holder">
				<div class="col-sm-12">
					<span v-for="(i, key) in itemJsFilters">
						<span v-for="type in i">
							<div class='tag has-icon' @click="setFilter(key, type)"><svg viewPort="0 0 9 9" version="1.1" xmlns="http://www.w3.org/2000/svg"><line x1="1" y1="9" x2="9" y2="1" stroke="#313d54" stroke-width="1"/><line x1="1" y1="1" x2="9" y2="9" stroke="#313d54" stroke-width="1"/></svg>{{type}}</div>
						</span>
					</span>
					<span v-if="keyword" >
						<div class='tag has-icon' @click="keyword=''"><svg viewPort="0 0 9 9" version="1.1" xmlns="http://www.w3.org/2000/svg"><line x1="1" y1="9" x2="9" y2="1" stroke="#313d54" stroke-width="1"/><line x1="1" y1="1" x2="9" y2="9" stroke="#313d54" stroke-width="1"/></svg>Search "{{keyword}}"</div>
					</span>
				</div>
			</div>
		</div>
		<div class="col tile-col tile-col-alt">
			<div class="btn-left" ref="swiperContainerBack" @click="scrollTileLeft('swiperContainer')" slot="button-prev"><div class="center"><i class="icon-down-open-big"></i></div></div>
			<div class="btn-right" @click="scrollTileRight('swiperContainer')" slot="button-next"><div class="center"><i class="icon-down-open-big"></i></div></div>
			<div class="tile-container" :style="containerStyles" ref="swiperContainer" v-on:scroll="showLeftTileScroll('swiperContainer')">
				<div class="tiles" :style="tilesStyles">
					<div class="item" v-for="fabric in filteredItems.data.items" :key="fabric.id" @click="setTile(fabric.id)">
						<label class="custom-tile" :class="{'isChecked': customization[drapeType] == fabric.id, 'custom-tile': true, 'width-79': true}">
							<input type="radio" :name="'radio-'+drapeType" :checked="customization[drapeType] == fabric.id">
							<span class="img" v-lazyload>
								<img :data-url="thumbnail(fabric.img)"  height="79" :width="79" :alt="fabric.id">
							</span>
							<div class="inner-tile">
								<div class="inner-tile-inner">
									<div class="icon"><div class="inner-icon icon-check-alt"></div></div>
									<div class="tile-id">{{fabric.id}}</div>
								</div>
							</div>
						</label>
					</div>
				</div>
			</div>
		</div>

	</div>
</template>

<script>
//
//
// </div>


import _ from 'lodash';
import { mapGetters, mapActions } from 'vuex';
import CustomizableTile from '../components/CustomizableTile';
import 'swiper/dist/css/swiper.css';
import { swiper, swiperSlide } from 'vue-awesome-swiper';
import dropdown from '../components/Dropdown';
import Icon from '../components/Icon';
import VirtualList from 'vue-virtual-scroll-list';
import Itemsjs from 'itemsjs';

var configuration = {
	searchableFields: ['id', 'idAlt', 'ColorDescr', 'PatternDescr', 'Grade', 'WearCd'],
	sortings: {
		name_asc: {
			field: 'name',
			order: 'asc'
		}
	},
	aggregations: {
		PatternDescr: {
			title: 'Material Type',
			sort: 'term',
			conjunction: false,//OR
			size: 100
		},
		ColorDescr: {
			title: 'Color Family',
			sort: 'term',
			conjunction: false,//OR
			size: 100
		},
		Grade: {
			title: 'Fabric Grade',
			sort: 'term',
			conjunction: false,//OR
			size: 100
		},
		WearCd: {
			title: 'Wearability',
			sort: 'term',
			conjunction: false,//OR
			size: 100
		}
	}
}


export default {
	name: 'CoverChooser',
	data(){
		var itemJsFilters = {};
		Object.keys(configuration.aggregations).map(function(v){
			itemJsFilters[v] = [];
		});

		return{
			'keyword': '',
			'searchState': 'closed',
			'itemJsFilters': itemJsFilters,
			tileWidth: 84,
			tileHeight: 84,
			pageNum: 1,
			numPerPage: 30,
			activeFilters: [],
			swiperOption: {
				slidesPerView: 'auto',
				simulateTouch: true,
				slidesPerGroup: 2,
				slidesPerColumn: 2,
				spaceBetween: 5,
				slidesOffsetBefore: 58,
				slidesOffsetAfter: 58,
				slideToClickedSlide: false,
				navigation: {
					nextEl: '.btn-right',
					prevEl: '.btn-left'
				},
			},

		}
	},
	components: {
		swiper,
		swiperSlide,
		dropdown,
		icon: Icon,
		'virtual-list': VirtualList,
		customizableTile: CustomizableTile,
	},
	props: [
		'drapeType',
		'rows',
		'product',
		'customization',
		'fabrics'
	],
	computed: {
		filteredItems(){
			var result = this.items.search({
				page: 1,
				per_page: 100000,
				query: this.keyword,
				filters: this.itemJsFilters
			});
			return result;
		},
		items(){
			return Itemsjs(this.fabrics, configuration);
		},
		containerStyles(){
			return "";
			return "height: "+(this.rows*this.tileHeight)+"px";
		},
		tilesStyles(){
			return "width: "+((this.fabricsLength*this.tileWidth)/this.rows+100)+"px";
		},
		fabricsLength(){
			return this.filteredItems.pagination.total;
			let local = this;
			let count = 0;
			_.forEach(this.fabrics, function(fabric, key){
				count++;
			});
			return count;
		},
		pagedFabrics(){
			// let prods = this.filteredProducts();
			let items = _.cloneDeep(this.filteredItems.data.items, true);
			return items.splice(0, this.numPerPage*this.pageNum);
		},

		...mapGetters({
			lastRoute: 'getLastRoute',
			allRoutes: 'getAllRoutes'
		})
	},
	methods: {
		nextPage(){
			this.pageNum++;
		},
		thumbnail(url){
			return url.replace("w_1000,", "w_100,").replace("h_1000,", "h_100,");
		},
		toggleSearch(){
			if(this.searchState === 'closed'){
				this.searchState = 'open';
				this.$refs.keyword.focus();
			}else{
				this.searchState = 'closed';
			}
		},
		setTile(id){
			this.$emit('setTile', {"type": this.drapeType, id});
		},
		toggleOpenFilter(index){
			this.$refs['toggle-'+index][0].toggle();
		},
		setFilter(type, val){
			if(!_.includes(this.itemJsFilters[type], val)){
				this.itemJsFilters[type].push(val);
			}else{
				_.pull(this.itemJsFilters[type], val);
			}
			//hack to force reactivity
			this.itemJsFilters[type].splice(10000);
			// this.$forceUpdate();
			return true;
		},
		//change what we display to consumers vs what comes in the API
		mappedLabel(facet, item){
			if(facet.name == "PatternDescr"){
				if(item == "Floral/Leaf Pattern"){return "Pattern";}
			}
			return item;
		},
		//something that we can reference via CSS
		machineName(facet, name){
			var facetName = facet.name.replace(/[!\"#$%&'\(\)\*\+,\.\/:;<=>\?\@\[\\\]\^`\{\|\}~]/g, '').toLowerCase().split(" ").join("-");
			name = name.replace(/[!\"#$%&'\(\)\*\+,\.\/:;<=>\?\@\[\\\]\^`\{\|\}~]/g, '').toLowerCase().split(" ").join("-");
			return "filter-icon "+facetName+" "+name;
		},
		scrollTileLeft(ref){
			let container = this.$refs[ref];
			container.scroll({
				top: 0,
				left: container.scrollLeft-450,
				behavior: 'smooth'
			});
		},
		scrollTileRight(ref){
			let container = this.$refs[ref];
			container.scroll({
				top: 0,
				left: container.scrollLeft+450,
				behavior: 'smooth'
			});
		},
		showLeftTileScroll(ref){
			let container = this.$refs[ref];
			let backButton = this.$refs[ref+"Back"];
			if(container.scrollLeft == 0){
				backButton.style.display = "none";
			}else{
				backButton.style.display = "block";
			}
		},
		...mapActions([
			'popRoute'
		])
	}
};
</script>

