<template>
	<div :class="{'inventory-modal': true}">
		<modal name="inventoryToggle" class="inventory-toggle" width="100%" :adaptive="true" height="100%">
			<a @click="$modal.hide('inventoryToggle')" class="close-icon"><icon icon-type="icon-close"></icon></a>
			<div class="inventory-holder" v-if="canShowInventory">
				<div class="inventory" v-if="product && product.inventory">
					<div v-if="product.inventory.length >= 1 && $store.state.appCopy.storeWarehouse != ''">
						<h4>Inventory as of <span v-html="product.inventory_u"></span>:</h4>
						<div class="item row header-row">
							<div class="cover col-md-4" v-if="product.inventory[0] && product.inventory[0].cover"><span>Cover</span></div>
							<div class="plant col-md-4"><span>Plant</span></div>
							<div class="stock col-md-4"><span>Stock</span></div>
						</div>
						<div class="item row" v-for="item in product.inventory">
							<span v-if="hasWarehouse(item.plant)">
								<div class="cover col-md-4" v-if="product.inventory[0] && product.inventory[0].cover">{{item.cover}}</div>
								<div class="plant col-md-4">{{item.plant}}</div>
								<div class="stock col-md-4">{{item.stock}}</div>
							</span>
						</div>
					</div>
					<div v-if="product.inventory.length == 0">
						<h3>Inventory is not available for {{product.sku}}</h3>
					</div>
					<div v-if="!$store.state.appCopy.storeWarehouse || $store.state.appCopy.storeWarehouse == ''">
						<h3>Kiosk setup is not complete</h3>
						<p>Please contact your sales rep to have them configure your kiosk's stock warehouse.</p>
					</div>
				</div>
			</div>
			<div class="inventory-holder" v-if="!canShowInventory">
				<div class="inventory">
					<h3>Inventory Not Available</h3>
					<p>{{product.sku}} cannot display inventory. Only Latitudes, Wynwood or Casegoods products have inventory available to display on this kiosk.</p>
					<div class="collections">Groups: {{product.collections}}</div>

				</div>
			</div>
		</modal>
	</div>
</template>

<script>
import Vue from 'vue';
import _ from 'lodash';
import Icon from '../components/Icon';
import { mapGetters, mapActions } from 'vuex';

export default {
	name: 'InventoryViewer',
	data(){
		return{
			'stores': []
		}
	},
	props: [
		'product'
	],
	computed: {
		canShowInventory(){
			if(this.product.inv === "1"){
				return true;
			}else{
				return false;
			}
		},
	},
	methods: {
		hasWarehouse(plant){
			if(_.includes(this.$store.state.appCopy.storeWarehouse, plant)){
				return true;
			}
			return false;
		},
		...mapActions([
			'clearToken',
			'setStore',
			'setLastRoute'
		])

	},
	mounted(){
	},
	components: {
		icon: Icon
	}
};

</script>
