<template>
	<vue100vh class="max-full-page" :class="{'thirtyGrid': true}">
		<vue-headful :title="pageTitle"/>
		<back-button></back-button>
		<div class="product-view">
			<!-- <a class="opener btn-default add-shadow" @click="toggleChooser()">Change All Fabrics &amp; Pillows</a> -->
			<div class="chooser-content row" v-if="chooserStatus">
				<a @click="toggleChooser()" class="close-icon"><icon icon-type="icon-close"></icon></a>
				<div class="title-area col-sm-2">
					<h2>Tap fabric &amp; pillows to see how they look.</h2>
					<p>Hundreds more available at the fabric wall, just ask a store associate.</p>
				</div>
				<div class="detail col-sm-10">
					<div class="row">
						<div class="col col-sm-6">
							<strong class="title">Performance Body Fabrics  <span>({{fabrics.length}})</span></strong>
						</div>
						<div class="col col-sm-5">
							<strong class="title">Accent Pillow Fabrics <span>({{fabrics.length}})</span></strong>
						</div>
					</div>
					<div class="row">
						<div class="col col-sm-6 chooser-container">
							<div class="btn-left" ref="swiperFabricBack" @click="scrollTileLeft('swiperFabric')" slot="button-prev"><i class="icon-down-open-big"></i></div>
							<div class="btn-right" @click="scrollTileRight('swiperFabric')"  slot="button-next"><i class="icon-down-open-big"></i></div>
							<div class="chooser-items tile-container body-fabric" ref="swiperFabric" v-on:scroll="showLeftTileScroll('swiperFabric')">
								<div class="tiles">
									<div class="item tile" v-for="fabric in fabrics" @click="setTile('FABRIC', fabric.id)">
										<customizable-tile :tile="fabric" :checked="customization.FABRIC == fabric.id" type="FABRIC" height="90" width="80" v-on:select="setTile" :chosen="customization.FABRIC"></customizable-tile>
									</div>
								</div>
							</div>
						</div>
						<div class="col col-sm-5 chooser-container">
							<div class="btn-left" ref="swiperPillowBack" @click="scrollTileLeft('swiperPillow')" slot="button-prev"><i class="icon-down-open-big"></i></div>
							<div class="btn-right" @click="scrollTileRight('swiperPillow')"  slot="button-next"><i class="icon-down-open-big"></i></div>
							<div class="chooser-items tile-container pillow-fabric" ref="swiperPillow" v-on:scroll="showLeftTileScroll('swiperPillow')">
								<div class="tiles">
									<div class="item tile" v-for="pillow in fabrics" @click="setTile('PILLOW', pillow.id)">
										<customizable-tile :tile="pillow" :checked="customization.PILLOW == pillow.id" type="PILLOW" height="90" width="80" v-on:select="setTile" :chosen="customization.PILLOW"></customizable-tile>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<article  ref="visionGrid" class="article row vision-grid">
			<h2>{{pageTitle}}</h2>
			<div class="products-col col-sm-12">
				<div :class="'row products-list '+type">
					<div class="col-sm-3 product-list-item" v-for="product in featured" :key="product.sku" @click="$ua.trackEvent('30in30-Grid', 'Click', product.sku);">
						<div class="inner">
							<div class="content">
								<div class="image " v-if="loaded">
									<customizable-product-image :round="product.round" :sku="product.sku" view="thirty-grid" :type="product.type" :customization="customization"></customizable-product-image>
								</div>
								<div class="product-name" v-if="product.t">{{product.t}}</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- <div class="arrows" v-if="isKiosk && (type != 'recliner' && type != 'sectional')">
				<a class="btn-up smooth" @click="pageUp"><i class="icon-up-open-big"></i></a>
				<a class="btn-down smooth" @click="pageDown"><i class="icon-down-open-big"></i></a>
			</div> -->

		</article>
		<bottom-nav ref="bottomNav"></bottom-nav>
	</vue100vh>
</template>

<script>
	import BottomNav from '../components/BottomNav';
	import SavedButton from '../components/SavedButton';
	import BackButton from '../components/BackButton';
	import CustomizableTile from '../components/CustomizableTile';
	import CustomizableProductImage from '../components/CustomizableProductImage';
	import VueScrollTo from 'vue-scrollto';
	import vue100vh from 'vue-100vh';
	import Icon from '../components/Icon';
	import _ from 'lodash';

	import vueHeadful from 'vue-headful';
	import { mapGetters, mapActions } from 'vuex';

	export default {
		name: 'landing-page',
		data(){
			return{
				loaded: false,
				'pageTitle': '',
				'customization': {
					"BODY": '959-X',
					"BODY 2": '959-X',
					"FABRIC": '959-X',
					"UPHOLSTERY": '959-X',
					// "LEGS", this.customization.LEGS,
					// "LEG FINISH", this.customization.LEGS,
					// "PILLOW", this.customization.PILLOW_1,
					// "PILLOWS", this.customization.PILLOW_1,
					"PILLOW GROUP 1": '011-22',
					"PILLOW GROUP 2": '011-22',
					'PILLOW': '011-22',
					'PILLOW_1': '011-22',
					'PILLOW_2': '011-22'
				},
				chooserStatus: false
			}
		},
		directives: {
			scrollTo: VueScrollTo
		},
		props: [
			'type'
		],
		components: {
			bottomNav: BottomNav,
			backButton: BackButton,
			icon: Icon,
			customizableTile: CustomizableTile,
			customizableProductImage: CustomizableProductImage,
			vueHeadful: vueHeadful,
			savedButton: SavedButton,
			vue100vh
		},
		computed: {
			isKiosk(){
				if(this.appId == "com.flexsteel.kiosk"){
					return true;
				}
				return false;
			},
			featured(){
				let items = [];
				if(this.type == "chair"){
					this.pageTitle = 'Viewing Chairs';
					items = this.$store.getters.getFeaturedByType("Chair");
				}
				if(this.type == "ottoman" || this.type == "ottoman_hidden"){
					this.pageTitle = 'Viewing Ottomans';
					items = this.$store.getters.getFeaturedByType("Ottoman");
				}
				if(this.type == "recliner"){
					this.pageTitle = 'Viewing Recliners';
					items = this.$store.getters.getFeaturedByType("Recliner");
				}
				if(this.type == "sectional"){
					this.pageTitle = 'Viewing Sectionals';
					items = this.$store.getters.getFeaturedByType("Sectional");
				}
				if(this.type == "sofa"){
					this.pageTitle = 'Viewing Sofas';
					items = this.$store.getters.getFeaturedByType("Sofa");
				}
				if(this.type == "loveseat"){
					this.pageTitle = 'Viewing Loveseats';
					items = this.$store.getters.getFeaturedByType("Loveseat");
				}
				items = _.sortBy(items, "t");
				return items;
			},
			...mapGetters({
				defaultFabrics: 'customizableDefaultFabrics',
				fabrics: 'customizableFabrics',
				pillows: 'customizablePillows'
			})
		},
		methods: {
			pageDown(){
				let container = this.$refs.visionGrid;
				console.log(container.scrollTop);
				container.scroll({
					top: container.scrollTop+window.innerHeight*.9,
					left: 0,
					behavior: 'smooth'
				});
			},
			pageUp(){
				let container = this.$refs.visionGrid;
				container.scroll({
					top: container.scrollTop-window.innerHeight*.9,
					left: 0,
					behavior: 'smooth'
				});
			},
			scrollTileLeft(ref){
				let container = this.$refs[ref];
				container.scroll({
					top: 0,
					left: container.scrollLeft-450,
					behavior: 'smooth'
				});
			},
			scrollTileRight(ref){
				let container = this.$refs[ref];
				container.scroll({
					top: 0,
					left: container.scrollLeft+450,
					behavior: 'smooth'
				});
			},
			showLeftTileScroll(ref){
				let container = this.$refs[ref];
				let backButton = this.$refs[ref+"Back"];
				if(container.scrollLeft == 0){
					backButton.style.display = "none";
				}else{
					backButton.style.display = "block";
				}
			},
			setTile(type, id){
				this.customization[type] = id;
				if(type == "PILLOW"){
					this.customization["PILLOW_1"] = id;
					this.customization["PILLOW_2"] = id;
				}
				if(type == "FABRIC"){
					this.customization["BODY 2"] = id;
				}
			},
			productDetails(sku){
				this.$navigate('/thirty-in-thirty/sku/'+sku);
			},
			toggleChooser(){
				// console.log(this.$refs.bottomNav.$children[0].offsetHeight);
				if(this.chooserStatus == true){
					this.chooserStatus = false;
				}else{
					this.chooserStatus = true;
				}
			}
		},
		mounted(){
			// this.$nextTick(function(){
				//set the default customization
				this.customization.BODY = this.defaultFabrics[0] || '011-22';
				this.customization["BODY 2"] = this.defaultFabrics[0] || '011-22';
				this.customization.FABRIC = this.defaultFabrics[0] || '011-22';
				this.customization.PILLOW_1 = this.defaultFabrics[1] || '108-40';
				this.customization.PILLOW_2 = this.defaultFabrics[2] || '108-70';
				console.log(this.customization);
				this.loaded = true;
			// });
		}

	}
</script>

