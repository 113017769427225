<template>
	<div class="max-full-page" :class="{'rsa': true}" ref="rsa" :style="{'height': windowHeight+'px'}">
		<vue-headful title="RSA"/>
		<back-button></back-button>
		<a v-if="showSecret" @click='updateProducts()' class="btn-inline has-icon product-reload"><span><icon icon-type="icon-reload"></icon>{{refreshText}}</span></a>
		<a v-if="showSecret" @click='openSettings("wifi")' class="btn-inline open-settings wifi"><span>WIFI Settings</span></a>
		<div class="nav-links">
			<!-- $navigate({name: 'screensaver'}); -->
		</div>

		<!-- <store-chooser v-if="showSecret" class="store-chooser"></store-chooser> -->
		<div class="top-actions row">
			<div class="col-xs-12 col-sm-3">
				<!-- <router-link class="link-to-gateway" :to="'/landing'">Go to Gateway Home</router-link> -->
			</div>
			<div v-if="!isKiosk">
				<div class="col-xs-12 col-sm-3">
					<router-link v-if="!isKiosk && showVideoLink" @click="$ua.trackEvent('RSA', 'Click', 'Click Restart Video Loop');" class="btn-secondary add-shadow" :to="'/screensaver'">RESTART VIDEO</router-link>
					<a v-if="isKiosk" @click="$ua.trackEvent('RSA', 'Click', 'Click Restart Video Loop');" onclick="window.location.replace('/');" class="btn-secondary add-shadow"><span>RESTART VIDEO</span></a>
				</div>
				<div class="col-xs-12 col-sm-3">
					<a @click="backroomGate()" class="btn-secondary add-shadow backroom-login">LAUNCH BACKROOM</a>
				</div>
				<div class="col-xs-12 col-sm-3">
					<a @click="dealerLogin()" class="btn-default add-shadow dealer-login"><span v-if="!$store.state.userLoggedIn">DEALER LOGIN</span><span v-if="$store.state.userLoggedIn">LOGOUT ({{$store.state.storeID}})</span></a>
				</div>
			</div>
			<div v-if="isKiosk">
				<div class="col-xs-12 col-sm-3">
				</div>
				<div class="col-xs-12 col-sm-3">
					<router-link v-if="!isKiosk && showVideoLink" @click="$ua.trackEvent('RSA', 'Click', 'Click Restart Video Loop');" class="btn-secondary add-shadow" :to="'/screensaver'">RESTART VIDEO</router-link>
					<a v-if="isKiosk" @click="$ua.trackEvent('RSA', 'Click', 'Click Restart Video Loop');" onclick="window.location.replace('/');" class="btn-secondary add-shadow"><span>RESTART VIDEO</span></a>
				</div>
				<div class="col-xs-12 col-sm-3">
					<a @click="backroomGate()" class="btn-default add-shadow backroom-login">LAUNCH BACKROOM</a>
				</div>
			</div>
		</div>
		<article v-if="content">
			<h2 @dblclick="showSecret=true">The Flexsteel Difference</h2>
			<div class="content-items row">
				<div class="col-sm-6 content-item" v-for="item in content">
					<div class="inner" @click="loadUrl(item.url)">
						<img :src="item.thumbFileURL"/>
						<h4>{{item.title}}</h4>
					</div>
				</div>
			</div>
		</article>
		<article v-if="videos">
			<h2>Flexsteel Videos</h2>
			<div class="video-items row">
				<div class="col-sm-6 video-item" v-for="item in videos">
					<div class="inner" @click="setVideo(item.videoFileURL)">
						<div class="icon icon-play"></div>
						<h4>{{item.title}}</h4>
					</div>
				</div>
			</div>
		</article>
		<article class="warranty" v-if="warranty">
			<h2>Warranty Info</h2>
			<div class="warranty-items row">
				<div class="col-sm-6 warranty-item" v-for="item in warranty">
					<div class="inner" @click="loadContent(item.html)">
						<h4>{{item.title}}</h4>
					</div>
				</div>
			</div>
		</article>
		<!-- <div class="arrows">
			<a class="btn-up smooth" @click="pageUp"><i class="icon-up-open-big"></i></a>
			<a class="btn-down smooth" @click="pageDown"><i class="icon-down-open-big"></i></a>
		</div> -->

		<modal name="videoPlayer" class="video-toggle" width="100%" height="100%" :adaptive="true" @opened="setDefaultVolume">
			<a @click="hideVideo();" class="close-icon"><icon icon-type="icon-close"></icon></a>
			<div class="player" v-if="videoPlayerURL != ''">
				<video autoplay="true"
					poster="iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mP89etbPQAJSwNrvKpw1AAAAABJRU5ErkJggg=="
					preload="false"
					:src="videoPlayerURL"
					width="960"
					height="538"
					controls="true"
					ref="videoPlayer">
				</video>
			</div>
			<div class="volume-container">
				<p>Volume</p>
				<a class="volume-btn-up" :class="{'disabled': volume == 1.2}" @click="volumeUp"><i class="icon-up-open-big"></i></a>
				<a class="volume-btn-down" :class="{'disabled': volume == 0}" @click="volumeDown"><i class="icon-down-open-big"></i></a>
			</div>
		</modal>
		<modal name="contentPlayerRSA" class="content-toggle-rsa" width="100%" height="100%" :adaptive="true">
			<a @click="$modal.hide('contentPlayerRSA');" class="close-icon"><icon icon-type="icon-close"></icon></a>
			<div class="player">
				<iframe width="100%" :height="(windowHeight - 100)" scroll="auto" border="0" frameBorder="0" :src="iframeURL"></iframe>
			</div>
		</modal>
		<modal name="contentViewer" class="content-toggle-rsa" width="100%" height="100%" :adaptive="true">
			<a @click="$modal.hide('contentViewer');" class="close-icon"><icon icon-type="icon-close"></icon></a>
			<div class="player">
				<iframe id="contentViewerFrame" width="100%" :height="(windowHeight - 100)" scroll="auto" border="10" frameBorder="0" :srcdoc="viewerMarkup" ref="contentViewerFrame">
				</iframe>
			</div>
		</modal>
		<modal name="backroom-gate" class="content-toggle-rsa" width="100%" height="100%" :adaptive="true">
			<div class="modal-inner">
				<a @click="$modal.hide('backroom-gate');" class="close-icon"><icon icon-type="icon-close"></icon></a>
				<h4>Enter 4-digit code to enter Backroom</h4>
				<p class="error" v-if="backroomLoginStatus">{{backroomLoginStatus}}</p>
				<div class="code-items row">
					<div class="code-item col-sm-3"><span class="unfilled" v-if="backroomCode.length < 1"></span><span class="filled" v-if="backroomCode.length >= 1"></span></div>
					<div class="code-item col-sm-3"><span class="unfilled" v-if="backroomCode.length < 2"></span><span class="filled" v-if="backroomCode.length >= 2"></span></div>
					<div class="code-item col-sm-3"><span class="unfilled" v-if="backroomCode.length < 3"></span><span class="filled" v-if="backroomCode.length >= 3"></span></div>
					<div class="code-item col-sm-3"><span class="unfilled" v-if="backroomCode.length < 4"></span><span class="filled" v-if="backroomCode.length >= 4"></span></div>
				</div>
				<!-- <input id="number" :class="{'error': hasError}" v-model="backroomCode" ref="backroomCode" type="text" placeholder="phone number"/> -->
				<keyboard
					v-model="backroomCode"
					:layouts="[
					'123|456|789|0|{Delete:backspace}{Submit:submit}',
					'123'
					]"
					@submit="checkBRCode"
					:maxlength="10000"
				/>
			</div>
		</modal>
		<wifi-setup></wifi-setup>
		<dealer-login></dealer-login>

		<bottom-nav></bottom-nav>
	</div>
</template>

<script>
	import Vue from 'vue';
	import BottomNav from '../components/BottomNav';
	import SavedButton from '../components/SavedButton';
	import BackButton from '../components/BackButton';
	import WifiSetup from '../components/WifiSetup';
	import StoreChooser from '../screens/StoreChooser';
	import Icon from '../components/Icon';
	import VueKeyboard from "../components/Keyboard";
	import DealerLogin from '../components/DealerLogin';
	import _ from 'lodash';
	// import { Plugins } from '@capacitor/core';
	// const { Browser } = Plugins;
	//
	import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
	const iab = new InAppBrowser();

	import vueHeadful from 'vue-headful';
	import { mapGetters, mapActions } from 'vuex';

	export default {
		name: 'landing-page',
		data(){
			return{
				showSecret: false,
				volume: 1,
				backroomLoginStatus: '',
				backroomCode: '',
				chooserStatus: false,
				videoPlayerURL: '',
				lastVideoScroll: 0,
				iframeURL: '',
				iframeContent: '',
				viewerMarkup: '',
				content: '',
				warranty: '',
				videos: '',
			}
		},
		components: {
			bottomNav: BottomNav,
			backButton: BackButton,
			wifiSetup: WifiSetup,
			dealerLogin: DealerLogin,
			icon: Icon,
			keyboard: VueKeyboard,
			storeChooser: StoreChooser,
			vueHeadful: vueHeadful
		},
		computed: {
			...mapGetters({
				fabrics: 'customizableFabrics',
				pillows: 'customizablePillows'
			}),
			isKiosk(){
				if(this.appId == "com.flexsteel.kiosk"){
					return true;
				}
				return false;
			},
			showVideoLink(){
				if(!this.isKiosk && this.windowWidth <= 550){
					return false;
				}else{
					return true;
				}
			},
			refreshText(){
				if(this.$store.getters.getLoading == false){
					return "Refresh Products";
				}else{
					return "Loading...";
				}
			},

		},
		methods: {
			dealerLogin(){
				this.$modal.show('dealerLoginModal');
			},
			async backroomGate(){
				if(this.isKiosk){
					this.$modal.show('backroom-gate');
				}else{
					await this.launchBackroom();
				}

			},
			async checkBRCode(){
				if(this.backroomCode == this.$store.state.appCopy.backroomGate){
					this.backroomLoginStatus = "";
					this.$modal.hide('backroom-gate');
					this.backroomCode = "";
					await this.launchBackroom();
				}else{
					this.backroomLoginStatus = "Invalid access code.";
					this.backroomCode = "";
				}
			},
			async launchBackroom(){
				if(this.isKiosk){
					//disable the screensaver
					this.$store.dispatch('setScreensaver', "stop");

					// await Browser.open({ url: 'http://backroom.flexsteel.com/' });
					// https://staging.flexsteel.com/
					let browser = iab.create('https://backroom.flexsteel.com/', "_self", "location=yes,beforeload=no,zoom=no,toolbarcolor=#15bdef,footer=no,closebuttoncolor=#FFFFFF");
					// browser.on('beforeload').subscribe(function(event, cb){
					// 	console.log(cb);
					// 	if(event.url.match(".pdf")){
					// 		// Open PDFs in system browser (instead of InAppBrowser)
					// 		// cordova.InAppBrowser.open(event.url, "_system");
					// 		alert("load a pdf");
					// 	}else{
					// 		// Invoke callback to load this URL in InAppBrowser
					// 		cb(event.url);
					// 	}
					// });

					browser.on('message').subscribe(event => {
						if(event.data.action == 'close'){
							browser.close();
							//re-enable the screensaver
							this.$store.dispatch('setScreensaver', true);
						}
					});

					browser.on('loadstop').subscribe(event => {
						browser.executeScript({file: 'https://ajax.googleapis.com/ajax/libs/jquery/1.11.3/jquery.min.js'}, function(){
							browser.executeScript({code: '(function() {$("body").append("<div id=back-to-kiosk>&lt; Back to Kiosk</div>");})()'});
							browser.executeScript({code: '(function() {$("#back-to-kiosk").click(function(){var message = { action: "close"};webkit.messageHandlers.cordova_iab.postMessage(JSON.stringify(message));})})()'});
							//old backroom
							browser.executeScript({code: 'document.querySelector("#navbar1") !== null'}, function(containerCustom){
								if(containerCustom == "false"){
									browser.insertCSS({ code: "html{zoom: 1.25;}.page{margin: 0px 0px 0px 20px;}" });
									browser.insertCSS({ code: "select{width: 100%!important;}"});
									browser.insertCSS({ code: "#back-to-kiosk{z-index:10000000;position: fixed;top: 10px;right: 10px;padding: 10px 20px;text-transform:uppercase;background-color: #15bdef;border:0px;font-size:20px;line-height:24px;color:#FFFFFF;font-weight:bold;transition: all 0.30s ease-in-out;box-shadow: 0px 5px 15px -5px rgba(0, 0, 0, 0.2);#back-to-kiosk:hover{background: #0c87ac;}" });
								}
							});
							//new backroom
							browser.executeScript({code: 'document.querySelector("#navbar1") !== null'}, function(container){
								if(container == "true"){
									browser.insertCSS({ code: ".container select{width: 700px!important;}"});
									browser.insertCSS({ code: "#back-to-kiosk{z-index:10000000;position: fixed;top: 10px;left: 270px;padding: 10px 20px;text-transform:uppercase;background-color: #15bdef;border:0px;font-size:20px;line-height:24px;color:#FFFFFF;font-weight:bold;transition: all 0.30s ease-in-out;box-shadow: 0px 5px 15px -5px rgba(0, 0, 0, 0.2);#back-to-kiosk:hover{background: #0c87ac;}" });
								}
							});
						});


						// browser.insertCSS({ code: "html{zoom: 1.25;}.page{margin: 0px 0px 0px 20px;}" });
						// browser.insertCSS({ code: ".container select{width: 700px!important;}"});
						// browser.insertCSS({ code: "#back-to-kiosk{z-index:10000000;position: fixed;top: 10px;left: 270px;padding: 10px 20px;text-transform:uppercase;background-color: #15bdef;border:0px;font-size:20px;line-height:24px;color:#FFFFFF;font-weight:bold;transition: all 0.30s ease-in-out;box-shadow: 0px 5px 15px -5px rgba(0, 0, 0, 0.2);#back-to-kiosk:hover{background: #0c87ac;}" });

					});
					// this.loadUrl('http://backroom.flexsteel.com/');
				}else{
					let browser = iab.create('https://backroom.flexsteel.com/', "_system");
				}
			},
			updateProducts(){
				this.$store.dispatch('getAppProducts');
				this.$store.dispatch('getAppCopy');
			},
			openSettings(typeOf){
				this.$modal.show('wifi-modal');
			},
			getContent(){
				// this.$modal.show('storeToggle');
				// return true;
				let formData = new FormData()
				formData.append('action', "getRSAContent");

				Vue.prototype.$http.post(Vue.prototype.api_endpoint, formData, {
					headers: {
						'Content-Type': 'multipart/form-data'
					}
				}).then(response => {
					console.log(response.data);
					if(response.data.status == true){
						this.content = response.data.content;
						this.videos = response.data.videos;
						this.warranty = response.data.warranty;
					}
				}, err_response => {
					console.log("Error getting stores list:", err_response);
				});
			},
			loadUrl(url){
				this.iframeURL = Vue.prototype.api_endpoint+"proxy.php?url="+url;
				this.$modal.show('contentPlayerRSA');
				// const browser = iab.create(url);
			},
			loadContent(html){
				//light styles for this content
				let finaleHTML = '<style>@font-face{font-family:"Avenir LT W01_45 Book1475508";src:url("/static/065a6b14-b2cc-446e-9428-271c570df0d9.woff2");}@font-face{font-family:"Avenir LT W01_65 Medium1475532";src:url("/static/17b90ef5-b63f-457b-a981-503bb7afe3c0.woff2") format("woff2");}body{padding:20px;font-family: "Avenir LT W01_45 Book1475508";font-size: 24px;color: #313c54;}h1,h2,h3,h4,h5,b,strong{font-family:"Avenir LT W01_65 Medium1475532";font-weight: 400;}</style>'+html;
				this.viewerMarkup = finaleHTML;
				this.$modal.show('contentViewer');

			},
			pageDown(){
				let container = this.$refs.rsa;
				container.scroll({
					top: container.scrollTop+window.innerHeight*.9,
					left: 0,
					behavior: 'smooth'
				});
			},
			pageUp(){
				let container = this.$refs.rsa;
				container.scroll({
					top: container.scrollTop-window.innerHeight*.9,
					left: 0,
					behavior: 'smooth'
				});
			},
			volumeUp(){
				if(window.ShellExec){
					//volume up
					window.ShellExec.exec('input keyevent 24', function(res){});
				}else{
					let player = this.$refs.videoPlayer;
					this.volume += 0.20;
					if(this.volume >= 1){
						this.volume = 1;
					}
					player.volume = this.volume;
				}
				// var VolumeControl = cordova.plugins.VolumeControl;
				// VolumeControl.setVolume(this.volume);
			},
			volumeDown(){
				if(window.ShellExec){
					//volume down
					window.ShellExec.exec('input keyevent 25', function(res){});
				}else{
					let player = this.$refs.videoPlayer;
					this.volume -= 0.20;
					if(this.volume <= 0){
						this.volume = 0;
					}
					player.volume = this.volume;
				}
				// var VolumeControl = cordova.plugins.VolumeControl;
				// this.volume -= 0.15;
				// VolumeControl.setVolume(this.volume);
			},
			setDefaultVolume(){
				// document.getElementsByTagName('video')[0].volume = this.volume;
				let player = this.$refs.videoPlayer;
				player.volume = this.volume;
			},
			setVideo(url){
				this.videoPlayerURL = url;
				this.lastVideoScroll = window.scrollY;
				window.scroll({
					top: 0,
					left: 0,
					behavior: 'smooth'
				});
				this.$modal.show('videoPlayer');
			},
			hideVideo(){
				this.videoPlayerURL = '';
				window.scroll({
					top: this.lastVideoScroll,
					left: 0,
					behavior: 'smooth'
				});
				this.$modal.hide('videoPlayer');
			},
			productDetails(sku){
				this.$navigate('/thirty-in-thirty/sku/'+sku);
			}
		},
		mounted() {
			this.getContent();
			console.log("test");
		}

	}
</script>
