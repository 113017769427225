<template>
	<div :class="'diagram '+view" v-if="filePath">
		<div class="top">
			<div class="width">{{diagram_width}}&rdquo;</div>
			<div class="height">{{diagram_height}}&rdquo;</div>
			<div class="background">
				<img :src="'static/img/diagrams/'+filePath+' 1.svg'" alt="top diagram"/>
			</div>
		</div>
		<div class="bottom">
			<div class="depth">{{diagram_depth}}&rdquo;</div>
			<div class="background">
				<img :src="'static/img/diagrams/'+filePath+' 2.svg'" alt="bottom diagram"/>
			</div>
		</div>
	</div>
</template>

<script>
import _ from 'lodash';
import pluralize from 'pluralize';

export default {
	name: 'Diagram',
	components:{
	},
	directives: {
	},
	data(){
		return{
		}
	},
	props: [
		'type',
		'view',
		'diagram_width',
		'diagram_height',
		'diagram_depth'
	],
	computed: {
		filePath(){
			// let type = pluralize.singular(this.type);
			if(!_.isNil(this.type)){
				let type = this.type.replace(/\s+/g,"-").toLowerCase();
				let file = "";
				if(type == "sofas" || type == "sofas-and-sleepers" || type == "reclining-sofas"){
					file = "sofa";
				}
				if(type == "bed" || type == "storage-beds" || type == "beds" || type == "upholstered-beds"){
					file = "bed";
				}
				if(type == "bookcases"){
					file = "bookcase";
				}
				if(type == "benches"){
					file = "bench";
				}
				if(type == "chairs" || type == "chairs-and-recliners" || type == "chairs-and-ottomans" || type == "chair-sleepers" || type == "lounge-chairs" || type == "recliners" || type == "high-leg-recliners" || type == "lift-recliners"){
					file = "chair";
				}
				if(type == "coffee-tables"){
					file = "coffee_table";
				}
				if(type == "desks-and-hutches"){
					file = "desk";
				}
				if(type == "dining-chairs"){
					file = "dining_chair";
				}
				if(type == "dressers-and-armoires"){
					file = "dresser";
				}
				if(type == "group-seating"){
					file = "group_seating";
				}
				if(type == "helm-seating"){
					file = "helm_seating";
				}
				if(type == "love-seat" || type == "loveseats" || type == "reclining-loveseats"){
					file = "loveseat";
				}
				if(type == "media-consoles"){
					file = "media_chest";
				}
				if(type == "night-stands"){
					file = "night_stand";
				}
				if(type == "ottomans"){
					file = "ottoman";
				}
				if(type == "presentation-boards"){
					file = "presentation_board";
				}
				if(type == "stools"){
					file = "stool";
				}
				if(type == "tables" || type == "side-tables"){
					file = "table";
				}
				if(type == "task-chairs"){
					file = "task_chair";
				}
				if(type == "arm-chairs"){
					file = "arm_chair";
				}
				if(type == "buffets" || type == "buffets-and-storage" || type == "chests"){
					file = "buffet";
				}
				if(type == "credenzas"){
					file = "credenza";
				}
				if(type == "headboard"){
					file = "headboard";
				}
				if(type == "hutches"){
					file = "hutch";
				}
				if(type == "rockers"){
					file = "rocker";
				}
				if(type == "settees" || type == "settee"){
					file = "settee";
				}
				if(type == "side-chairs"){
					file = "side_chair";
				}
				if(type == "side-piers"){
					file = "side_pier";
				}
				if(type == "sofa-tables"){
					file = "sofa_table";
				}
				if(type == "storage-ottomans"){
					file = "storage_ottoman";
				}
				if(type == "vanity-sets"){
					file = "vanity_mirror";
				}
				return file;
			}
		}
	},
	methods: {
	}
};
</script>
