<template>
	<div class="digital-catalog-modal-container">
		<modal name="contentPlayer" class="content-toggle" :width="windowWidth" :height="windowHeight" :adaptive="true" @before-open="openedDigitalCatalog">
			<a @click="$modal.hide('contentPlayer');" class="close-icon"><icon icon-type="icon-close"></icon></a>
			<div class="player">
				<iframe :width="windowWidth" :height="windowHeight" scroll="auto" border="0" frameBorder="0" :src="iframeURL"></iframe>
			</div>
		</modal>
	</div>
</template>

<script>
	import _ from 'lodash';
	import { mapGetters } from 'vuex';
	import Icon from '../components/Icon';

	export default {
		name: 'digitalCatalogModal',
		components: {
			icon: Icon,
		},
		data(){
			return{
				'sku': '',
				'loading': false,
			}
		},
		props: [
		],
		computed: {
			iframeURL(){
				// var url = 'https://www.flexsteel-stage.com/flx/catalog/?sku='+this.sku;
				var url = 'https://catalog.flexsteel.com/generate/?sku='+this.sku;
				return "https://docs.google.com/gview?embedded=true&url="+url;
			}
		},
		mounted(){
		},
		methods: {
			openedDigitalCatalog(event){
				this.sku = event.params.sku;
			},
		}
	}
</script>

