<template>
	<img :src="image" v-on:changesrc="changeSrc">
</template>

<script>
import _ from 'lodash';
import { mapGetters, mapActions } from 'vuex';
import { Plugins, FilesystemDirectory } from '@capacitor/core';
const { Filesystem } = Plugins;
import { HTTP } from '@ionic-native/http/ngx';
import { WebView } from '@ionic-native/ionic-webview/ngx';
import md5 from 'blueimp-md5';

const http = new HTTP();
const webview = new WebView();

export default {
	name: 'CachedImage',
	components:{
	},
	data(){
		return{
			'datasrc': '',
			'modified': false,
			'image': 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=',
		}
	},
	props: [
		'src',
	],
	watch: {
		src: {
			handler(newVal, oldVal){
				this.modified = true;
				this.init();
			},
			deep: true
		}
	},
	computed: {
		passedSrc(){
			if(!_.isNil(this.datasrc) && this.datasrc.length >= 1){
				return this.datasrc;
			}else{
				return this.src;
			}
		}
	},
	methods: {
		changeSrc(e){
			//coming from a lazyload
			this.datasrc = e.detail;
			this.init();
		},
		getFileName(url){
			let extension = url.split('.').pop();
			if(extension === 'jpg' || extension === 'png'){

			}else{
				extension = "png";
			}
			return md5(url)+"."+extension;
		},
		async download(url){
			let fileName = this.getFileName(url);
			try{
				Filesystem.getUri({
					directory: FilesystemDirectory.Data,
					path: ''
				}).then((result) => {
					let targetPath = result.uri + '/' + fileName;
					http.downloadFile(url, null, null, targetPath).then((res) => {
						//downloaded for next time
						console.log("downloaded");
					}, (res) => {
						console.log('error downloading', res);
					});
				}).catch(e => {

				});
			}catch(e){
				console.log(e);
			}
		},
		async checkCache(url, attempt){
			let fileName = this.getFileName(url);
			let local = this;
			try{
				let stat = await Filesystem.stat({
					path: fileName,
					directory: FilesystemDirectory.Data,
				}).then(res => {
					this.image = webview.convertFileSrc(res.uri);
				}).catch(e => {
					//probably running in a browser instead of on device
					local.image = local.passedSrc;
					local.download(url);
					console.log(e);
				});
			}catch(e){
				console.log("cant cache");
			}
		},
		init(){
			this.image = this.src;
			return true;
			if(!_.isNil(this.src)){// && !this.src.includes("base64")
				this.checkCache(this.src, 0);
			}
			if(!_.isNil(this.datasrc) && this.datasrc.length >= 1){
				this.checkCache(this.datasrc, 0);
			}
			// this.image = this.src;
		},
	},
	mounted(){
		this.init();
	}

};
</script>

<style lang="scss" scoped>
	.fade-enter-active{
		transition: opacity 0.5s ease-in-out;
	}

	.fade-enter-to{
		opacity: 1;
	}

	.fade-enter{
		opacity: 0;
	}
	.img{
		position: relative;
		.old{
			display: none;
		}
		&.thirty-grid, &.related, &.landing, &.thirty-hero{
			width: 250px;
			height: 250px;
			.new{
				position: absolute;
				top: 0px;
				left: 0px;
				width: 250px;
				height: 250px;
				z-index: 2;
			}
			.old{
				display: block;
				position: absolute;
				width: 250px;
				height: 250px;
				top: 0px;
				left: 0px;
				z-index: 1;
			}
			img{
				object-fit: contain;
				max-height: 250px;
				max-width: 100%;
				width: 100%;
				height: 100%;
			}
			&.sectional{
				zoom: 1.65;
				width: 165%;
				margin-left: -35%;
				.new, .old{
					top: -35px;
				}
			}
		}
		&.vision-grid{
			// width: 380px;
			height: 250px;
			width: 100%;
			img{
				max-height: 320px;
				max-width: 100%;
			}
		}
		&.landing{
			img{
				max-height: 300px;
			}
		}
		&.related{
			width: 158px;
			height: 190px;
			.new{
				top: 10px;
				width: 158px;
				height: 158px;
			}
			.old{
				top: 10px;
				width: 158px;
				height: 158px;
			}
			img{
				max-height: 190px;
			}
		}
		img{
			max-width: 100%;
		}
		&.thirty-hero{
			overflow: hidden;
			width: auto;
			height: 400px;
			display: block;
			.new, .old{
				width: auto;
				height: 400px;
			}
			img{
				// margin-top: -80px;
				// margin-left: -80px;
				max-height: 400px;
			}

		}
		&.featured{
			overflow: hidden;
			width: 560px;
			height: 490px;
			display: block;
			img{
				// margin-top: -80px;
				// margin-left: -80px;
				height: 490px;
				// width: 550px;
				max-width: none;
			}
		}

		&.large{
			overflow: hidden;
			width: 400px;
			height: 400px;
			display: block;
			img{
				margin-top: -80px;
				margin-left: -80px;
				height: 550px;
				width: 550px;
				max-width: none;
			}
		}
		&.large.loveseat{
			overflow: hidden;
			width: 660px;
			height: 400px;
			display: block;
			img{
				margin-top: -190px;
				margin-right: 0px;
				height: 800px;
				width: 800px;
				max-width: none;
			}
		}
		&.large.sectional{
			overflow: hidden;
			width: 560px;
			height: 400px;
			display: block;
			img{
				margin-top: -290px;
				margin-left: -350px;
				height: 1000px;
				width: 1000px;
				max-width: none;
			}
		}
		&.grid{
			img{
				margin-top: 50px;
				height: 350px;
				// width: 800px;
			}
			&.iframe{
				height: 175px;
			}
		}
	}
	.custom-product-image{
		&.iframe{
			.grid{
				img{
					margin-top: 50px;
					height: 250px;
					// width: 800px;
				}
			}

		}
	}

</style>
